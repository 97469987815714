import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllCustomer,
  apiGetEmployee,
  apiGetAllCustomerDetails,
  apiGetAllService,
} from "../../../services/ProductinterestService";

export const getAllcustIntrest = createAsyncThunk(
  "Productinterest/data/getAllcustIntrest",
  async (data) => {
    const response = await apiGetAllCustomerDetails(data);
    return response.data;
  }
);

export const getAllcustIntrestXls = createAsyncThunk(
  "Productinterest/data/getAllcustIntrestXls",
  async (data) => {
    const response = await apiGetAllCustomerDetails(data);
    return response.data;
  }
);
export const getCustomer = createAsyncThunk(
  "Productinterest/data/getCustomer",
  async (data) => {
    const response = await apiGetAllCustomer(data);
    return response.data;
  }
);

export const getEmployee = createAsyncThunk(
  "Productinterest/data/getEmployee",
  async (data) => {
    const response = await apiGetEmployee(data);
    return response.data;
  }
);

export const getService = createAsyncThunk(
  "Productinterest/data/getService",
  async (data) => {
    const response = await apiGetAllService(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "ProductinterestList/data",
  initialState: {
    loading: false,
    loadingXls: false,
    ProductIntrestList: [],
    customerList: [],
    employeeList: [],
    XlsList: [],
    serviceList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.designationList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllcustIntrest.fulfilled, (state, action) => {
        state.loading = false;
        state.ProductIntrestList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getAllcustIntrest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllcustIntrest.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
        // state.tableData.total = action.payload.count;
      })

      .addCase(getAllcustIntrestXls.fulfilled, (state, action) => {
        state.loadingXls = false;
        state.XlsList = action.payload;
      })

      .addCase(getAllcustIntrestXls.pending, (state, action) => {
        state.loadingXls = true;
      })
      .addCase(getAllcustIntrestXls.rejected, (state, action) => {
        state.loadingXls = false;
      })

      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload;
        // state.tableData.total = action.payload.count;
      })

      .addCase(getService.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceList = action.payload;
      });
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
