import React, { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination, Spin } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { Switch } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getBranch, setTableData } from "../store/dataSlice";
import { setSelectedBranch, toggleNewDialog } from "../store/stateSlice";

const BranchTable = ({ handleRefresh }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.branch.data.loading);

  const tableData = useSelector((state) => state.branch.data.tableData);
  const { pageIndex = 1, pageSize = 10 } = tableData || {};

  const fetchData = useCallback(() => {
    dispatch(getBranch({ pageIndex, pageSize }));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, handleRefresh]);

  const data = useSelector((state) => state.branch.data.branchList.data);

  const onEdit = (record) => {
    dispatch(setSelectedBranch(record));
    dispatch(toggleNewDialog(true));
  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };
  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
            {/* <span className="text-2xl ml-2 text-red-500 cursor-pointer">
              <MdDelete />
            </span> */}
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Name</span>,
      dataIndex: "NAME",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Address</span>,
      dataIndex: "ADDRESS",
    },
    {
      title: <span className="text-gray-500">Organisation Name</span>,
      dataIndex: "ORGANISATION_NAME",
    },

    {
      title: <span className="text-gray-500">Longitude</span>,
      dataIndex: "LONGITUDE",
    },
    {
      title: <span className="text-gray-500">Latitude</span>,
      dataIndex: "LATITUDE",
    },
    {
      title: <span className="text-gray-800">Status</span>,
      dataIndex: "STATUS",
      //   width: 170,
      render: (text, record) => {
        const handleChange = (checked) => {
          //console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          onChange={handleTableChange}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
        />
      )}
    </>
  );
};

export default BranchTable;
