import ApiService from "./ApiService";

export async function apiGetAllEmployeeTarget(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/employeeTargetMaster/get",
    method: "post",
    data,
  });
}

export async function apipostEmployeeTarget(data) {
  return ApiService.fetchData({
    url: "/api/employeeTargetMapping/assignTarget",
    method: "post",
    data,
  });
}

export async function apipostEmployeeTarInner(data) {
  return ApiService.fetchData({
    url: "/api/employeeTargetMapping/get",
    method: "post",
    data,
  });
}

export async function apiputEmployeeTarget(data) {
  return ApiService.fetchData({
    url: "/api/employeeTargetMapping/assignTarget",
    method: "put",
    data,
  });
}

export async function apiGetAllBranch(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/branch/get",
    method: "post",
    data,
  });
}
export async function apiGetAllDepartment(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/department/get",
    method: "post",
    data,
  });
}

// export async function apiGetAllPriceTable(data) {
//     return ApiService.fetchData({
//         url: "/api/priceList/get",
//         method: "Post",
//         data,
//     });
// }

// export async function apiGetpriceListDetails(data) {
//     return ApiService.fetchData({
//         url: "/api/priceListDetails/get",
//         method: "Post",
//         data,
//     });
// }
