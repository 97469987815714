import React from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getEmployee } from "../store/dataSlice";
import { setFilters } from "../store/stateSlice";

const EmployeeSearch = ({ handleRefresh }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state?.employee?.state?.filters);

  const handleSubmit = async () => {
    try {
      const action = await dispatch(getEmployee(filters));
      if (action.payload.code === 200) {
        
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const handleChange = (event) => {
    dispatch(setFilters({ ...filters, SEARCH_FILTER: event.target.value }));
  };

  return (
    <div className="flex items-center">
      <input
        type="text"
        placeholder="Search"
        value={filters?.SEARCH_FILTER || ""}
        onChange={handleChange}
        className="px-2 py-2 border focus:outline-none focus:border-blue-500 rounded-s"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <div
        className="bg-[#096CAE] text-white mr-4 px-2.5 py-[11.5px] rounded-e cursor-pointer"
        onClick={handleSubmit}
      >
        <FaSearch className="text-white " />
        {/* {JSON.stringify(filters)} */}
      </div>
    </div>
  );
};

export default EmployeeSearch;
