import React, { useEffect, useState } from "react";
import AbsentReportreducer from "./store";
import { injectReducer } from "../../store";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { PiFileXlsThin } from "react-icons/pi";
import { PiFileXlsBold } from "react-icons/pi";
import { PiFileXlsFill } from "react-icons/pi";
import { TbFileTypeXls } from "react-icons/tb";
import { PiFileXlsLight } from "react-icons/pi";
import {
  getAbsentReport,
  getBranchId,
  getDepartmentId,
  getEmployeeId,
} from "./store/dataSlice";
import AbsentXls from "./Components/AbsentXls";
import { FaFilter } from "react-icons/fa";
import { Button, Select } from "antd";
import AbsentReportTable from "./Components/AbsentReportTable";
import { toggleNewDialog } from "./store/stateSlice";
injectReducer("absentReport", AbsentReportreducer);

const AbsentReport = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.absentReport?.state.newDialog);

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">
            Employee Absent Report
          </div>
          <div className="flex justify-between">
            <AbsentXls />

            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[10px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>

        <AbsentReportTable />
      </div>
    </>
  );
};

export default AbsentReport;
