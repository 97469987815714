import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getPrice } from "../store/dataSlice";
import { Input, Space } from "antd";
const { Search } = Input;
const PriceSearch = ({ handleRefresh }) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  ////console.log(searchQuery);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const action = await dispatch(getPrice({ SEARCH_FILTER: searchQuery }));

      if (action.payload.code === 200) {
        handleRefresh && handleRefresh();
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <div className="flex items-center w-full">
      <Search
        placeholder="input search text"
        value={searchQuery}
        onChange={handleSearchChange}
        onSearch={handleSubmit}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
        enterButton
      />
    </div>
  );
};

export default PriceSearch;
