import ApiService from "./ApiService";

export async function apiGetAllAsset(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/asset/get",
    method: "post",
    data,
  });
}
export async function apipostAsset(data) {
  return ApiService.fetchData({
    url: "/api/asset/create",
    method: "post",
    data,
  });
}

export async function apiputAsset(data) {
  return ApiService.fetchData({
    url: "/api/asset/update",
    method: "put",
    data,
  });
}
export async function apiGetAllAssetCategory(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/assetCategory/get",
    method: "post",
    data,
  });
}
