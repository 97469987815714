import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLeavesdata } from "../store/dataSlice";
import { Table } from "antd";

const TopsellerTable = () => {
  const dispatch = useDispatch();

  const data1 = useSelector((state) => state?.dashboard?.data?.leaves?.data);
  // //console.log("data1", data1);

  //----------------------role wise switch START----------

  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails?.[0]?.EMP_ID;

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it

  const fetchData = useCallback(() => {
    dispatch(getLeavesdata());
  }, [ROLE_ID]);

  const fetchData4 = useCallback(() => {
    dispatch(getLeavesdata({ REPORTING_HEAD_ID: [EmpId] }));
  }, [dispatch]);

  const fetchData10 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

    dispatch(
      getLeavesdata({
        BRANCH_ID: branchIds,
        DEPARTMENT_ID: departmentids,
      })
    );
  }, [dispatch]);

  const fetchData9 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

    dispatch(
      getLeavesdata({
        DEPARTMENT_ID: departmentids,
        BRANCH_ID: branchIds,
      })
    );
    //console.log("9 hit  twise");
  }, [dispatch]);

  useEffect(() => {
    if (ROLE_ID == 4) {
      fetchData4();
    } else if (ROLE_ID == 10) {
      fetchData10();
    } else if (ROLE_ID == 9) {
      fetchData9();
    } else {
      fetchData();
    }
  }, [ROLE_ID]);

  //----------------------role wise switch END----------

  const columns = [
    {
      title: <span className="text-gray-500">Employee name</span>,
      dataIndex: "EMPLOYEE_NAME",
      width: 200,
      fixed: "left",
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#FFE2E5",
            color: "white",
          },
        };
      },
    },
    {
      title: <span className="text-gray-500">Branch Name</span>,
      dataIndex: "BRANCH_NAME",
      width: 150,
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#FFE2E5",
            color: "white",
          },
        };
      },
    },
    {
      title: <span className="text-gray-500">Department </span>,
      dataIndex: "DEPARTMENT",
      width: 150,
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#FFE2E5",
            color: "white",
          },
        };
      },
    },

    {
      title: <span className="text-gray-500">Approval Status</span>,
      dataIndex: "APPROVAL_STATUS",
      width: 150,
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#FFE2E5",
            color: "white",
          },
        };
      },
      render: (text) => (
        <>
          {text === "A" ? (
            <div className="text-[#1677ff] font-medium w-[120px] w-500px bg-opacity-10    py-2 bg-[#1c74cc] rounded-md">
              <center>Approved</center>
            </div>
          ) : null}

          {text === "R" ? (
            <div className="text-[#e93354] font-medium w-[120px] w-500px bg-opacity-10    py-2 bg-[#e42245] rounded-md">
              <center>Rejected</center>
            </div>
          ) : null}

          {text === "P" ? (
            <div className="text-[#f1c606] bg-[#d1ec0b] font-medium w-[120px] w-500px bg-opacity-10    py-2  rounded-md">
              <center>Pending</center>
            </div>
          ) : null}
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Date </span>,
      dataIndex: "DATE",
      width: 150,
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: "#FFE2E5",
            color: "white",
          },
        };
      },
    },
  ];

  return (
    <>
      <div className="bg-white m-5 p-8 rounded-xl">
        <div className="flex flex-col lg:flex-row justify-between gap-6">
          <div className="mt-6  w-full">
            <div className="flex text-[22px] text-[#111827] font-bold">
              <p>Todays Leave</p>
            </div>

            <div className="w-full mt-3">
              <Table
                className="w-full"
                columns={columns}
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                dataSource={data1}
                bordered
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopsellerTable;
