import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllEmployeeId,
  apiGetAllDepartmentId,
  apiGetEmployeetarget,
  apiGetAllBranchId,
} from "../../../services/EmployeereportService";

export const GetEmployeetarget = createAsyncThunk(
  "employee/data/GetEmployeetarget",
  async (data) => {
    const response = await apiGetEmployeetarget(data);
    return response.data;
  }
);

export const GetEmployeetargetXls = createAsyncThunk(
  "employee/data/GetEmployeetargetXls",
  async (data) => {
    const response = await apiGetEmployeetarget(data);
    return response.data;
  }
);
export const getEmployee = createAsyncThunk(
  "employee/data/getEmployee",
  async (data) => {
    const response = await apiGetAllEmployeeId(data);
    ////console.log("response.data",response.data);
    return response.data;
  }
);
export const grtDepartment = createAsyncThunk(
  "department/data/grtDepartment",
  async (data) => {
    const response = await apiGetAllDepartmentId(data);
    return response.data;
  }
);

export const getBranch = createAsyncThunk(
  "branch/data/getBranch",
  async (data) => {
    const response = await apiGetAllBranchId(data);
    return response.data;
  }
);
export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "employeereportList/data",
  initialState: {
    loading: false,
    employeeIdList: [],
    employeereportList: [],
    departmentIdList: [],
    employeetargetList: [],
    XlsList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.employeeIdList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEmployeetarget.fulfilled, (state, action) => {
        state.loading = false;
        state.employeereportList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(GetEmployeetarget.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetEmployeetarget.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetEmployeetargetXls.fulfilled, (state, action) => {
        state.XlsList = action.payload;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.employeeIdList = action.payload;
      })

      .addCase(grtDepartment.fulfilled, (state, action) => {
        state.departmentIdList = action.payload;
      })

      .addCase(getBranch.fulfilled, (state, action) => {
        state.branchIdList = action.payload;
      });
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
