import React, { useEffect, useRef, useCallback, useState } from "react";
import { Button, Table, Switch } from "antd";
import { CgFileDocument } from "react-icons/cg";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getTask, setTableData } from "../store/dataSlice";
import {
  increaseCounter,
  setSelectedTaskMaster,
  toggleNewDialog,
} from "../store/stateSlice";

import AttachmentModal from "./AttachmentModal";
import DescriptionModal from "./DescriptionModal";
import { useLocation } from "react-router-dom";

const TaskTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState(false);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.taskmaster.data.tableData
  );

  const data = useSelector(
    (state) => state.taskmaster.data.taskMasterList.data
  );
  ////console.log(data);

  const onEdit = (record) => {
    dispatch(setSelectedTaskMaster(record));
    dispatch(toggleNewDialog(true));
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const onView = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const onEyeClick = (record) => {
    setSelectedRecord(record);
    setIsDescriptionModalVisible(true);
  };

  const handleDescriptionModalClose = () => {
    setIsDescriptionModalVisible(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    // setSelectedRecord(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getTask({ pageIndex: 1, pageSize }));
        //console.log("Hi babes");

        dispatch(setTableData({ pageIndex: 1, pageSize }));
        dispatch(increaseCounter({ counter: 0 }));

        dispatch(toggleNewDialog(false));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [location.pathname, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      align: "center",
      render: (_, record) => (
        <>
          <div className="flex justify-between px-2">
            <span
              className="text-xl  text-red-500 cursor-pointer"
              onClick={() => {
                onView(record);
              }}
            >
              <CgFileDocument />
            </span>

            <span
              onClick={() => onEyeClick(record)}
              className="text-xl text-[#096CAE] cursor-pointer "
            >
              <FaEye />
            </span>
          </div>
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Employee</span>,
      dataIndex: "EMPLOYEE_NAME",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Department</span>,
      dataIndex: "DEPARTMENT_ID",
      width: 140,
      render: (_, record) => `${record.DEPARTMENT_NAME} `,
    },

    {
      title: <span className="text-gray-500">Start</span>,
      dataIndex: "START_DATETIME",
      width: 160,
    },

    {
      title: <span className="text-gray-500">End</span>,
      dataIndex: "END_DATETIME",
      width: 160,
    },
    {
      title: <span className="text-gray-500">Description</span>,
      dataIndex: "DESCRIPTION",
      width: 140,
    },
    {
      title: <span className="text-gray-500">Assigned by</span>,
      dataIndex: "ASSIGNED_BY_NAME",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      width: 100,
      align: "center",
      render: (text, record) => {
        const handleChange = (checked) => {
          ////console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },

    {
      title: <span className="text-gray-500">Priority</span>,
      dataIndex: "PRIORITY",
      align: "center",
      width: 140,
      render: (text, record) => {
        switch (record.PRIORITY) {
          case 1:
            return "Low";
          case 2:
            return "Medium";
          case 3:
            return "High";
          default:
            return null;
        }
      },
    },
    {
      title: <span className="text-gray-500">Completion</span>,
      dataIndex: "COMPLETION_DATETIME",
      width: 160,
    },
    {
      title: <span className="text-gray-500">Remark</span>,
      dataIndex: "REMARK",
      width: 140,
    },
    // {
    //   title: <span className="text-gray-500">Attachment</span>,
    //   dataIndex: "",
    //   width: 120,
    // },
    {
      title: <span className="text-gray-500">Created</span>,
      dataIndex: "CREATED_DATETIME",
      width: 160,
    },
    {
      title: <span className="text-gray-500 ">Completion Status</span>,
      dataIndex: "COMPLETION_STATUS",
      width: 150,
      align: "center",
      render: (text) => {
        const isPending = text === "P";
        const style = {
          color: isPending ? "#f3dc93" : "#82d9b3",
          backgroundColor: isPending ? "#fdfbee" : "#dafbf1",
          padding: "5px",
          borderRadius: "4px",
          fontWeight: "bold",
        };
        return <span style={style}>{isPending ? "Pending" : "Completed"}</span>;
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20],
          onChange: handleTableChange,
          onShowSizeChange: handleTableChange,
        }}
        onChange={handleTableChange}
      />

      <AttachmentModal
        isVisible={isModalVisible}
        record={selectedRecord}
        onClose={handleModalClose}
      />

      <DescriptionModal
        isVisible={isDescriptionModalVisible}
        record={selectedRecord}
        onClose={handleDescriptionModalClose}
      />
    </>
  );
};

export default TaskTable;
