import axios from "axios";
// import appConfig from '../configs/app.config'
// import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant'
// import { PERSIST_STORE_NAME } from '../constants/app.constant'
// import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: "https://bgs.5techg.com",
  // "https://14d5-2401-4900-54f7-1579-8c82-7137-7cf3-6dd0.ngrok-free.app",
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem("token");
    // ////console.log("rawPersistData", rawPersistData);

    // ////console.log("persistData", rawPersistData);

    let accessToken = rawPersistData ? rawPersistData : null;
    // ////console.log(accessToken);
    // if (accessToken) {
    config.headers["token"] = `${accessToken}`;
    // }

    config.headers["apikey"] = "w9OAxS4zZXSvub0rVCZf5mt4v6K66pj2";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    if (response.data.code === 403) {
      localStorage.removeItem("UserData");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    return response;
  },
  (error) => {
    const { response } = error;

    // if (response && unauthorizedCode.includes(response.status)) {
    //   store.dispatch(onSignOutSuccess());
    // }

    return Promise.reject(error);
  }
);

export default BaseService;
