import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getPrice, putPrice, getPriceTable } from "../store/dataSlice";
import { setSelectedPrice } from "../store/stateSlice";
import { setTableData, getpriceListDetails } from "../store/dataSlice";
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const PriceTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.price?.data?.priceListTable?.data);

  // ////console.log(data);

  const onEdit = async (record) => {
    await dispatch(setSelectedPrice(record));
    await dispatch(getpriceListDetails({ ORDER_ID: record.ID }));
    await navigate("/pricedetail");
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putPrice(updatedRecord));
    dispatch(getPriceTable());
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.price.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getPriceTable({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  // const fetchData2 = useCallback(() => {
  //   dispatch(getPriceTable());
  // }, []);

  // useEffect(() => {
  //   fetchData2();
  // }, []);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 200,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEye />
            </span>
          </div>
        </>
      ),
    },
    // {
    //   title: <span className="text-gray-500">ID</span>,
    //   dataIndex: "ID",
    //   width: 240,

    // },
    {
      title: <span className="text-gray-500">Month</span>,
      dataIndex: "MONTH",
      render: (monthId) => monthNames[monthId - 1],
    },

    {
      title: <span className="text-gray-500">Year</span>,
      dataIndex: "YEAR",
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20],
          onChange: handleTableChange,
          onShowSizeChange: handleTableChange,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default PriceTable;
