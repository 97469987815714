import React, { useEffect, useCallback, useState } from "react";
import { Table, Select, Switch, Spin } from "antd";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { getDepartment, setTableData } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import { setSelectedDistributor, toggleNewDialog } from "../store/stateSlice";

const { Option } = Select;

const DepartmentTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFirstRender, setIsFirstRender] = useState(true);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.department.data.tableData
  );

  const loading = useSelector((state) => state.department.data.loading);
  const data = useSelector(
    (state) => state.department.data.departmentList.data
  );
  const fetchData = useCallback(() => {
    dispatch(getDepartment({ pageIndex, pageSize }));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const onEdit = (record) => {
    dispatch(setSelectedDistributor(record));
    dispatch(toggleNewDialog(true));
  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    ////console.log(current);
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      align: "center",
      width: 110,
      render: (_, record) => (
        <>
          <div className="flex justify-center px-2 ">
            <span
              onClick={() => onEdit(record)}
              className="text-xl text-[#096CAE] cursor-pointer "
            >
              <FaRegEdit />
            </span>
            {/* <span
                onClick={() => navigate("/cart")}
                className="text-xl text-[#096CAE] cursor-pointer "
              >
                <HiOutlineShoppingCart />
              </span> */}
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Department</span>,
      dataIndex: "DEPARTMENT",
      width: 220,
    },

    {
      title: <span className="text-gray-500">Location</span>,

      dataIndex: "LOCATION",
    },

    {
      title: <span className="text-gray-500">Tehsil</span>,
      dataIndex: "TEHSIL",
    },

    {
      title: <span className="text-gray-500">Address</span>,
      dataIndex: "ADDRESS",
    },
    {
      title: <span className="text-gray-500">Country</span>,
      dataIndex: "COUNTRY",
    },
    {
      title: <span className="text-gray-500">District</span>,
      dataIndex: "DISTRICT",
    },
    {
      title: <span className="text-gray-500">Day In Time</span>,
      dataIndex: "DAYIN_TIME",
      width: 100,
    },
    {
      title: <span className="text-gray-500">Latemark Time</span>,
      dataIndex: "LATEMARK_TIME",
      width: 100,
    },
    {
      title: <span className="text-gray-500">Interval Time</span>,
      dataIndex: "INTERVAL_TIME",
    },
    {
      title: <span className="text-gray-500">Day Out Time</span>,
      dataIndex: "DAYOUT_TIME",
      width: 100,
    },

    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      render: (text, record) => {
        const handleChange = (checked) => {
          ////console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];
  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginBottom: "16px" }}>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 1300 }}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </>
  );
};

export default DepartmentTable;
