import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Input, notification, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeTargetInner, setTableDataInner, putEmployeeTarget } from "../store/dataSlice";
import { setSelectedEmployeeTargetInner, toggleNewDialog } from "../store/stateSlice";
import { RiArrowGoBackFill } from "react-icons/ri";
import EmployeeTargetInnerTableSerch from './EmployeeTargetInnerTableSerch';
import { Link, useNavigate } from "react-router-dom";
const { Search } = Input;
const EmployeeTargetInnerTable = () => {
    const loading = useSelector((state) => state.employeetarget.data.loadinginner);

    const dispatch = useDispatch();
    const data = useSelector((state) => state?.employeetarget?.data?.employeetargetInnerList?.data);
    const selectedEMPTarget = useSelector((state) => state.employeetarget.state.selectedEmployeeTargetInner);
    const [api, contextHolder] = notification.useNotification();

    const [rates, setRates] = useState({});
    const [changes, setChanges] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

        if (data) {
            const initialRates = data.reduce((acc, item) => {
                acc[item.ID] = item.RATE;
                return acc;
            }, {});
            setRates(initialRates);
            const initialChanges = data.reduce((acc, item) => {
                acc[item.ID] = 0;
                return acc;
            }, {});
            setChanges(initialChanges);
        }
    }, [data]);

    const handleRateChange = (e, record) => {
        const { value } = e.target;

        setRates({
            ...rates,
            [record.ID]: value,
        });

    };







    const { pageIndex, pageSize, total } = useSelector(

        (state) => state.employeetarget.data.tableDataInner
    );



    const handleTableChange = (pagination) => {

        const { current, pageSize } = pagination;
        dispatch(setTableDataInner({ pageIndex: current, pageSize: pageSize }));
    };

    const fetchData = useCallback(() => {
        dispatch(getEmployeeTargetInner({ TARGET_ID: [selectedEMPTarget.ID], pageIndex, pageSize }));
    }, [dispatch, pageIndex, pageSize]);


    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize]);









    const columns = [
        {
            title: <span className="text-gray-500">Employee Name</span>,
            dataIndex: "EMPLOYEE_NAME",
            width: 150,


        },


        {
            title: <span className="text-gray-500">Department</span>,
            dataIndex: "DEPARTMENT",
            width: 150,


        },
        {
            title: <span className="text-gray-500">Branch</span>,
            dataIndex: "BRANCH_NAME",
            width: 150,


        },
        {
            title: <span className="text-gray-500">Total Amount</span>,
            dataIndex: "TOTAL_AMOUNT",
            width: 150,


        },
        {
            title: <span className="text-gray-500">Total Customer</span>,
            dataIndex: "TOTAL_CUSTOMERS",
            width: 150,


        },


    ];





    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];






    return (
        <>
            {contextHolder}

            <div className="bg-white m-4 p-8 rounded-xl">

                <div className="flex justify-between mb-6">


                    <div className="text-xl   !text-[#414141]">

                        <span className="font-bold ">
                            Employee Target Of -
                        </span>

                        <span className="ml-3" >
                            {monthNames[selectedEMPTarget.MONTH - 1]} {JSON.stringify(selectedEMPTarget.YEAR)}
                        </span>

                    </div>
                    <div className="flex">
                        <div className="flex items-center">

                            <EmployeeTargetInnerTableSerch />


                            <Button
                                style={{
                                    backgroundColor: "#096CAE",
                                    color: "#ffff",
                                    display: "flex",
                                    padding: "18px",
                                    borderRadius: "6px",
                                }}
                                onClick={() => navigate('/employeetarget')}
                            >
                                <RiArrowGoBackFill />

                                <p>Go Back</p>

                            </Button>
                        </div>
                    </div>






                </div>


                {loading ? (
                    <div className="w-full flex justify-center h-60 items-center">
                        <Spin size="large" />
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={data}
                        bordered
                        // scroll={{ x: 1300 }}
                        pagination={{
                            current: pageIndex,
                            pageSize: pageSize,
                            total: total,
                            showSizeChanger: true,
                            pageSizeOptions: [5, 10, 20],
                            onChange: handleTableChange,
                            onShowSizeChange: handleTableChange,
                        }}
                        onChange={handleTableChange}
                    />
                )}

            </div >
        </>
    );
};

export default EmployeeTargetInnerTable;
