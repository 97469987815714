import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, notification, Select } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleNewDialog,
  setSelectedEmployeeTarget,
} from "../store/stateSlice";
import {
  postEmployeeTarget,
  putEmployeeTarget,
  getEmployeeTarget,
  getBranch,
  getDepartment,
} from "../store/dataSlice";

const { Option } = Select;

const EmployeeTargetForm = ({ handleRefresh }) => {
  const [loading, setLoading] = useState(false);

  const [showSaveNext, setShowSaveNext] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedEmployeeTarget = useSelector(
    (state) => state.employeetarget.state.selectedEmployeeTarget
  );

  const validationSchema = Yup.object({
    DEPARTMENT_ID: Yup.string().required("Required"),
    BRANCH_ID: Yup.string().required("Required"),
    TOTAL_AMOUNT: Yup.string().required("Required"),
    MONTH: Yup.string().required("Required"),
    YEAR: Yup.string().required("Required"),
    TOTAL_CUSTOMERS: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedEmployeeTarget) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedEmployeeTarget]);

  function getMonthName(monthIndex) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear().toString();

  const initialValues = selectedEmployeeTarget || {
    BRANCH_ID: "",
    DEPARTMENT_ID: "",
    TOTAL_CUSTOMERS: "",
    TOTAL_AMOUNT: "",
    MONTH: currentMonth + 1,
    YEAR: currentYear,
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);

      const action = edit
        ? await dispatch(putEmployeeTarget(values))
        : await dispatch(postEmployeeTarget(values));
      if (action.payload.code == 200) {
        dispatch(getEmployeeTarget());
        setLoading(false);
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      } else if (action.payload.code == 304) {
        api.info({
          message: "target already assigned..",
        });
      } else if (action.payload.code == 402) {
        api.info({
          message: "employee not found",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const branch = useSelector(
    (state) => state?.employeetarget?.data?.branchList?.data
  );
  const department = useSelector(
    (state) => state?.employeetarget?.data?.departmentList?.data
  );

  // useEffect(() => {
  //     dispatch(getBranch());
  //     dispatch(getDepartment());
  // }, [dispatch]);

  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails?.[0]?.EMP_ID;

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it

  const filterData1 = JSON.parse(localStorage.getItem("filterData"));
  let BRANCH_ID;
  let DEPARTMENT_ID;
  if (ROLE_ID == 10) {
    BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
    DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
  }

  const dialog = useSelector(
    (state) => state?.employeetarget?.state?.newDialog
  );

  useEffect(() => {
    if (ROLE_ID == 10) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const depid = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
      dispatch(getBranch({ BRANCH_ID: branchIds }));
      dispatch(getDepartment({ DEPARTMENT_ID: depid }));
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const depid = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
      dispatch(getBranch({ BRANCH_ID: branchIds }));
      dispatch(getDepartment({ DEPARTMENT_ID: depid }));
    } else {
      dispatch(getBranch());
      dispatch(getDepartment());
    }
  }, [dialog]);

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, touched, errors, handleChange }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.BRANCH_ID && errors.BRANCH_ID
                        ? errors.BRANCH_ID
                        : ""
                    }
                    validateStatus={
                      touched.BRANCH_ID && errors.BRANCH_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Branch</span>
                    <Select
                      showSearch
                      placeholder="Select Branch"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "BRANCH_ID", value },
                        })
                      }
                      value={values.BRANCH_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {branch?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? errors.DEPARTMENT_ID
                        : ""
                    }
                    validateStatus={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Department</span>
                    <Select
                      showSearch
                      placeholder="Select Department"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "DEPARTMENT_ID", value },
                        })
                      }
                      value={values.DEPARTMENT_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {department?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.DEPARTMENT}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.TOTAL_CUSTOMERS && errors.TOTAL_CUSTOMERS
                        ? errors.TOTAL_CUSTOMERS
                        : ""
                    }
                    validateStatus={
                      touched.TOTAL_CUSTOMERS && errors.TOTAL_CUSTOMERS
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Total Customers</span>
                    <Input
                      name="TOTAL_CUSTOMERS"
                      placeholder="Total Customers"
                      value={values.TOTAL_CUSTOMERS}
                      onChange={handleChange}
                      type="number"
                      min="0"
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.TOTAL_AMOUNT && errors.TOTAL_AMOUNT
                        ? errors.TOTAL_AMOUNT
                        : ""
                    }
                    validateStatus={
                      touched.TOTAL_AMOUNT && errors.TOTAL_AMOUNT
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Total Amount</span>
                    <Input
                      name="TOTAL_AMOUNT"
                      placeholder="Total Amount"
                      value={values.TOTAL_AMOUNT}
                      onChange={handleChange}
                      type="number"
                      min="0"
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.MONTH && errors.MONTH ? errors.MONTH : ""}
                    validateStatus={
                      touched.MONTH && errors.MONTH ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Current Month</span>
                    <Select
                      showSearch
                      placeholder="Current  Month"
                      onChange={(value) => {
                        if (!values.MONTH) {
                          handleChange({
                            target: { name: "MONTH", value },
                          });
                        }
                      }}
                      value={values.MONTH || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                      style={{ backgroundColor: "white !important" }}
                    >
                      {Array.from({ length: 12 }, (_, index) => (
                        <Option
                          style={{ backgroundColor: "white !important" }}
                          key={index + 1}
                          value={index + 1}
                        >
                          {getMonthName(index)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.YEAR && errors.YEAR ? errors.YEAR : ""}
                    validateStatus={
                      touched.YEAR && errors.YEAR ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Current Year</span>
                    <Input
                      name="YEAR"
                      placeholder="YEAR"
                      value={values.YEAR}
                      onChange={handleChange}
                      disabled
                      style={{
                        padding: "8px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      className="!rounded"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      className="mr-4 !py-4 !px-6 border !rounded border-blue-500 text-blue-500"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="!py-4 !px-6 !rounded"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EmployeeTargetForm;
