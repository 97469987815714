import ApiService from "./ApiService";

export async function apiGetAllTransaction(data) {
    return ApiService.fetchData({
        url: "/api/transaction/get",
        method: "post",
        data,
    });
}
export async function apipostTransaction(data) {
    return ApiService.fetchData({
        url: "/api/transaction/createTransaction",
        method: "post",
        data,
    });
}

export async function apiGetAccountGroup(data) {
    return ApiService.fetchData({
        url: "/api/accountGroup/get",
        method: "post",
        data,
    });
}
export async function apiGetAccount(data) {
    return ApiService.fetchData({
        url: "/api/account/get",
        method: "post",
        data,
    });
}


export async function apiputTransaction(data) {
    return ApiService.fetchData({
        url: "/api/transaction/createTransaction",
        method: "post",
        data,
    });
}

export async function apiPuttTransactionSwitch(data) {
    return ApiService.fetchData({
        url: "/api/transaction/update",
        method: "put",
        data,
    });
}



export async function apiGettransactionDetails(data) {
    return ApiService.fetchData({
        url: "/api/transactionDetails/get",
        method: "post",
        data,
    });
}


export async function apiGettransactionDetailsID(data) {
    return ApiService.fetchData({
        url: "/api/transactionDetails/get",
        method: "post",
        data,
    });
}


