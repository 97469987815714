import React, { useEffect } from "react";

import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import ExpenseForm from "./Components/ExpenseForm";
import ExpenseTable from "./Components/ExpenseTable";
import { injectReducer } from "../../store";
import Expensereducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedExpense } from "./store/stateSlice";
import { getExpense } from "./store/dataSlice";
import ExpenseSearch from "./Components/ExpenseSearch";

injectReducer("expense", Expensereducer);

const Expense = () => {
  const dialog = useSelector((state) => state.expense.state.newDialog);

  const AddEdit = useSelector((state) => state.expense.state.selectedExpense);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedExpense(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            Expense Detail
          </div>
          <div className="flex">
            <div className="flex items-center">
              <ExpenseSearch />
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Expense" : "Add New Expense"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <ExpenseForm />
          </Modal>
        </div>

        <ExpenseTable />
      </div>
    </>
  );
};

export default Expense;
