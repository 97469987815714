import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Input,
  Form,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
  DatePicker,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { postEom, putEom, getEom } from "../store/dataSlice";
import moment from "moment";

const { Option } = Select;

const EomForm = ({ handleRefresh }) => {
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedEom = useSelector((state) => state.eom.state.selectedEom);
  const eomList = useSelector((state) => state.eom.data.eomList.data);
  const employeeIdList = useSelector(
    (state) => state.employeereport.data.employeeIdList.data
  );

  const validationSchema = Yup.object({
    EMP_ID: Yup.string().required("Required"),
    STATUS: Yup.boolean().required("Required"),
    MONTH: Yup.number().required("Required"),
    YEAR: Yup.number().required("Required"),
  });

  useEffect(() => {
    if (selectedEom) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedEom]);

  const initialValues = selectedEom || {
    EMP_ID: "",
    STATUS: true,
    MONTH: null,
    YEAR: null,
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log("Form values:", values);
    try {
      const monthNumber = new Date(values.MONTH).getMonth() + 1;
      // const actionValues = { ...values, MONTH: monthNumber };
      const action = edit
        ? await dispatch(putEom(values))
        : await dispatch(postEom(values));
      if (action.payload.code == 200) {
        //console.log("EOM submitted successfully!");
        dispatch(getEom());
        // dispatch(toggleNewDialog(false));
        api.success({
          message: "EOM Submitted Successfully.",
          duration: 1,
        });
        // handleRefresh();
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
        api.error({
          message: "Failed to submit EOM.",
          description: action.payload.error,
        });
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      api.error({
        message: "An unexpected error occurred.",
        description: error.toString(),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getMonthName = (monthIndex) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            submitForm,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.EMP_ID && errors.EMP_ID ? errors.EMP_ID : ""}
                    validateStatus={
                      touched.EMP_ID && errors.EMP_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Employee Name</span>
                    <Select
                      showSearch
                      placeholder="Select Employee name"
                      onChange={(value) =>
                        handleChange({ target: { name: "EMP_ID", value } })
                      }
                      value={values.EMP_ID}
                      className="h-[40px] rounded"
                    >
                      {employeeIdList?.map((type) => (
                        <Option
                          key={type.ID}
                          value={type.ID}
                          className="text-gray-400"
                        >
                          {type.FIRST_NAME} {type.LAST_NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.MONTH && errors.MONTH ? errors.MONTH : ""}
                    validateStatus={
                      touched.MONTH && errors.MONTH ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Month</span>
                    <Select
                      showSearch
                      placeholder="Current  Month"
                      onChange={(value) => {
                        if (!values.MONTH) {
                          handleChange({
                            target: { name: "MONTH", value },
                          });
                        }
                      }}
                      value={values.MONTH || undefined}
                      // filterOption={filterOption}
                      className="h-[40px] rounded"
                      style={{ backgroundColor: "white !important" }}
                    >
                      {Array.from({ length: 12 }, (_, index) => (
                        <Option
                          style={{ backgroundColor: "white !important" }}
                          key={index + 1}
                          value={index + 1}
                        >
                          {getMonthName(index)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.YEAR && errors.YEAR ? errors.YEAR : ""}
                    validateStatus={
                      touched.YEAR && errors.YEAR ? "error" : undefined
                    }
                  >
                    <label htmlFor="year-picker" className="text-xs">
                      Year
                    </label>
                    <DatePicker
                      id="year-picker"
                      name="YEAR"
                      picker="year"
                      value={values.YEAR ? moment(values.YEAR, "YYYY") : null}
                      onChange={(date, dateString) =>
                        setFieldValue("YEAR", dateString)
                      }
                      className="!rounded"
                      style={{ width: "100%" }}
                      aria-required="true"
                      aria-invalid={
                        touched.YEAR && errors.YEAR ? "true" : "false"
                      }
                      aria-describedby={
                        touched.YEAR && errors.YEAR ? "year-error" : undefined
                      }
                    />
                    {touched.YEAR && errors.YEAR && (
                      <div id="year-error" className="error-message">
                        {errors.YEAR}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        defaultChecked
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EomForm;
