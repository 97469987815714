import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import TransactionForm from "./Components/TransactionForm";
import TransactionTable from "./Components/TransactionTable";
import { injectReducer } from "../../store";
import Transactionreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedTransaction } from "./store/stateSlice";
import { getTransaction, getAllTransactionDetailsIDNull } from "./store/dataSlice";
import TransactionSearch from "./Components/TransactionSearch";

injectReducer("transaction", Transactionreducer);
const Transaction = () => {
  const dialog = useSelector((state) => state.transaction.state.newDialog);

  const AddEdit = useSelector((state) => state.transaction.state.selectedTransaction);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedTransaction(null));
    dispatch(getAllTransactionDetailsIDNull());
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
  };


  useEffect(() => {
    if (dialog) {
    } else {
      dispatch(setSelectedTransaction(null));
    }
  }, [dialog]);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Transaction Detail</div>
          <div className="flex">
            <div className="flex items-center">
              {/* <TransactionSearch /> */}
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Transaction</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Transaction" : "Add New Transaction"}

              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <TransactionForm />
          </Modal>
        </div>


        <TransactionTable />


      </div>
    </>
  );
};

export default Transaction;
