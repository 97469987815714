import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "employeeDetailList/data",
  initialState: {
    deleteConfirmation: false,
    selectedAttendance: "",
    newDialog: false,
    newDialog2: false,

    drowerDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedAttendance: (state, action) => {
      state.selectedAttendance = action.payload;
    },
    setSelectedSalarySlipinner: (state, action) => {
      state.selectedSalarySlipinner = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.drawerVisible = action.payload !== undefined ? action.payload : !state.drawerVisible;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    toggleNewDialog2: (state, action) => {
      state.newDialog2 = action.payload;
    },
    toggleDrower: (state, action) => {
      state.drowerDialog = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedAttendance,
  toggleNewDialog,
  setSelectedSalarySlipinner,
  toggleNewDialog2,
} = stateSlice.actions;

export default stateSlice.reducer;
