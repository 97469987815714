import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { getState, postState, putState } from "../store/dataSlice";
import { DatePicker } from "antd";

const StateForm = () => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  // const filterOption = (input, option) =>
  //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const selectedDistributor = useSelector(
    (state) => state.state.state.selectedDistributor
  );

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    SHORT_CODE: Yup.string(),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedDistributor) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedDistributor]);

  const initialValues = selectedDistributor || {
    NAME: "",
    STATUS: "",
    SHORT_CODE: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const action = edit
        ? await dispatch(putState(values))
        : await dispatch(postState(values));

      if (action.payload.code === 200) {
        dispatch(getState());

        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveAndClose = async (submitForm) => {
    submitForm().then((actionCode) => {
      ////console.log(actionCode);

      if (actionCode === 200) {
        handleCancel();
      }
    });
  };
  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.NAME && errors.NAME ? errors.NAME : ""}
                    validateStatus={
                      touched.NAME && errors.NAME ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Name</span>
                    <Input
                      name="NAME"
                      placeholder="Name"
                      value={values.NAME}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.SHORT_CODE && errors.SHORT_CODE
                        ? errors.SHORT_CODE
                        : ""
                    }
                    validateStatus={
                      touched.SHORT_CODE && errors.SHORT_CODE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Short Code</span>
                    <Input
                      name="SHORT_CODE"
                      placeholder="Short COde"
                      value={values.SHORT_CODE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">STATUS</span>
                    <div className="flex items-center">
                      <Switch
                        name="STATUS"
                        checked={values.STATUS}
                        onChange={(checked) => {
                          handleChange({
                            target: { name: "STATUS", value: checked },
                          });
                        }}
                        onBlur={handleBlur}
                        className="!rounded-full custom-switch"
                        style={{ padding: "8px 5px" }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <div className="flex relative">
                      <Button
                        onClick={handleCancel}
                        type="primary"
                        className="mr-4"
                      >
                        Cancel
                      </Button>
                      <div
                        className="flex items-center"
                        // onMouseEnter={() => setShowSaveNext(true)}
                        // onMouseLeave={() => setShowSaveNext(false)}
                      >
                        {!edit && (
                          <Button
                            type="primary"
                            className="!p-2"
                            onClick={() => handleSaveAndClose(submitForm)}
                          >
                            Save and Close
                          </Button>
                        )}
                      </div>
                      {edit && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={handleCancel}
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default StateForm;
