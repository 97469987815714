import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  DatePicker,
  Radio,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import axios from "axios";
import moment from "moment";
import {
  postTask,
  getTask,
  getDepartmentId,
  getEmployee,
} from "../store/dataSlice";

const TaskForm = () => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;

  useEffect(() => {
    dispatch(getTask());
    dispatch(getEmployee());
    dispatch(getDepartmentId());
    // fetchEmployeeData(); // Fetch employee data on component mount
  }, [dispatch]);

  const employeeIdList = useSelector(
    (state) => state.employeereport.data.employeeIdList.data
  );
  const departmentIdList = useSelector(
    (state) => state.employee.data.departmentIdList?.data
  );

  const validationSchema = Yup.object({
    DEPARTMENT_ID: Yup.string().required("Required"),
    EMP_ID: Yup.string().required("Required"),
    DESCRIPTION: Yup.string().required("Required"),
    // ASSIGNED_BY_ID: Yup.string().required("Required"),
    // COMPLETION_STATUS: Yup.string().required("Required"),
    PRIORITY: Yup.string().required("Required"),
    // COMPLETION_DATETIME: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const userData = JSON.parse(localStorage.getItem("UserData"));
  const EMP_ID = userData?.[0]?.EMP_ID;
  //console.log(EMP_ID);

  const initialValues = {
    STATUS: true,
    EMP_ID: "",
    DEPARTMENT_ID: "",
    START_DATETIME: "",
    END_DATETIME: "",
    DESCRIPTION: "",
    ASSIGNED_BY_ID: EMP_ID,
    COMPLETION_STATUS: "",
    PRIORITY: "",
    COMPLETION_DATETIME: null,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);

      const formattedValues = {
        ...values,
        START_DATETIME: values.START_DATETIME
          ? moment(values.START_DATETIME).format("YYYY-MM-DD HH:mm:ss")
          : null,
        END_DATETIME: values.END_DATETIME
          ? moment(values.END_DATETIME).format("YYYY-MM-DD HH:mm:ss")
          : null,
      };

      const action = await dispatch(postTask(formattedValues));

      if (action.payload.status === 200) {
        dispatch(toggleNewDialog(false));
        setLoading(false);

        dispatch(getTask());
        if (action.payload.data.code === 200) {
          api.success({
            message: "Form Submitted Successfully.",
          });
        } else if (action.payload.data.code === 304) {
          api.error({
            message: "Mobile Number Already Exists.",
          });
        } else {
          console.error("Error occurred during form submission:");
        }
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.EMP_ID && errors.EMP_ID ? errors.EMP_ID : ""}
                    validateStatus={
                      touched.EMP_ID && errors.EMP_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Employee Name</span>
                    <Select
                      showSearch
                      placeholder="Select Employee Name"
                      onChange={(value) => setFieldValue("EMP_ID", value)}
                      value={values.EMP_ID}
                      className="h-[40px] rounded"
                    >
                      {employeeIdList?.map((employee) => (
                        <Option
                          key={employee.ID}
                          value={employee.ID}
                          className="text-gray-400"
                        >
                          {employee.FIRST_NAME} {employee.MIDDLE_NAME}{" "}
                          {employee.LAST_NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? errors.DEPARTMENT_ID
                        : ""
                    }
                    validateStatus={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Department Name</span>
                    <Select
                      showSearch
                      placeholder="Select Department Name"
                      onChange={(value) =>
                        setFieldValue("DEPARTMENT_ID", value)
                      }
                      value={values.DEPARTMENT_ID}
                      className="h-[40px] rounded"
                    >
                      {departmentIdList?.map((department) => (
                        <Option
                          key={department.ID}
                          value={department.ID}
                          className="text-gray-400"
                        >
                          {department.DEPARTMENT}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      (touched.START_DATETIME || touched.END_DATETIME) &&
                      (errors.START_DATETIME || errors.END_DATETIME)
                        ? errors.START_DATETIME || errors.END_DATETIME
                        : ""
                    }
                    validateStatus={
                      (touched.START_DATETIME || touched.END_DATETIME) &&
                      (errors.START_DATETIME || errors.END_DATETIME)
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Select Date & Time Range</span>
                    <RangePicker
                      showTime={{ format: "HH:mm:ss" }}
                      format="YYYY-MM-DD HH:mm:ss"
                      value={
                        values.START_DATETIME && values.END_DATETIME
                          ? [
                              moment(values.START_DATETIME),
                              moment(values.END_DATETIME),
                            ]
                          : undefined
                      }
                      onChange={(dates) => {
                        if (dates && dates.length === 2) {
                          setFieldValue(
                            "START_DATETIME",
                            dates[0].toISOString()
                          );
                          setFieldValue("END_DATETIME", dates[1].toISOString());
                        } else {
                          setFieldValue("START_DATETIME", "");
                          setFieldValue("END_DATETIME", "");
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.PRIORITY && errors.PRIORITY ? errors.PRIORITY : ""
                    }
                    validateStatus={
                      touched.PRIORITY && errors.PRIORITY ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Task Priority</span>
                    <Radio.Group
                      name="PRIORITY"
                      onChange={(e) =>
                        setFieldValue("PRIORITY", e.target.value)
                      }
                      onBlur={handleBlur}
                      value={values.PRIORITY}
                    >
                      <Radio value="1">High</Radio>
                      <Radio value="2">Medium</Radio>
                      <Radio value="3">Low</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? errors.DESCRIPTION
                        : ""
                    }
                    validateStatus={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Description</span>

                    <TextArea
                      rows={4}
                      placeholder="You Can Add Maximum 512 Characters.."
                      maxLength={6000}
                      value={values.DESCRIPTION}
                      onChange={(e) =>
                        setFieldValue("DESCRIPTION", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  className="!py-4 !px-6 !rounded"
                  htmlType="submit"
                  loading={loading}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleCancel}
                  className="ml-4 !py-4 !px-6 border !rounded border-blue-500 text-blue-500"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TaskForm;
