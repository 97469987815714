import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FaFilter } from "react-icons/fa";
import ProductinterestTable from "./Components/ProductinterestTable";
import { injectReducer } from "../../store";
import Designationreducer from "./store";
import { toggleNewDialog } from "./store/stateSlice";
import ProductionintrestXls from "./Components/ProductionintrestXls";

injectReducer("Productinterest", Designationreducer);

const Productinterest = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.Productinterest.state.newDialog);

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            All Product interest{" "}
          </div>
          <div className="flex">
            <div className="flex items-center">
              <ProductionintrestXls />
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>

        <ProductinterestTable />
      </div>
    </>
  );
};

export default Productinterest;
