import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "ProductinterestList/state",
  initialState: {
    deleteConfirmation: false,
    selectedDesignation: "",
    newDialog: false,
    filters: {
      BRANCH_ID: null,
      DEPARTMENT_ID: null,
      CUSTOMER_ID: null,
      EMPLOYEE_ID: null,
      SERVICE_ID: null,
      IS_CONVERTED: null,
      FROM_DATE: null,
      TO_DATE: null,
    },
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedDesignation: (state, action) => {
      state.selectedDesignation = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedDesignation,
  toggleNewDialog,
  setFilters,
} = stateSlice.actions;

export default stateSlice.reducer;
