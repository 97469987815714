import ApiService from "./ApiService";

export async function apiGetAllService(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/service/get",
    method: "post",
    data,
  });
}
export async function apipostService(data) {
  return ApiService.fetchData({
    url: "/api/service/create",
    method: "post",
    data,
  });
}

export async function apiputService(data) {
  return ApiService.fetchData({
    url: "/api/service/update",
    method: "put",
    data,
  });
}
