import React, { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Modal, Select } from "antd";
import { FaUser, FaSearch, FaFilter } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import CustomerTable from "./components/CustomerTable";
import CustomerSearch from "./components/CustomerSearch";
import { injectReducer } from "../../store";
import Customerreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog } from "./store/stateSlice";
import EmpReportXls from "./components/EmpReportXls";
injectReducer("customer", Customerreducer);

const Customer = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.customer.state.newDialog);

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Customer</div>

          <div className="flex">
            <EmpReportXls />

            <div
              className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
              onClick={handleFilterClick}
            >
              <FaFilter />
            </div>
            <div className="flex items-center">
              <CustomerSearch />
            </div>
          </div>
        </div>

        <CustomerTable />
      </div>
    </>
  );
};

export default Customer;
