import React, { useEffect, useCallback } from "react";
import { Table, Select, Switch, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getExpenseType, setTableData } from "../store/dataSlice";
import { useLocation } from "react-router-dom";
const ExpenseTypeTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.expenseType.data.tableData
  );
  const loading = useSelector((state) => state.expenseType.data.loading);
  const data = useSelector(
    (state) => state.expenseType.data.expenseTypeList.data
  );

  const fetchData = useCallback(() => {
    dispatch(getExpenseType({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
  }, [location.pathname, dispatch, pageSize]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    ////console.log(current);
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const columns = [
    {
      title: <span className="text-gray-800">Name</span>,
      dataIndex: "NAME",
      render: (text) => (
        <span className="text-gray-500 font-semibold ">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Sequence Number</span>,
      dataIndex: "SEQ_NO",

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Status</span>,
      dataIndex: "STATUS",

      render: (text, record) => {
        const handleChange = (checked) => {
          ////console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginBottom: "16px" }}>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </>
  );
};

export default ExpenseTypeTable;
