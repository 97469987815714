import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Form, Input, Row, Col, Button, Select, notification, Switch } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { getRoleAssign, postRoleAssign, putRoleAssign, getBranchId, getDepartmentId, getEmployee, getRoleId } from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";

const RoleAssignForm = () => {
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const selectedRoleAssign = useSelector(
    (state) => state.roleAssign.state.selectedRoleAssign
  );
  const BRANCH_NAME = selectedRoleAssign?.BRANCH_NAME?.split(",") || [];
  const DEPARTMENT = selectedRoleAssign?.DEPARTMENT?.split(",") || [];
  const { Option } = Select;
  const validationSchema = Yup.object({
    ROLE_ID: Yup.string().required("Required"),
    EMP_ID: Yup.string().required("Required"),
    DEPARTMENT_ID: Yup.array().min(1, "At least one department must be selected").required("Required"),
    BRANCH_ID: Yup.array().min(1, "At least one branch must be selected").required("Required"),
    STATUS: Yup.boolean().required("Required"),


  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedRoleAssign) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedRoleAssign]);


  useEffect(() => {
    dispatch(getBranchId());
    dispatch(getDepartmentId());
    dispatch(getEmployee());
    dispatch(getRoleId());


  }, []);

  // const roleIdList = useSelector(
  //   (state) => state.employee.data.roleIdList?.data
  // );
  // const empIDList = useSelector((state) => state.employee.data.employeeList?.data);
  // const departmentIdList = useSelector(
  //   (state) => state.employee.data.departmentIdList?.data
  // );
  // const branchIdList = useSelector(
  //   (state) => state.employee.data.branchIdList?.data
  // );


  const roleIdList = useSelector(
    (state) => state.roleAssign.data.roleIdList?.data
  );
  const empIDList = useSelector((state) => state.roleAssign.data.employeeList?.data);
  const departmentIdList = useSelector(
    (state) => state.roleAssign.data.departmentIdList?.data
  );
  const branchIdList = useSelector(
    (state) => state.roleAssign.data.branchIdList?.data
  );

  const initialValues = {
    ROLE_ID: selectedRoleAssign?.ROLE_ID || null,
    EMP_ID: selectedRoleAssign?.EMP_ID || null,
    DEPARTMENT_ID: selectedRoleAssign?.DEPARTMENT_ID?.split(",").map(id => parseInt(id)) || [],
    BRANCH_ID: selectedRoleAssign?.BRANCH_ID?.split(",").map(id => parseInt(id)) || [],
    STATUS: selectedRoleAssign?.STATUS || true,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {

      const updatedFilters = {
        ...selectedRoleAssign,
        BRANCH_ID: values.BRANCH_ID.join(','),
        DEPARTMENT_ID: values.DEPARTMENT_ID.join(','),


      };
      const action = edit
        ? await dispatch(putRoleAssign(updatedFilters))
        : await dispatch(postRoleAssign(values));

      if (action.payload.status < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getRoleAssign());
        if (action.payload.data.code === 200) {
          api.success({
            message: "Form Submitted Successfully.",
          });
        } else if (action.payload.data.code === 304) {
          api.error({
            message: "Already Exist",
          });
        }
      }
      else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };



  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <>

      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>

      <div className="mt-4">
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.ROLE_ID && errors.ROLE_ID ? errors.ROLE_ID : ""}
                    validateStatus={
                      touched.ROLE_ID && errors.ROLE_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Role Name</span>
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Role Name"
                      onChange={(value) => setFieldValue("ROLE_ID", value)}
                      value={values.ROLE_ID}
                      className="rounded"
                    >
                      {roleIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.EMP_ID && errors.EMP_ID ? errors.EMP_ID : ""}
                    validateStatus={
                      touched.EMP_ID && errors.EMP_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Employee Name</span>
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Employee Name"
                      onChange={(value) => setFieldValue("EMP_ID", value)}
                      value={values.EMP_ID}
                      className="rounded"
                    >
                      {empIDList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {`${type.FIRST_NAME} ${type.MIDDLE_NAME} ${type.LAST_NAME}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? errors.DEPARTMENT_ID
                        : ""
                    }
                    validateStatus={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Department Name</span>
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Department Name"
                      onChange={(value) => setFieldValue("DEPARTMENT_ID", value)}
                      value={values.DEPARTMENT_ID}

                      className="w-full custom-select h-11"
                    >
                      {departmentIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.DEPARTMENT}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.BRANCH_ID && errors.BRANCH_ID ? errors.BRANCH_ID : ""}
                    validateStatus={
                      touched.BRANCH_ID && errors.BRANCH_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Branch</span>
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select Branch"
                      onChange={(value) => setFieldValue("BRANCH_ID", value)}
                      value={values.BRANCH_ID}
                      // className="h-[40px] rounded"
                      className="w-full custom-select h-11"
                    >
                      {branchIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        defaultChecked
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button onClick={handleCancel} type="primary" className="mr-4">
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                      {edit ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RoleAssignForm;
