import ApiService from "./ApiService";

export async function apiGetAllTask(data) {
  return ApiService.fetchData({
    url: "/api/task/get",
    method: "post",
    data,
  });
}
export async function apipostTask(data) {
  return ApiService.fetchData({
    url: "/api/task/create",
    method: "post",
    data,
  });
}

export async function apiGetAllEmployeeId(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}
export async function apiGetAllDepartmentId(params) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    params,
  });
}