import React, { useEffect, useCallback, useState } from "react";
import { Table, Select, Switch, Spin, DatePicker } from "antd";
import { FaEye } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { setTableData } from "../store/dataSlice";
import { increaseCounter, toggleNewDialog } from "../store/stateSlice";
import { useNavigate, useLocation } from "react-router-dom";
import CustomerFilter from "./CustomerFilter";

const CustomerTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.customer.data.tableData
  );

  const data = useSelector((state) => state.customer.data.customerList.data);

  const loading = useSelector((state) => state.customer.data.loading);

  const onView = (record) => {
    navigate("/customer/details", { state: { record } });
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(increaseCounter({ counter: 0 }));
    dispatch(toggleNewDialog(false));
  }, [location.pathname]);

  const columns = [
    {
      title: <span className="text-gray-800">Action</span>,
      dataIndex: "action",
      fixed: "left",
      align: "center",
      width: 110,
      render: (_, record) => (
        <>
          <div className="flex justify-center px-2 ">
            <span
              onClick={() => onView(record)}
              className="text-xl text-[#096CAE] cursor-pointer "
            >
              <FaEye />
            </span>
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-800">First Name</span>,
      dataIndex: "FIRST_NAME",
      width: 180,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Last Name</span>,
      width: 180,
      dataIndex: "LAST_NAME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Mobile No.</span>,
      dataIndex: "MOBILE_NO",
      width: 200,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Address</span>,
      dataIndex: "ADDRESS",
      width: 240,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Email</span>,
      dataIndex: "EMAIL_ID",
      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Description</span>,
      dataIndex: "DESCRIPTION",
      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Employee Name</span>,
      dataIndex: "CREATED_EMP_NAME",
      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Is Working</span>,
      dataIndex: "IS_WORKING",
      width: 130,
      render: (text) => {
        ////console.log(text);
        let style;
        let displayText;

        if (text === 0) {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Not Working";
        } else if (text === 1) {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Working";
        }

        return <span style={style}>{displayText}</span>;
      },
    },

    {
      title: <span className="text-gray-800">District</span>,
      dataIndex: "DISTRICT",
      width: 180,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: <span className="text-gray-800">Pin Code</span>,
      dataIndex: "PINCODE",
      width: 140,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">City</span>,
      dataIndex: "CITY",
      width: 180,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        const handleChange = (checked) => {
          ////console.log("Switch Changed to:", checked ?
        };
        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];

  return (
    <>
      <div>
        <CustomerFilter />
        <div style={{ marginBottom: "16px" }}>
          {loading ? (
            <div className="w-full flex justify-center h-60 items-center">
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 1300 }}
              pagination={{
                current: pageIndex,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20],
                onChange: handleTableChange,
                onShowSizeChange: handleTableChange,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerTable;
