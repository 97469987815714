import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { Button, Select, Radio } from "antd";

import { useSelector, useDispatch } from "react-redux";
import {
  getEmployee,
  getBranchId,
  getDepartmentId,
  getOrganisation,
  getDesignationId,
} from "../store/dataSlice";
import { toggleFilter, setFilters } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";

const EmpFilter = () => {
  const dispatch = useDispatch();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.employee.data.tableData
  );
  const dialog = useSelector((state) => state?.employee?.state?.filterToggle);
  const filters = useSelector((state) => state?.employee?.state?.filters);

  const fetchData = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      ORG_ID:
        filters?.ORG_ID == null ||
        (Array.isArray(filters?.ORG_ID) && filters?.ORG_ID.length === 0)
          ? null
          : filters?.ORG_ID,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      DESIGNATION_ID:
        filters?.DESIGNATION_ID == null ||
        (Array.isArray(filters?.DESIGNATION_ID) &&
          filters?.DESIGNATION_ID.length === 0)
          ? null
          : filters?.DESIGNATION_ID,

      // SEARCH_FILTER: searchQuery,
    };
    dispatch(getEmployee(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const switchs = useSelector((state) => state?.employee?.state?.switchHappen);

  const switchHandel = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      ORG_ID:
        filters?.ORG_ID == null ||
        (Array.isArray(filters?.ORG_ID) && filters?.ORG_ID.length === 0)
          ? null
          : filters?.ORG_ID,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      DESIGNATION_ID:
        filters?.DESIGNATION_ID == null ||
        (Array.isArray(filters?.DESIGNATION_ID) &&
          filters?.DESIGNATION_ID.length === 0)
          ? null
          : filters?.DESIGNATION_ID,

      // SEARCH_FILTER: searchQuery,
    };
    dispatch(getEmployee(updatedFilters));
  }, [dispatch, switchs]);

  useEffect(() => {
    switchHandel();
  }, [switchs]);

  //console.log("filters", filters);

  useEffect(() => {
    dispatch(
      setFilters({
        ORG_ID: null,
        DEPARTMENT_ID: null,
        BRANCH_ID: null,
        DESIGNATION_ID: null,
        STATUS: 1,
        SEARCH_FILTER: null,
      })
    );

    dispatch(getEmployee({ pageIndex, pageSize }));
  }, [dialog]);

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleChange = (key, value) => {
    dispatch(setFilters({ ...filters, [key]: value }));
  };

  const organisationList = useSelector(
    (state) => state.employee.data.organisationList?.data
  );
  const departmentIdList = useSelector(
    (state) => state.employee.data.departmentIdList.data
  );
  const branchIdList = useSelector(
    (state) => state.employee.data.branchIdList.data
  );
  const designationIdList = useSelector(
    (state) => state.employee.data.designationIdList.data
  );

  useEffect(() => {
    dispatch(getBranchId());
    dispatch(getOrganisation());
    dispatch(getDepartmentId());
    dispatch(getDesignationId());
  }, [dispatch]);

  const handleSubmit = (filters) => {
    const updatedFilters = {
      ...filters,
      pageIndex: 1,
      pageSize,
      ORG_ID:
        filters?.ORG_ID == null ||
        (Array.isArray(filters?.ORG_ID) && filters?.ORG_ID.length === 0)
          ? null
          : filters?.ORG_ID,

      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,

      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
      DESIGNATION_ID:
        filters?.DESIGNATION_ID == null ||
        (Array.isArray(filters?.DESIGNATION_ID) &&
          filters?.DESIGNATION_ID.length === 0)
          ? null
          : filters?.DESIGNATION_ID,
    };
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(getEmployee(updatedFilters));
  };

  return (
    <>
      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>

      <div className="filter mb-4">
        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ START  ------- */}
        {dialog ? (
          <div className="p-4 bg-white rounded border border-dashed">
            <div className="grid grid-cols-4 gap-4  ">
              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Orgamisation
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="ORGANISATION"
                    onChange={(value) => handleChange("ORG_ID", value)}
                  >
                    {organisationList?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.ORGANISATION}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Department
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Department"
                    onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                  >
                    {departmentIdList?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.DEPARTMENT}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Branch
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Branchs"
                    onChange={(value) => handleChange("BRANCH_ID", value)}
                  >
                    {branchIdList?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.NAME}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Designation
                </label>
                <div className="">
                  <Select
                    showSearch
                    mode="multiple"
                    filterOption={filterOption}
                    className="w-full custom-select h-11"
                    placeholder="Select Designation"
                    onChange={(value) => handleChange("DESIGNATION_ID", value)}
                  >
                    {designationIdList?.map((type) => (
                      <Select.Option key={type.ID} value={type.ID}>
                        {type.DESIGNATION}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-span-4 md:col-span-1">
                <label className="block mb-1 font-semibold text-gray-500">
                  Status
                </label>
                <div className="">
                  <Radio.Group
                    // onChange={onChangeradio}
                    onChange={(value) => {
                      // //console.log("value---------", value.target.value)
                      handleChange("STATUS", value.target.value);
                    }}
                    console
                    value={filters?.STATUS}
                  >
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                    <Radio value={null} defaultChecked={true}>
                      {" "}
                      All
                    </Radio>
                  </Radio.Group>
                </div>
              </div>

              {/* <div className="col-span-4 md:col-span-1 mb-6">
                                <label className="block mb-1 font-semibold text-gray-500">
                                    Pick Date
                                </label>
                                <div className="">
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            className="w-full  h-11"
                                            onChange={handleDateChange}
                                        />
                                    </Space>
                                </div>
                            </div> */}
            </div>

            <div className="flex items-end justify-end mt-7">
              <div className="flex">
                <Button
                  type="default"
                  className="mr-4 py-4 px-6 border border-blue-500"
                  onClick={() => {
                    dispatch(
                      setFilters({
                        ORG_ID: null,
                        DEPARTMENT_ID: null,
                        BRANCH_ID: null,
                        DESIGNATION_ID: null,
                        STATUS: null,
                        SEARCH_FILTER: null,
                      })
                    );

                    dispatch(toggleFilter(false));
                    dispatch(setTableData({ pageIndex: 1, pageSize }));
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  className="py-4 px-6"
                  onClick={() => {
                    handleSubmit(filters);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ END  ------- */}
      </div>
    </>
  );
};

export default EmpFilter;
