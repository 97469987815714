import ApiService from "./ApiService";

export async function apiGetSalaryslip(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/attendenceCalender/get",
    method: "post",
    data,
  });
}
export async function apiGetAllEmployeeId(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}

export async function apiGetBranch(data) {
  return ApiService.fetchData({
    url: "/api/branch/getBranchData",
    method: "post",
    data,
  });
}

export async function apiGetDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    data,
  });
}

export async function apiGetEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}

export async function apiGetAllHoliday(data) {
  return ApiService.fetchData({
    url: "/api/holiday/get",
    method: "post",
    data,
  });
}
export async function apipostSalaryslip(data) {
  return ApiService.fetchData({
    url: "/api/salaryslip/createSalarySlip",
    method: "post",
    data,
  });
}
export async function apiGetSalaryslipTable(data) {
  return ApiService.fetchData({
    url: "/api/salaryslip/get",
    method: "post",
    data,
  });
}

export async function apiGetSalaryslipinner(data) {
  return ApiService.fetchData({
    url: "/api/salaryslipDetails/get",
    method: "post",
    data,
  });
}
