import ApiService from "./ApiService";

export async function apiGetAllOrganisation(data) {
  return ApiService.fetchData({
    url: "/api/organisation/get",
    method: "post",
    data,
  });
}

export async function apipostOrganisation(data) {
  return ApiService.fetchData({
    url: "/api/organisation/create",
    method: "post",
    data,
  });
}

export async function apiputOrganisation(data) {
  return ApiService.fetchData({
    url: "/api/organisation/update",
    method: "put",
    data,
  });
}

export async function apigetStateId(data) {
  return ApiService.fetchData({
    url: "/api/state/getStateData",
    method: "post",
    data,
  });
}

export async function apiGetImages(data) {
  // ////console.log("params", data);
  return ApiService.fetchData({
    url: "/static/orgImg/",
    method: "post",
    data,
  });
}

export async function apipostOrganisationimg(data) {
  return ApiService.fetchData({
    url: "/upload/orgImg",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
}
