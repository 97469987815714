import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDetails, setTableData } from "../store/dataSlice";
import { Table, Spin } from "antd";
import moment from "moment";
import { toggleNewDialog } from "../../Dashboard/store/stateSlice";
import { useLocation } from "react-router-dom";

const EmployeeDetailsTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageIndex, pageSize, total, MONTH, YEAR } = useSelector(
    (state) => state.employeeDetails.data.tableData
  );
  const loading = useSelector((state) => state.employeeDetails.data.loading);
  const data = useSelector(
    (state) => state.employeeDetails.data.employeeDetailsList?.data
  );

  // //console.log("Table data", data);
  const filterData = useSelector(
    (state) => state.employeeDetails.data.tableData.filterData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    dispatch(
      getEmployeeDetails({
        MONTH: MONTH || currentMonth,
        YEAR: YEAR || currentYear,
        pageIndex,
        pageSize,
      })
    );
  }, [dispatch, pageIndex, pageSize, data]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(
          getEmployeeDetails({
            MONTH: moment().month() + 1,
            YEAR: moment().year(),
            pageIndex: 1,
            pageSize,
          })
        );

        dispatch(
          setTableData({
            MONTH: moment().month() + 1,
            YEAR: moment().year(),
            pageIndex: 1,
            pageSize,
          })
        );

        dispatch(toggleNewDialog(false));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.pathname, pageSize]);

  const columns = [
    // {
    //   title: <span className="text-gray-500">Action</span>,
    //   dataIndex: "action",
    //   width: 155,
    //   fixed: "left",
    //   render: (_, record) => (
    //     <>
    //       <div className="flex items-center">
    //         <span
    //           onClick={() => onEdit(record)}
    //           className="text-2xl text-[#096CAE] cursor-pointer"
    //         >
    //           <MdEdit />
    //         </span>
    //         <span
    //           onClick={() => onShow(record)}
    //           className="text-2xl ml-2 text-red-500 cursor-pointer"
    //         >
    //           <FaRegImage />
    //         </span>

    //         <span
    //           onClick={() => onShowCalender(record)}
    //           className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
    //         >
    //           <SlCalender />
    //         </span>
    //         <span
    //           onClick={() => onShowPdf(record)}
    //           className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
    //         >
    //           <FaFilePdf />
    //         </span>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FIRST_NAME",
      width: 200,
      render: (_, record) =>
        `${record.FIRST_NAME} ${record.MIDDLE_NAME ? record.MIDDLE_NAME : ""} ${
          record.LAST_NAME ? record.LAST_NAME : ""
        }`,
    },

    {
      title: <span className="text-gray-500">Month</span>,
      dataIndex: "MONTH",
      align: "center",
      width: 100,
      render: (value) => {
        switch (value) {
          case 1:
            return "January";
          case 2:
            return "February";
          case 3:
            return "March";
          case 4:
            return "April";
          case 5:
            return "May";
          case 6:
            return "June";
          case 7:
            return "July";
          case 8:
            return "August";
          case 9:
            return "September";
          case 10:
            return "October";
          case 11:
            return "November";
          case 12:
            return "December";
          default:
            return "";
        }
      },
    },
    {
      title: <span className="text-gray-500">Year</span>,
      dataIndex: "YEAR",
      align: "center",
      width: 120,
    },

    {
      title: <span className="text-gray-500">Full Days</span>,
      dataIndex: "FULL_DAYS",
      align: "center",
      width: 120,
      render: (value, record) => {
        let fullDays = 0;
        for (let i = 1; i <= 31; i++) {
          const dayIn = record[`DAYIN_${i}`];
          const dayOut = record[`DAYOUT_${i}`];
          if (
            dayIn <= record["DAYIN_TIME"] &&
            dayOut >= record["DAYOUT_TIME"]
          ) {
            fullDays++;
          }
        }

        return fullDays;
      },
    },

    {
      title: <span className="text-gray-500">Half Days</span>,
      dataIndex: "",
      align: "center",
      width: 120,
      render: (value, record) => {
        let halfDays = 0;
        for (let i = 0; i <= 31; i++) {
          const dayIn = record[`DAYIN_${i}`];
          const dayOut = record[`DAYOUT_${i}`];

          if (
            (dayIn > record["DAYIN_TIME"] || dayIn < record["INTERVAL_TIME"]) &&
            dayOut >= record["DAYOUT_TIME"]
          ) {
            halfDays++;
          } else if (
            dayIn <= record["DAYIN_TIME"] &&
            (dayOut > record["INTERVAL_TIME"] || dayOut < record["DAYOUT_TIME"])
          ) {
            halfDays++;
          }
        }

        return halfDays;
      },
    },

    {
      title: <span className="text-gray-500">Absent</span>,
      dataIndex: "",
      align: "center",
      width: 120,
      render: (value, record) => {
        let absent = 0;
        for (let i = 1; i <= 31; i++) {
          const dayIn = record[`DAYIN_${i}`];
          const dayOut = record[`DAYOUT_${i}`];

          if (
            dayIn <= record["DAYIN_TIME"] &&
            dayOut < record["INTERVAL_TIME"]
          ) {
            absent++;
          } else if (
            dayIn > record["DAYIN_TIME"] &&
            dayOut < record["DAYOUT_TIME"]
          ) {
            absent++;
          } else if (!dayOut) {
            absent++;
          }
        }
        return absent;
      },
    },
  ];

  for (let i = 1; i <= 31; i++) {
    columns.push({
      title: <span className="text-gray-500">Day {i}</span>,
      dataIndex: `DAYIN_${i}`,
      align: "center",
      width: 150,
      render: (value, record) => {
        const dayIn = record[`DAYIN_${i}`];
        const dayOut = record[`DAYOUT_${i}`];

        if (!dayIn || !dayOut) {
          return "---";
        }

        const diff = moment(dayOut, "HH:mm:ss").diff(moment(dayIn, "HH:mm:ss"));
        const hours = Math.floor(diff / 3600000);
        const minutes = Math.floor((diff % 3600000) / 60000);
        const seconds = Math.floor((diff % 60000) / 1000);
        return `${hours.toString().padStart(2, "0")} : ${minutes
          .toString()
          .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
      },
    });
  }

  return (
    <div className="main-content bg-white mt-4 rounded-md">
      <div className="w-full h-full flex items-center justify-center">
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              onChange: handleTableChange,
              onShowSizeChange: handleTableChange,
            }}
            scroll={{ x: 1500, y: 400 }}
            bordered
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeDetailsTable;
