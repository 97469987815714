import ApiService from "./ApiService";

export async function apiGetAllPrice(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/priceList/getPriceListData",
    method: "post",
    data,
  });
}

export async function apipostPrice(data) {
  return ApiService.fetchData({
    url: "/api/priceList/addBulk",
    method: "post",
    data,
  });
}

export async function apiputPrice(data) {
  return ApiService.fetchData({
    url: "/api/priceListDetails/update",
    method: "put",
    data,
  });
}

export async function apiGetAllPriceTable(data) {
  return ApiService.fetchData({
    url: "/api/priceList/get",
    method: "Post",
    data,
  });
}

export async function apiGetpriceListDetails(data) {
  return ApiService.fetchData({
    url: "/api/priceListDetails/get",
    method: "Post",
    data,
  });
}
