import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllPrice,
  apipostPrice,
  apiputPrice,
  apiGetAllPriceTable,
  apiGetpriceListDetails,
} from "../../../services/PriceService";

export const getpriceListDetails = createAsyncThunk(
  "price/data/getpriceListDetails",
  async (data) => {
    const response = await apiGetpriceListDetails(data);
    return response.data;
  }
);

export const getPrice = createAsyncThunk(
  "price/data/getPrice",
  async (data) => {
    const response = await apiGetAllPrice(data);
    return response.data;
  }
);

export const getPriceTable = createAsyncThunk(
  "price/data/getPriceTable",
  async (data) => {
    const response = await apiGetAllPriceTable(data);
    return response.data;
  }
);

export const postPrice = createAsyncThunk(
  "price/data/postPrice",
  async (data) => {
    const response = await apipostPrice(data);
    return response.data;
  }
);

export const putPrice = createAsyncThunk(
  "price/data/putPrice",
  async (data) => {
    const response = await apiputPrice(data);
    ////console.log(response);
    return response.data;
  }
);

export const initialTableData2 = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialpriceListDetails = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "priceList/data",
  initialState: {
    loading: false,
    priceList: [],
    tableData: initialTableData,
    priceListTable: [],
    tableDataTable: initialTableData2,
    priceListDetails: [],
    tableDataDetails: initialpriceListDetails,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.priceList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.priceList = action.payload;
        state.tableData = state.tableData || {};
        state.tableData.total = action.payload.count;
      })
      .addCase(getPrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrice.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPriceTable.fulfilled, (state, action) => {
        state.loading = false;
        state.priceListTable = action.payload;
        state.tableDataTable = state.tableDataTable || {};
        state.tableDataTable.total = action.payload.count;
      })
      .addCase(getPriceTable.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPriceTable.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getpriceListDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.priceListDetails = action.payload;
        state.tableDataDetails = state.tableDataDetails || {};
        state.tableDataDetails.total = action.payload.count;
      })
      .addCase(getpriceListDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getpriceListDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postPrice.fulfilled, () => {})
      .addCase(putPrice.fulfilled, () => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
