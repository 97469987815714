import React from "react";
import { Button, Select } from "antd";
import * as XLSX from "xlsx";
import { TbFileTypeXls } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { AiFillFileExcel } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { increaseCounter } from "../store/stateSlice";

const GenerateExcel = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.customer.data.XlsList?.data);
  const sct = useSelector((state) => state.customer.state.counter?.counter);

  const exportToExcel = () => {
    dispatch(increaseCounter({ counter: sct + 1 }));

    const newList = data.map((item) => ({
      Name: `${item.FIRST_NAME} ${item.LAST_NAME}`,
      "Email ID": item.EMAIL_ID,
      Department: item.DEPARTMENT,
      "Branch Name": item.BRANCH_NAME,
      "Mobile No": item.MOBILE_NO,
      Address: item.ADDRESS,
      City: item.CITY,
      Pincode: item.PINCODE,
      District: item.DISTRICT,
      Description: item.DESCRIPTION,
      "Is Working": item.IS_WORKING == 1 ? "Working" : "Not Working",
      "Created Emp Name": item.CREATED_EMP_NAME,

      // "State ID": item.STATE_ID,
      // "Created Emp ID": item.CREATED_EMP_ID,
      // "Department ID": item.DEPARTMENT_ID,
      // "Branch ID": item.BRANCH_ID,
      // "State Name": item.STATE_NAME,
      // "Created Datetime": item.CREATED_DATETIME,
      // "Status": item.STATUS,
      // "Created Modified Date": item.CREATED_MODIFIED_DATE,
    }));

    const worksheet = XLSX.utils.json_to_sheet(newList);

    worksheet["!cols"] = [
      { wch: 30 }, // 1
      { wch: 20 }, // 2
      { wch: 20 }, // 3
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
      { wch: 10 }, // 7
      { wch: 10 }, //8
      { wch: 15 }, // 9
      { wch: 15 }, //10
      { wch: 20 }, // 11
      { wch: 20 }, // 12
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Employee_Target_Report.xlsx");
  };

  return (
    <div>
      {/* <button onClick={exportToExcel}>Download Excel File</button> */}

      <div className="flex">
        <div className="flex items-center">
          <div
            className="bg-[#096CAE] p-[9px] rounded text-xl mr-2 text-white cursor-pointer"
            onClick={exportToExcel}
          >
            <SiMicrosoftexcel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateExcel;
