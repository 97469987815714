import React, { useState, useEffect } from "react";
import { Drawer, Form, DatePicker, Select, Button, Row, Col } from "antd";
import moment from "moment";
import SalaryTableinner from "./SalaryTableinner"; // Import the SalaryTableinner component

const { Option } = Select;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const GenerateSalarySlipDrawer = () => {
  const [form] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const onChange = (date, dateString) => {
    if (dateString) {
      setSelectedYear(date);
      let month = dateString.split("-")[1]; // This will give you "08"
      month = parseInt(month, 10); // Converts "08" to 8

      setSelectedMonth(month);
      setShowTable(true);
      // //console.log("dateString", month);
    } else {
      setShowTable(false);
      // //console.log("dateStrindvdvvvdvdsvg", dateString);
    }
  };
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="year"
              label="Select year and month"
              rules={[{ required: true, message: "Please select a year" }]}
            >
              <DatePicker
                onChange={onChange}
                picker="month"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {showTable && (
        <SalaryTableinner
          year={selectedYear}
          // month={months.indexOf(selectedMonth) + 1}
          month={selectedMonth}
          onClose={() => setShowTable(false)}
        />
      )}
    </>
  );
};

export default GenerateSalarySlipDrawer;
