import React, { useEffect, useRef, useCallback, useState } from "react";
import { Button, Modal, Select, DatePicker, Radio, Space } from "antd";
import { FaFilter, FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import TaskForm from "./components/TaskForm";
import TaskTable from "./components/TaskTable";
import TaskSearch from "./components/TaskSearch";

import {
  toggleNewDialog,
  setSelectedTaskMaster,
  increaseCounter,
} from "./store/stateSlice";

import { injectReducer } from "../../store";
import TaskMasterreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { AiFillFileExcel } from "react-icons/ai";

import { getTask, getEmployee, setTableData } from "./store/dataSlice";

const { Option } = Select;
injectReducer("taskmaster", TaskMasterreducer);

const TaskMaster = () => {
  const dispatch = useDispatch();

  const employee = useSelector(
    (state) => state.taskmaster.data.employeeIdList?.data
  );

  const loading = useSelector((state) => state.taskmaster.data.loading);

  const { RangePicker } = DatePicker;
  const [showFilter, setShowFilter] = useState(false);
  const dialog = useSelector((state) => state.taskmaster.state.newDialog);
  const [filters, setFilters] = useState({
    PRIORITY: "",
    EMP_ID: null,
    ASSIGNED_BY_ID: null,
    COMPLETION_STATUS: "",
    FROM_DATE: null,
    TO_DATE: null,
  });
  const allData = useSelector(
    (state) => state.taskmaster.data.taskMasterList?.data
  );

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const exportToExcel = () => {
    dispatch(increaseCounter({ counter: sct + 1 }));

    if (!loading && allData && allData.length > 0) {
      const transformedData = allData.map((data) => {
        const {
          EMPLOYEE_NAME,
          DEPARTMENT_NAME,
          START_DATETIME,
          END_DATETIME,
          DESCRIPTION,
          ASSIGNED_BY_NAME,
          PRIORITY,
          COMPLETION_DATETIME,
          COMPLETION_STATUS,
        } = data;

        const priorityText = {
          3: "High",
          2: "Medium",
          1: "Low",
        };
        const completionStatus = {
          P: "Pending",
          C: "Completed",
        };

        return {
          Employee_Name: EMPLOYEE_NAME || "",
          Department_Name: DEPARTMENT_NAME || "",
          Start_DateTime: START_DATETIME || "",
          End_Date_Time: END_DATETIME || "",
          Description: DESCRIPTION || "",
          Assigned_By: ASSIGNED_BY_NAME || "",
          Priority: priorityText[PRIORITY] || "",
          Completion_DateTime: COMPLETION_DATETIME || "",
          Completion_Status: completionStatus[COMPLETION_STATUS] || "",
        };
      });
      const ws = XLSX.utils.json_to_sheet(transformedData);

      ws["!cols"] = [
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
      ];

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet2");
      XLSX.writeFile(wb, "taskmaster_data.xlsx");
    } else {
      //console.log("No data available to export");
    }
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.taskmaster.data.tableData
  );

  const fetchData = useCallback(() => {
    const updated = {
      ...filters,
      ASSIGNED_BY_ID:
        filters?.ASSIGNED_BY_ID == null ||
        (Array.isArray(filters?.ASSIGNED_BY_ID) &&
          filters?.ASSIGNED_BY_ID.length === 0)
          ? null
          : filters?.ASSIGNED_BY_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
      pageIndex,
      pageSize,
    };
    dispatch(getTask(updated));
  }, [pageIndex, pageSize]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchData();
    } else {
      isFirstRender.current = false;
    }
  }, [pageIndex, pageSize, fetchData]);

  const sct = useSelector((state) => state.taskmaster.state.counter.counter);

  useEffect(() => {
    const updated = {
      ...filters,
      ASSIGNED_BY_ID:
        filters?.ASSIGNED_BY_ID == null ||
        (Array.isArray(filters?.ASSIGNED_BY_ID) &&
          filters?.ASSIGNED_BY_ID.length === 0)
          ? null
          : filters?.ASSIGNED_BY_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
    };

    if (sct > 0) {
      dispatch(getTask(updated));
    }
  }, [sct]);

  const handleSubmit = (filters) => {
    const updated = {
      ...filters,
      ASSIGNED_BY_ID:
        filters?.ASSIGNED_BY_ID == null ||
        (Array.isArray(filters?.ASSIGNED_BY_ID) &&
          filters?.ASSIGNED_BY_ID.length === 0)
          ? null
          : filters?.ASSIGNED_BY_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
        (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
      pageIndex: 1,
      pageSize,
    };
    dispatch(getTask(updated));
    dispatch(setTableData({ pageIndex: 1, pageSize }));

    // setShowFilter(false);
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
    setFilters({
      PRIORITY: "",
      EMP_ID: null,
      ASSIGNED_BY_ID: null,
      COMPLETION_STATUS: "",
      FROM_DATE: null,
      TO_DATE: null,
    });
    const updated = {
      pageIndex: 1,
      pageSize,
    };
    dispatch(getTask(updated));
  };

  const onDialog = () => {
    dispatch(setSelectedTaskMaster(null));
    dispatch(toggleNewDialog(true));
  };

  useEffect(() => {
    dispatch(getEmployee());
  }, [dispatch]);

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <>
      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Task</div>
          <div className="">
            <div className="flex items-center ">
              <div
                className="bg-[#096CAE] p-[9px] rounded text-xl mr-2 text-white cursor-pointer"
                onClick={exportToExcel}
              >
                <AiFillFileExcel />
              </div>
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
              <TaskSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Task</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                Add Task
              </span>
            }
            open={dialog}
            width={550}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <TaskForm />
          </Modal>
        </div>
        <div className="filter mb-4">
          {showFilter && (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4 mb-4">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      mode="multiple"
                      className="w-full custom-select h-11"
                      placeholder="Select Employees"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {employee.map(
                        ({ ID, FIRST_NAME, MIDDLE_NAME, LAST_NAME }) => {
                          const fullName = [FIRST_NAME, MIDDLE_NAME, LAST_NAME]
                            .filter(Boolean)
                            .join(" ");

                          return fullName ? (
                            <Option key={ID} value={ID}>
                              {fullName}
                            </Option>
                          ) : null;
                        }
                      )}
                    </Select>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Assigned by
                  </label>
                  <div className="">
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select a Person"
                      className="w-full custom-select h-11"
                      onChange={(value) =>
                        handleChange("ASSIGNED_BY_ID", value)
                      }
                    >
                      {employee.map(
                        ({ ID, FIRST_NAME, MIDDLE_NAME, LAST_NAME }) => {
                          const fullName = [FIRST_NAME, MIDDLE_NAME, LAST_NAME]
                            .filter(Boolean)
                            .join(" ");

                          return fullName ? (
                            <Option key={ID} value={ID}>
                              {fullName}
                            </Option>
                          ) : null;
                        }
                      )}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    From Date
                  </label>
                  <div className="">
                    <RangePicker
                      className="w-full  h-11"
                      style={{ padding: "0.5rem" }}
                      onChange={(value, dateString) => {
                        handleChange("FROM_DATE", dateString[0]);
                        handleChange("TO_DATE", dateString[1]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-between mb-4 mt-6">
                <div className="grid grid-cols-3 w-full">
                  <div className="col-span-4 md:col-span-1">
                    <label className="block mb-1 font-semibold text-gray-500">
                      Completed Status
                    </label>
                    <div className="">
                      <Radio.Group
                        onChange={(e) =>
                          handleChange("COMPLETION_STATUS", e.target.value)
                        }
                        className="w-full"
                        value={filters.COMPLETION_STATUS}
                      >
                        <Radio value="">All</Radio>
                        <Radio value="P">Pending</Radio>
                        <Radio value="C">Completed</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <label className="block mb-1 font-semibold text-gray-500">
                      Priority
                    </label>
                    <div className="">
                      <Radio.Group
                        onChange={(e) =>
                          handleChange("PRIORITY", e.target.value)
                        }
                        className="w-full"
                        value={filters.PRIORITY}
                      >
                        <Radio value="">All</Radio>
                        <Radio value="3">High</Radio>
                        <Radio value="2">Medium</Radio>
                        <Radio value="1">Low</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        PRIORITY: "",
                        EMP_ID: null,
                        ASSIGNED_BY_ID: null,
                        COMPLETION_STATUS: "",
                        START_DATETIME: null,
                        END_DATETIME: null,
                      });
                      setShowFilter(false);
                      dispatch(getTask());
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <TaskTable />
      </div>
    </>
  );
};

export default TaskMaster;
