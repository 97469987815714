import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllEmployeeId,
  apiGetAllDepartmentId,
  apiGetAbsentReport,
  apiGetAllBranchId,
} from "../../../services/AbsentReportService";


export const getAbsentreportForXLS = createAsyncThunk(
  "/api/absentReport/getAbsentreportForXLS",
  async (data) => {
    const response = await apiGetAbsentReport(data);
    return response.data;
  }
);


export const getAbsentReport = createAsyncThunk(
  "/api/absentReport/get",
  async (data) => {
    const response = await apiGetAbsentReport(data);
    return response.data;
  }
);

export const getEmployeeId = createAsyncThunk(
  "/api/employee/getEmployeeData",
  async (data) => {
    const response = await apiGetAllEmployeeId(data);
    return response.data;
  }
);

export const getDepartmentId = createAsyncThunk(
  "department/data/getDepartmentId",
  async (data) => {
    const response = await apiGetAllDepartmentId(data);
    return response.data;
  }
);

export const getBranchId = createAsyncThunk(
  "branch/data/getBranchId",
  async (data) => {
    const response = await apiGetAllBranchId(data);
    return response.data;
  }
);

const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "absentReportList/data",
  initialState: {
    loading: false,
    XLloading: false,
    employeeIdList: [],
    departmentIdList: [],
    branchIdList: [],
    absentReportList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateEmployeeList: (state, action) => {
      state.employeeIdList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAbsentReport.fulfilled, (state, action) => {
        state.loading = false;
        state.absentReportList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getAbsentReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAbsentReport.rejected, (state) => {
        state.loading = false;
      })



      .addCase(getAbsentreportForXLS.fulfilled, (state, action) => {
        state.XLloading = false;
        state.XlsList = action.payload;
      })
      .addCase(getAbsentreportForXLS.pending, (state) => {
        state.XLloading = true;
      })
      .addCase(getAbsentreportForXLS.rejected, (state) => {
        state.XLloading = false;
      })




      .addCase(getEmployeeId.fulfilled, (state, action) => {
        state.employeeIdList = action.payload;
      })
      .addCase(getDepartmentId.fulfilled, (state, action) => {
        state.departmentIdList = action.payload;
      })
      .addCase(getBranchId.fulfilled, (state, action) => {
        state.branchIdList = action.payload;
      });
  },
});

export const { updateEmployeeList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
