import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Table, Button, Input, message } from "antd";
import { AiOutlineSave } from "react-icons/ai";
import {
  GetSalaryslip,
  setTravelAllowance,
  GetSalaryslipTable,
  GetSalaryslipforouttertable,
} from "../store/dataSlice";
import { toggleNewDialog, toggleDrower } from "../store/stateSlice";
import { apiGetAllHoliday } from "../../../services/HolidayService";
import { apipostSalaryslip } from "../../../services/SalarySlipService";
import dayjs from "dayjs";
import { Axis } from "echarts";

function getDaysInMonth(year, month) {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
}

const SalaryTableinner = ({ year, month }) => {
  const dispatch = useDispatch();
  const [holidaysCount, setHolidaysCount] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [updatedDataSalaryData, setUpdatedDataSalaryData] = useState([]);
  const date = dayjs().month(month - 1);
  const tableData = useSelector(
    (state) => state.salaryslip?.data?.salaryslipList.data
  );
  const loading = useSelector(
    (state) => state.salaryslip?.data?.loading || false
  );

  useEffect(() => {
    if (Array.isArray(tableData)) {
      setData(tableData);
    } else {
      setData([]);
    }
  }, [tableData]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await apiGetAllHoliday({
          YEAR: dayjs(year).format("YYYY"),
          MONTH: date.format("MM"),
        });
        setHolidaysCount(response.data.count || 0); // Ensure fallback if count is not present
      } catch (error) {
        console.error("Error fetching holidays:", error);
        setHolidaysCount(0);
      }
    };

    fetchHolidays();
    dispatch(
      GetSalaryslip({
        YEAR: dayjs(year).format("YYYY"),
        MONTH: date.format("MM"),
      })
    );
    dispatch(toggleNewDialog(false));
  }, [dispatch, year, month]);

  const calculateAttendance = (record) => {
    let totalPresentDays = 0;
    let lateComings = 0;

    const dayInStandardTime = record["DAYIN_TIME"];
    const dayOutStandardTime = record["DAYOUT_TIME"];
    const intervalTime = record["INTERVAL_TIME"];
    const lateMarkTime = record["LATEMARK_TIME"];
    const monthyTravellingAllowance = record["TRAVELLING_ALLOWANCE"];
    const monthlySalary = record["SALARY"];

    for (let i = 1; i <= 30; i++) {
      const dayInKey = `DAYIN_${i}`;
      const dayOutKey = `DAYOUT_${i}`;
      const dayInTime = record[dayInKey];
      const dayOutTime = record[dayOutKey];

      if (dayInTime && dayOutTime) {
        if (
          (dayInTime > lateMarkTime && dayOutTime < dayOutStandardTime) ||
          dayInTime >= intervalTime ||
          (dayInTime > lateMarkTime && dayOutTime < intervalTime)
        ) {
          totalPresentDays += 0;
          // Absent if day in after 10:15 and day out before 6
        } else if (
          dayInTime > lateMarkTime &&
          dayOutTime >= dayOutStandardTime
        ) {
          totalPresentDays += 0.5; // Half day if day in between 10:15 and 2 and day out after 6
        } else if (
          dayInTime <= dayInStandardTime &&
          dayOutTime >= intervalTime &&
          dayOutTime < dayOutStandardTime
        ) {
          totalPresentDays += 0.5; // Half day if day in before 10:15 and day out between 2 and 6
        } else if (
          dayInTime <= dayInStandardTime &&
          dayOutTime >= dayOutStandardTime
        ) {
          totalPresentDays += 1; // Full day if day in before 10.15 and day out after 6
        } else if (
          dayInTime > dayInStandardTime &&
          dayInTime <= lateMarkTime &&
          (dayOutTime >= dayOutStandardTime || dayOutTime < dayOutStandardTime)
        ) {
          lateComings += 1; // Count late comings if day in between 10:00 and 10:15 and day out after 6
          dayOutTime >= dayOutStandardTime
            ? (totalPresentDays += 1)
            : (totalPresentDays += 0.5); // Initially consider it as a full day
        }
      }
    }

    // totalPresentDays -= Math.floor(lateComings / 3); // 3 late comings count as 1 absent
    totalPresentDays -= Math.floor((lateComings % 3) / 3) * 0.5; // 3 late comings count as half day

    totalPresentDays = Math.max(totalPresentDays, 0);

    const totalDays = getDaysInMonth(record["YEAR"], record["MONTH"]);
    const absentDays = totalDays - totalPresentDays;
    const payableSalary = (
      (record["SALARY"] / totalDays) *
      totalPresentDays
    ).toFixed(2);

    const workingDays = totalDays - holidaysCount; // Total working days in the month
    const perdayTravellingAllowance = (
      record["TRAVELLING_ALLOWANCE"] / workingDays
    ).toFixed(2);

    const travellingAllowance = (
      perdayTravellingAllowance * totalPresentDays
    ).toFixed(2);

    const actualPayableSalary = (
      parseFloat(payableSalary) + parseFloat(travellingAllowance)
    ).toFixed(2);

    return {
      totalPresentDays,
      absentDays,
      payableSalary,
      actualPayableSalary,
      travellingAllowance,
      holidaysCount,
    };
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      const processedData = data.map((record, index) => {
        const {
          totalPresentDays,
          absentDays,
          payableSalary,
          actualPayableSalary,
          travellingAllowance,
          holidaysCount,
        } = calculateAttendance(record);
        return {
          ...record,
          TOTAL_PRESENT_DAYS: totalPresentDays,
          TOTAL_ABSENCES: absentDays,
          PAYBLE_SALARY: payableSalary,
          TRAVELLING_ALLOWANCE: travellingAllowance,
          ACTUAL_PAYABLE_SALARY: actualPayableSalary,
          FULL_NAME: `${record.FIRST_NAME} ${record.MIDDLE_NAME} ${record.LAST_NAME}`,
          TOTAL_HOLIDAYS: holidaysCount,
          index,
        };
      });
      setUpdatedDataSalaryData(processedData);
    }
  }, [data]);

  const updatedSalaryDetails = (index, field, value) => {
    const updatedData = [...updatedDataSalaryData];
    updatedData[index][field] = value;

    if (field === "TRAVELLING_ALLOWANCE" || field === "PAYBLE_SALARY") {
      const record = updatedData[index];
      const payableSalary = parseFloat(record.PAYBLE_SALARY || 0);
      const travellingAllowance = parseFloat(record.TRAVELLING_ALLOWANCE || 0);
      const actualPayableSalary = (payableSalary + travellingAllowance).toFixed(
        2
      );
      updatedData[index].ACTUAL_PAYABLE_SALARY = actualPayableSalary;
    }
    // //console.log("updatedData", updatedData);
    setUpdatedDataSalaryData(updatedData);
  };

  const handleSave = async () => {
    try {
      const detailsData = updatedDataSalaryData.map((record) => ({
        EMP_ID: record.EMP_ID,
        TOTAL_PRESENT_DAYS: record.TOTAL_PRESENT_DAYS,
        TOTAL_ABSENT_DAYS: record.TOTAL_ABSENCES,
        BASE_SALARY: record.SALARY,
        TRAVEL_ALLOWANCE: record.TRAVELLING_ALLOWANCE,
        PAYABLE_SALARY: record.PAYBLE_SALARY,
        ACTUAL_PAYABLE_SALARY: record.ACTUAL_PAYABLE_SALARY,
        REMARK: record.REMARK || "NA",
      }));

      const dataToSend = {
        MONTH: date.format("MM"),
        YEAR: dayjs(year).format("YYYY"),
        detailsData: detailsData,
      };

      const response = await apipostSalaryslip(dataToSend);

      if (response.status >= 200) {
        if (response.data.code === 200) {
          message.success("Salary slips saved successfully!");
          dispatch(GetSalaryslipforouttertable({ pageIndex: 1, pageSize: 10 }));
          dispatch(toggleDrower(false));
        } else if (response.data.code === 304) {
          message.info("Salary slip already generated.");
        } else {
          message.error("Error occurred during form submission.");
        }
      } else {
        message.error("Error occurred during form submission.");
      }
    } catch (error) {
      console.error("Error posting salary slips:", error);
      message.error("Failed to save salary slips. Please try again.");
    }
  };

  const columns = [
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FULL_NAME",
      key: "fullName",
      fixed: "left",
      width: 220,
    },
    {
      title: <span className="text-gray-500">Total Present Days</span>,
      dataIndex: "TOTAL_PRESENT_DAYS",
      key: "totalPresentDays",
      render: (text) => (text !== null ? text : 0),
      width: 160,
    },
    {
      title: <span className="text-gray-500">Total Absent Days</span>,
      dataIndex: "TOTAL_ABSENCES",
      key: "totalAbsences",
      render: (text) => (text !== null ? text : 0),
      width: 160,
    },
    {
      title: "Travelling Allowance",
      dataIndex: "TRAVELLING_ALLOWANCE",
      key: "TRAVELLING_ALLOWANCE",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            updatedSalaryDetails(index, "TRAVELLING_ALLOWANCE", e.target.value)
          }
        />
      ),
    },
    {
      title: "Basic Salary",
      dataIndex: "SALARY",
      align: "center",
      key: "SALARY",
      width: "200",
    },
    {
      title: "Payable Salary",
      dataIndex: "PAYBLE_SALARY",
      key: "PAYBLE_SALARY",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            updatedSalaryDetails(index, "PAYBLE_SALARY", e.target.value)
          }
        />
      ),
    },
    {
      title: "Actual Payable Salary",
      dataIndex: "ACTUAL_PAYABLE_SALARY",
      key: "ACTUAL_PAYABLE_SALARY",
      render: (text, record, index) => (
        <Input
          value={text}
          // onChange={(e) =>
          //   updatedSalaryDetails(index, "ACTUAL_PAYABLE_SALARY", e.target.value)
          // }
        />
      ),
    },
    {
      title: "Remarks",
      dataIndex: "REMARK",
      key: "REMARK",
      render: (text, record, index) => <Input value={text} />,
    },
  ];

  return (
    <div className="salary-table-inner">
      <Spin spinning={loading}>
        <Table
          dataSource={updatedDataSalaryData}
          columns={columns}
          scroll={{
            x: 1500,
          }}
          pagination={{
            current: currentPage,
            pageSizeOptions: [5, 10, 20, 50],
            pageSize: pageSize,
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
          }}
          rowKey={(record) => record.index}
        />
      </Spin>
      <Button
        type="primary"
        icon={<AiOutlineSave />}
        onClick={handleSave}
        style={{ marginTop: 16 }}
      >
        Save
      </Button>
    </div>
  );
};

export default SalaryTableinner;
