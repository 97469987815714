import ApiService from "./ApiService";

export async function apiGetAllRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/get",
    method: "post",
    data,
  });
}

export async function apiPostRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/create",
    method: "post",
    data,
  });
}

export async function apiPutRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/update",
    method: "put",
    data,
  });
}



export async function apiGetAllRoleId(params) {
  return ApiService.fetchData({
    url: "/api/role/get",
    method: "post",
    params,
  });
}


export async function apiGetAllEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/get",
    method: "post",
    data,
  });
}

export async function apiGetAllDepartmentId(params) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    params,
  });
}

export async function apiGetAllBranchId(params) {
  return ApiService.fetchData({
    url: "/api/branch/getBranchData",
    method: "post",
    params,
  });
}