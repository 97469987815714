import React, { useEffect, useState } from "react";
import { Table, Switch, Button, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getSendRole } from "../store/dataSlice";
import { render } from "@testing-library/react";

const RoleAssignTable = ({ handleCancel }) => {
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const initialData = useSelector(
    (state) => state.role.data.roleAssignList?.data
  );
  const [data, setData] = useState([]);

  const roleid = useSelector((state) => state.role.state.selectedRole.ID);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const handleSwitchChange = (key, field, checked) => {
    const updatedData = data?.map((item) => {
      if (item.ID === key) {
        return { ...item, [field]: checked ? 1 : 0 };
      }
      return item;
    });

    setData(updatedData);
  };
  const handleSeqChange = (id, field, value) => {
    setData((prevData) => {
      const updatedRecords = prevData.map((record) => {
        if (record.ID === id) {
          return { ...record, [field]: value };
        }
        return record;
      });
      return updatedRecords;
    });
  };

  const handleSubmit = async () => {
    try {
      const action = await dispatch(
        getSendRole({
          data: data,
          ROLE_ID: roleid,
        })
      );
      if (action.payload.data.code === 200) {
        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }

    handleCancel();
  };

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: <span className="text-gray-500">Form Name</span>,
      dataIndex: "NAME",
    },
    {
      title: <span className="text-gray-500">Link</span>,
      dataIndex: "LINK",
    },
    {
      title: <span className="text-gray-500">Seq No</span>,
      dataIndex: "SEQ_NO",
      render: (text, record) => (
        <Input
          type="number"
          value={record.SEQ_NO}
          onChange={(e) => handleSeqChange(record.ID, "SEQ_NO", e.target.value)}
        />
      ),
    },
    {
      title: <span className="text-gray-500">Show in Menu</span>,
      dataIndex: "SHOW_IN_MENU",
      render: (text, record) => (
        <Switch
          checked={record.SHOW_IN_MENU === 1}
          onChange={(checked) =>
            handleSwitchChange(record.ID, "SHOW_IN_MENU", checked)
          }
        />
      ),
    },
    {
      title: <span className="text-gray-500">Is Allowed</span>,
      dataIndex: "IS_ALLOWED",
      render: (text, record) => (
        <Switch
          checked={record.IS_ALLOWED === 1}
          onChange={(checked) =>
            handleSwitchChange(record.ID, "IS_ALLOWED", checked)
          }
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Table dataSource={data} columns={columns} />
      <div className="flex justify-end">
        <Button onClick={handleCancel} type="primary" className="mr-4">
          Cancel
        </Button>
        <Button onClick={handleSubmit} type="primary">
          Save
        </Button>
      </div>
    </>
  );
};

export default RoleAssignTable;
