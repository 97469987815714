import React, { useState } from 'react';

const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
};

const CustomSteps = ({ initialStep = 0, steps = [], onChange }) => {

    const [current, setCurrent] = useState(initialStep);
    const stepsLength = steps.length;
    const handleChange = (index) => {
        setCurrent(index);
        if (onChange) {
            onChange(index);
        }
    };

    return (
        <div className="relative flex flex-col items-start w-full">
            {steps.map((item, index) => (
                <div
                    key={index}
                    className={`relative flex items-center mb-4 cursor-pointer w-full ${index === current ? 'text-green-500' : 'text-gray-500'}`}
                    onClick={() => handleChange(index)}
                >
                    <div className="flex items-center justify-center w-[30%]">
                        <div className="relative flex-shrink-0 w-full h-6 flex items-center justify-center border-gray border-[1px] text-[#adadad] text-[12px] rounded-lg font-semibold z-40 bg-white">
                            {convertTo12HourFormat(item.Time)}
                        </div>
                    </div>
                    <div className="relative z-0 flex-grow border-gray border-[1px] text-[#adadad] text-[12px] rounded-lg px-2 flex items-center">
                        <div className="flex-grow">
                            {/* Empty div or content if needed */}
                        </div>
                    </div>

                    {index !== stepsLength - 1 ? (
                        <>
                            <div className="absolute top-3 left-[15%] h-full w-px bg-gray-300 z-0"></div>
                            <div className="absolute bottom-4 left-[15%] h-full w-px bg-gray-300 z-0"></div>
                        </>
                    ) : (
                        <div className="absolute bottom-4 left-[15%] h-full w-px bg-gray-300 z-0"></div>

                    )}


                    <div className="w-[70%] relative z-40 flex-grow flex items-center border-gray border-[1px] text-[#adadad] text-[12px] rounded-lg px-2">
                        <div className="text-gray-400">
                            {item.lat ?
                                <div className="flex w-full justify-between items-center">
                                    <p className="font-bold text-green-500">Active</p>
                                    <p>{item.title}</p>
                                </div>

                                :
                                <p className='font-bold  text-[red]'>Inactive</p>
                            }


                            {item.description}  </div>
                    </div>
                </div>
            ))}
        </div>

    );
};

export default CustomSteps;
