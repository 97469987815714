import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Select } from "antd";
import { getExcelDetails } from "./store/dataSlice";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilter } from "react-icons/fa";
import { injectReducer } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog } from "./store/stateSlice";
import EmployeeDetailsreducer from "./store";
import EmployeeDetailsTable from "./components/EmployeeAttendanceReportTable";
import EmployeeAttendanceReportFilter from "./components/EmployeeAttendanceReportFilter";
import * as XLSX from "xlsx";
import moment from "moment";

injectReducer("employeeDetails", EmployeeDetailsreducer);

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.employeeDetails.state.newDialog);
  const excelData = useSelector(
    (state) => state.employeeDetails.data.excelArray?.data
  );
  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  const exportToExcel = () => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    dispatch(getExcelDetails({ MONTH: currentMonth, YEAR: currentYear }));
    setTimeout(() => {
      //console.log("exceldata", excelData);
      if (excelData && excelData.length > 0) {
        const transformedData = excelData.map(
          ({
            ID,
            EMP_ID,
            FIRST_NAME,
            MIDDLE_NAME,
            LAST_NAME,
            MONTH,
            YEAR,
            DAYIN_1,
            DAYOUT_1,
            DAYIN_2,
            DAYOUT_2,
            DAYIN_3,
            DAYOUT_3,
            DAYIN_4,
            DAYOUT_4,
            DAYIN_5,
            DAYOUT_5,
            DAYIN_6,
            DAYOUT_6,
            DAYIN_7,
            DAYOUT_7,
            DAYIN_8,
            DAYOUT_8,
            DAYIN_9,
            DAYOUT_9,
            DAYIN_10,
            DAYOUT_10,
            DAYIN_11,
            DAYOUT_11,
            DAYIN_12,
            DAYOUT_12,
            DAYIN_13,
            DAYOUT_13,
            DAYIN_14,
            DAYOUT_14,
            DAYIN_15,
            DAYOUT_15,
            DAYIN_16,
            DAYOUT_16,
            DAYIN_17,
            DAYOUT_17,
            DAYIN_18,
            DAYOUT_18,
            DAYIN_19,
            DAYOUT_19,
            DAYIN_20,
            DAYOUT_20,
            DAYIN_21,
            DAYOUT_21,
            DAYIN_22,
            DAYOUT_22,
            DAYIN_23,
            DAYOUT_23,
            DAYIN_24,
            DAYOUT_24,
            DAYIN_25,
            DAYOUT_25,
            DAYIN_26,
            DAYOUT_26,
            DAYIN_27,
            DAYOUT_27,
            DAYIN_28,
            DAYOUT_28,
            DAYIN_29,
            DAYOUT_29,
            DAYIN_30,
            DAYOUT_30,
            DAYIN_31,
            DAYOUT_31,
            ...rest
          }) => {
            const data = {
              Name: `${FIRST_NAME} ${MIDDLE_NAME} ${LAST_NAME}`.trim(),
              Month: MONTH,
              Year: YEAR,
            };

            for (let i = 1; i <= 31; i++) {
              const dayIn = eval(`DAYIN_${i}`);
              const dayOut = eval(`DAYOUT_${i}`);
              if (!dayIn || !dayOut) {
                data[`DAY_${i}`] = "---";
              } else {
                const diff = moment(dayOut, "HH:mm:ss").diff(
                  moment(dayIn, "HH:mm:ss")
                );
                const hours = Math.floor(diff / 3600000);
                const minutes = Math.floor((diff % 3600000) / 60000);
                const seconds = Math.floor((diff % 60000) / 1000);
                data[`DAY_${i}`] = `${hours
                  .toString()
                  .padStart(2, "0")} : ${minutes
                  .toString()
                  .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
              }
            }
            return data;
          }
        );

        const ws = XLSX.utils.json_to_sheet(transformedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "employee_attendance_report.xlsx");
      } else {
        //console.log("No data available to export");
      }
    }, 2000);
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="text-xl font-bold !text-[#414141] w-full flex justify-between">
          <h2 className="flex items-center">Employee Details</h2>
          <div className="flex">
            <div
              className="bg-[#096CAE] p-[9px] rounded text-xl mr-8 text-white cursor-pointer"
              onClick={exportToExcel}
            >
              <SiMicrosoftexcel />
            </div>
            <div
              className="bg-[#096CAE] p-[11.5px] rounded text-white cursor-pointer text-lg"
              onClick={handleFilterClick}
            >
              <FaFilter />
            </div>
          </div>
        </div>
        {dialog && <EmployeeAttendanceReportFilter />}
        <EmployeeDetailsTable />
      </div>
    </>
  );
};

export default EmployeeDetails;
