import { mappls } from "mappls-web-maps";
import { useEffect, useRef, useState } from "react";
import {
  getLocation,
  setTableData,
  getAllLocationMapData,
} from "../store/dataSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Divider, Steps } from "antd";
import CustomSteps from "./CustomSteps";
import { useLocation } from "react-router-dom";
import { toggleNewDialog, toggleMap } from "../store/stateSlice";

import "./styles.css";
import { Button, DatePicker, Select, Spin, Table, Space, Flex } from "antd";
import moment from "moment";

const mapplsClassObject = new mappls();

const dmsToDecimal = (dms, isLongitude) => {
  if (!dms) return NaN;

  const regex = /(\d+)[° ](\d+)'(\d+\.\d+)"([NSWE])/;
  const match = dms.match(regex);

  if (!match) {
    console.error(`Invalid DMS format: ${dms}`);
    return NaN;
  }

  const degrees = parseFloat(match[1]);
  const minutes = parseFloat(match[2]);
  const seconds = parseFloat(match[3]);
  const direction = match[4];

  let decimal = degrees + minutes / 60 + seconds / 3600;

  if (
    (isLongitude && (direction === "W" || direction === "E")) ||
    (!isLongitude && (direction === "S" || direction === "N"))
  ) {
    decimal = direction === "W" || direction === "S" ? -decimal : decimal;
  }

  return decimal;
};

const LocationMapComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const employeeNameList = useSelector(
    (state) => state?.location?.data?.employeeIdList?.data
  );

  const [filters, setFilters] = useState({
    EMP_ID: null,
    START_DATE: null,
    END_DATE: null,
  });
  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));

    if (key == "EMP_ID" && value != null) {
      const updatedFilters = {
        // ...filters,
        EMP_ID: [value],
        START_DATE: filters.DATE || moment().format("YYYY-MM-DD"),
        END_DATE: filters.DATE || moment().format("YYYY-MM-DD"),
      };

      dispatch(getAllLocationMapData(updatedFilters));
    }

    if (key == "DATE") {
      const updatedFilters = {
        // ...filters,
        EMP_ID: [filters.EMP_ID],
        START_DATE: value || moment().format("YYYY-MM-DD"),
        END_DATE: value || moment().format("YYYY-MM-DD"),
      };

      if (filters.EMP_ID != null) {
        dispatch(getAllLocationMapData(updatedFilters));
      }
    }
  };
  const { RangePicker } = DatePicker;
  const mapToggle = useSelector((state) => state.location.state.mapToggle);
  const map = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [getData, setData] = useState([]);
  const [getData2, setData2] = useState([]);

  const [selectedPoint, setSelectedPoint] = useState(null);
  const employees = useSelector(
    (state) => state?.location?.data?.mapData?.data
  );
  const employees2 = useSelector(
    (state) => state?.location?.data?.mapData?.data
  );

  useEffect(() => {
    if (employees && employees.length > 0) {
      const employeeMap = {};

      const lastEmployee = employees[employees.length - 1];

      const employeeWithDecimalCoords = {
        ...lastEmployee,
        lat: dmsToDecimal(lastEmployee.LATITUDE, false),
        lng: dmsToDecimal(lastEmployee.LONGITUDE, true),
        TIMES: lastEmployee.TIME,
        TIME: new Date(lastEmployee.TIME),
      };

      if (
        !isNaN(employeeWithDecimalCoords.lat) &&
        !isNaN(employeeWithDecimalCoords.lng)
      ) {
        const empName = employeeWithDecimalCoords.EMP_NAME;
        const empDate = employeeWithDecimalCoords.TIME.toDateString();

        if (!employeeMap[empName]) {
          employeeMap[empName] = {};
        }

        if (employeeMap[empName][empDate]) {
          if (
            employeeWithDecimalCoords.TIME < employeeMap[empName][empDate].TIME
          ) {
            employeeMap[empName][empDate] = employeeWithDecimalCoords;
          }
        } else {
          employeeMap[empName][empDate] = employeeWithDecimalCoords;
        }

        const uniqueEmployee = Object.values(employeeMap).flatMap((dateMap) =>
          Object.values(dateMap)
        );

        setData(uniqueEmployee);
      }
    }
  }, [employees]);

  const PolylineComponent = ({ map, point, employees }) => {
    const polylineRef = useRef(null);

    const pts = employees2
      ?.map((employee) => ({
        ...employee,
        lat: dmsToDecimal(employee.LATITUDE, false),
        lng: dmsToDecimal(employee.LONGITUDE, true),
      }))
      ?.filter((employee) => {
        if (isNaN(employee.lat) || isNaN(employee.lng)) {
          return false;
        }

        return true;
      })
      ?.filter(
        (employee) =>
          employee.EMP_NAME === selectedPoint.EMP_NAME &&
          employee.DATE === selectedPoint.DATE
      );

    useEffect(() => {
      if (polylineRef.current) {
        mapplsClassObject.removeLayer({ map: map, layer: polylineRef.current });
      }
      if (point && pts?.length > 0) {
        polylineRef.current = new mapplsClassObject.Polyline({
          map: map,
          paths: pts,
          strokeColor: "#333",
          strokeOpacity: 1.0,
          dasharray: [2, 2],
          strokeWeight: 2,
          fitbounds: true,
          animate: {
            // path: true,
            speed: 5,
          },
        });
      }

      return () => {
        mapplsClassObject.removeLayer({ map: map, layer: polylineRef.current });
      };
    }, [point, pts]);

    return null;
  };

  useEffect(() => {
    // Retrieve the timer duration from local storage or default to 3000ms
    const storedTime =
      parseInt(localStorage.getItem("timerDuration"), 10) || 2000;

    const timer = setTimeout(() => {
      if (!isMapLoaded) {
        // Set the timer duration to 6000ms (6s) for the next reload
        localStorage.setItem("timerDuration", "6000");
        window.location.reload();
      }
    }, storedTime);

    return () => clearTimeout(timer);
  }, [isMapLoaded]);

  setTimeout(() => {
    if (isMapLoaded) {
      // Set the timer duration to 6000ms (6s) for the next reload
      localStorage.setItem("timerDuration", "2000");
    }
  }, 6000);

  useEffect(() => {
    mapplsClassObject.initialize(
      "8eddb182a778d97d91cc01f3ce00dcf2",
      { map: true },
      () => {
        if (map?.current) {
          map.current.remove();
        }

        map.current = mapplsClassObject.Map({
          id: "map",
          properties: {
            center: [19.7515, 75.7139],
            zoom: 4,
          },
        });
        try {
          map.current?.on("load", () => {
            setIsMapLoaded(true);
          });
        } catch {}
      }
    );
  }, [employees, employees2]);

  const [current, setCurrent] = useState(0);

  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(":").map(Number);
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12; // Convert hour to 12-hour format
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  };
  const [extraMarker, setExtraMarker] = useState(null);
  const onChange = (value) => {
    //console.log('onChange:', value);
    setCurrent(value);
    const stepData = steps[value];
    //console.log(`Latitude: ${stepData.lat}, Longitude: ${stepData.lng}, msg: ${stepData.msg}`);

    // Check if there's already an extra marker
    if (extraMarker) {
      mapplsClassObject.removeLayer({ map: map.current, layer: extraMarker });
      setExtraMarker(null);

      // If the same step is clicked again, remove the marker and exit
      if (
        extraMarker.getPosition().lat === stepData.lat &&
        extraMarker.getPosition().lng === stepData.lng
      ) {
        return;
      }
    }

    const newMarker = mapplsClassObject.Marker({
      map: map.current,
      position: [stepData.lat, stepData.lng],
      // icon_url: "https://apis.mapmyindia.com/map_v3/1.png",
      icon_url: "https://maps.mappls.com/images/to.png",

      clickableIcons: true,
      popupOptions: {
        offset: { bottom: [0, -20] },
      },
      properties: {
        name: <div onclick="function1()">click</div>,
        description: `<div>${stepData.msg}<br>${stepData.lat}, ${stepData.lng}</div>`,
      },
      popupHtml: `<div>${stepData.msg}<br>${convertTo12HourFormat(
        stepData.Time
      )}</div>`,
    });

    setExtraMarker(newMarker);
  };

  const steps = employees2
    ?.reduce(
      (acc, employee) => {
        // Check if the time is already in the set
        if (!acc.seenTimes.has(employee.TIME)) {
          acc.seenTimes.add(employee.TIME);
          acc.filteredEmployees.push({
            description: employee.ERROR_MSG,
            title: ` ${employee.DATE} `, // Format date and time
            key: acc.filteredEmployees.length, // Generate a unique key
            // lon: employee.LONGITUDE,
            // lat: employee.LATITUDE,
            lat: dmsToDecimal(employee.LATITUDE, false),
            lng: dmsToDecimal(employee.LONGITUDE, true),
            msg: employee.ERROR_MSG,
            Time: employee.TIME,
          });
        }
        return acc;
      },
      { seenTimes: new Set(), filteredEmployees: [] }
    )
    .filteredEmployees.reverse();

  useEffect(() => {
    if (steps?.length > 0) {
      setCurrent(steps.length);
    }
  }, [employees2]);

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div className="flex ">
      <div className="w-[30%] border-[dotted] px-3 h-[60vh]">
        <div className="w-full h-full rounded-md shadow-lg shadow-[gray] p-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-8">
              <label className="block mb-1 font-semibold text-gray-500">
                Employee Name
              </label>
              <div>
                <Select
                  showSearch
                  className="w-full custom-select h-11"
                  placeholder="Select Employee"
                  filterOption={filterOption}
                  onChange={(value) => handleChange("EMP_ID", value)}
                >
                  {employeeNameList?.map((type) => (
                    <Select.Option key={type.ID} value={type.ID}>
                      {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="col-span-12 md:col-span-4">
              <label className="block mb-1 font-semibold text-gray-500">
                Date
              </label>
              <div>
                <Flex vertical gap={12}>
                  <Flex gap={8}>
                    <DatePicker
                      onChange={(date) => {
                        const formattedDate = date
                          ? date.format("YYYY-MM-DD")
                          : null;
                        handleChange("DATE", formattedDate);
                      }}
                      className="h-11"
                      placeholder="Date"
                      variant="filled"
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>

          <Divider />

          <div className="steps-container overflow-y-scroll h-[70%] hide-scrollbar w-full">
            {filters?.EMP_ID != null ? (
              <CustomSteps
                initialStep={current} // Adjust this based on your logic
                steps={steps} // Use the steps array we created
                onChange={onChange}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="rounded-md shadow-lg shadow-[gray]"
        id="map"
        style={{ width: "70%", height: "60vh", display: "inline-block" }}
      >
        {isMapLoaded ? (
          <>
            {getData &&
              getData.map((employee) => (
                <div key={employee.EMP_NAME}>
                  <MarkerComponent
                    employee={employee}
                    map={map.current}
                    setSelectedPoint={setSelectedPoint}
                  />
                </div>
              ))}

            {selectedPoint != null && (
              <PolylineComponent map={map.current} point={selectedPoint} />
            )}
          </>
        ) : (
          <div className="w-full flex justify-center h-60 items-center">
            <Spin size="large" />
          </div>
        )}
      </div>
      {/* {JSON.stringify(getData)} */}
    </div>
  );
};

const MarkerComponent = ({ employee, map, setSelectedPoint }) => {
  useEffect(() => {
    const marker = mapplsClassObject.Marker({
      map: map,
      position: [employee.lat, employee.lng],
      icon_url: "https://apis.mapmyindia.com/map_v3/1.png",
      clickableIcons: true,
      popupOptions: {
        offset: { bottom: [0, -20] },
      },
      properties: {
        name: <div onclick="function1()">click</div>,
        description: `<div >${employee.EMP_NAME} <br> ${employee.TIMES}<br> ${employee.DATE}</div>`,
      },
      popupHtml: `<div >${employee.EMP_NAME} <br> ${employee.TIMES}<br> ${employee.DATE}</div>`,
    });
    // marker.addListener('click', function () {
    //     setSelectedPoint(employee);
    //     // //console.log("load");
    // });
    setSelectedPoint(employee);
    return () => {
      mapplsClassObject.removeLayer({ map: map, layer: marker });
    };
  }, [map, employee]);

  return null;
};

export default LocationMapComponent;
