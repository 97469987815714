import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, DatePicker, Select, Spin, Table, Space } from "antd";
import { getLocation, setTableData, getAllLocationMapData } from "../store/dataSlice";
import { useDispatch } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import moment from 'moment';

import {
  getBranchId,
  getDepartmentId,
  getEmployeeId,
} from "../store/dataSlice";
const { RangePicker } = DatePicker;

const LocationFilter = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state?.location?.state?.newDialog);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.location.data.tableData
  );
  const mapToggle = useSelector((state) => state.location.state.mapToggle);


  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const [filters, setFilters] = useState({
    BRANCH_ID: null,
    DEPARTMENT_ID: null,
    EMP_ID: null,
    START_DATE: null,
    END_DATE: null,
  });

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };
  const today = moment().format('YYYY-MM-DD');
  // const today = "2024-07-23";


  //    --------------------- ROLE ID SART ----------------------------

  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  // const EmpId = roleDetails[0]?.EMP_ID;

  const filterData1 = JSON.parse(localStorage.getItem("filterData"));
  let BRANCH_ID;
  let DEPARTMENT_ID;
  if (ROLE_ID == 10) {
    BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
    DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
  }

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it even if it dose nothing

  const fetchData = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      EMP_ID:
        filters?.EMP_ID == null ||
          (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,

      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,

      BRANCH_ID:
        filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
    };
    dispatch(getLocation(updatedFilters));
  }, [pageIndex, pageSize]);

  const fetchData1 = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex: 1,
      pageSize,
    };
    dispatch(getLocation(updatedFilters));
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    // dispatch(getAllLocationMapData({
    //   START_DATE: today,
    //   END_DATE: today,

    // }));
  }, [pageIndex, pageSize]);

  //------------BRANCH MANAGER ------------

  const fetchData10 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      BRANCH_ID: branchIds,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
          ? departmentids
          : filters?.DEPARTMENT_ID,
      EMP_ID:
        filters?.EMP_ID == null ||
          (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
          ? null
          : filters?.EMP_ID,
    };

    dispatch(getLocation(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  const fetchData101 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      pageIndex: 1,
      pageSize,
      BRANCH_ID: branchIds || [],
      DEPARTMENT_ID: departmentids || [],
    };
    dispatch(getLocation(updatedFilters));
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    // dispatch(getAllLocationMapData({
    //   START_DATE: today,
    //   END_DATE: today,
    //   BRANCH_ID: branchIds,
    //   DEPARTMENT_ID: departmentids,

    // }));
  }, [dispatch, pageIndex, pageSize]);

  //------------ASSET  MASTER ------------
  const fetchData9 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? branchIds
          : filters?.BRANCH_ID,
      DEPARTMENT_ID: departmentids,
    };

    dispatch(getLocation(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  const fetchData91 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      pageIndex: 1,
      pageSize,
      BRANCH_ID: branchIds || [],
      DEPARTMENT_ID: departmentids || [],
    };
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(getLocation(updatedFilters));
    // dispatch(getAllLocationMapData({
    //   START_DATE: today,
    //   END_DATE: today,
    //   BRANCH_ID: branchIds,
    //   DEPARTMENT_ID: departmentids,
    // }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    if (ROLE_ID == 10) {
      fetchData10();
    } else if (ROLE_ID == 9) {
      fetchData9();
    } else {
      fetchData();
    }
  }, [pageIndex, pageSize]);

  // useEffect(() => {
  //   if (ROLE_ID == 10) {
  //     fetchData101();
  //   } else if (ROLE_ID == 9) {
  //     fetchData91();
  //   } else {
  //     fetchData1();
  //   }
  // }, [ROLE_ID]);






  const isFirstRender = useRef(true);

  useEffect(() => {


    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    if (dialog === false) {

      if (ROLE_ID == 10) {
        const filterData = JSON.parse(localStorage.getItem("filterData"));
        const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
        const departmentids =
          filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
        setFilters({
          EMP_ID: null,
          START_DATE: null,
          END_DATE: null,
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
        });




        if (mapToggle) {
          dispatch(
            getLocation({
              pageIndex,
              pageSize,
              BRANCH_ID: branchIds,
              DEPARTMENT_ID: departmentids,
            })
          );
        }
        else {
          // dispatch(getAllLocationMapData({
          //   START_DATE: today,
          //   END_DATE: today,
          //   BRANCH_ID: branchIds,
          //   DEPARTMENT_ID: departmentids,
          // }));
        }


      } else if (ROLE_ID == 9) {
        const filterData = JSON.parse(localStorage.getItem("filterData"));
        const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
        const departmentids =
          filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
        setFilters({
          EMP_ID: null,
          START_DATE: null,
          END_DATE: null,
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
        });


        if (mapToggle) {

          dispatch(
            getLocation({
              pageIndex,
              pageSize,
              BRANCH_ID: branchIds,
              DEPARTMENT_ID: departmentids,
            })
          );
        }
        else {

          // dispatch(getAllLocationMapData({
          //   START_DATE: today,
          //   END_DATE: today,
          //   BRANCH_ID: branchIds,
          //   DEPARTMENT_ID: departmentids,
          // }));
        }



      } else {
        setFilters({
          EMP_ID: null,
          START_DATE: null,
          END_DATE: null,
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
        });


        if (mapToggle) {
          dispatch(getLocation({ pageIndex, pageSize }));
        }
        else {
          // dispatch(getAllLocationMapData({
          //   START_DATE: today,
          //   END_DATE: today,
          // }));
        }

      }
    }


  }, [dialog]);

  //    --------------------- ROLE ID END ----------------------------

  //------------- filter ---------------------
  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      handleChange("START_DATE", dateStrings[0]);
      handleChange("END_DATE", dateStrings[1]);
    } else {
      handleChange("START_DATE", null);
      handleChange("END_DATE", null);
    }
  };

  const branch = useSelector(
    (state) => state?.location?.data?.branchIdList?.data
  );
  const department = useSelector(
    (state) => state?.location?.data?.departmentIdList?.data
  );
  const employee = useSelector(
    (state) => state?.location?.data?.employeeIdList?.data
  );

  useEffect(() => {
    if (ROLE_ID == 10) {
      dispatch(getDepartmentId({ DEPARTMENT_ID: DEPARTMENT_ID }));
      dispatch(
        getEmployeeId({ DEPARTMENT_ID: DEPARTMENT_ID, BRANCH_ID: BRANCH_ID })
      );
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      dispatch(getBranchId({ BRANCH_ID: branchIds }));
      dispatch(
        getEmployeeId({ DEPARTMENT_ID: departmentids, BRANCH_ID: branchIds })
      );
    } else {
      dispatch(getBranchId());
      dispatch(getDepartmentId());
      dispatch(getEmployeeId());
    }
  }, [ROLE_ID]);

  const handleSubmit = (filters) => {
    if (ROLE_ID == 10) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        BRANCH_ID: branchIds,
        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
            (Array.isArray(filters?.DEPARTMENT_ID) &&
              filters?.DEPARTMENT_ID.length === 0)
            ? departmentids
            : filters?.DEPARTMENT_ID,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,
      };

      const updatedFilters2 = {
        ...filters,
        START_DATE: filters.START_DATE == null ? today : filters.START_DATE,
        END_DATE: filters.END_DATE == null ? today : filters.END_DATE,
        BRANCH_ID: branchIds,
        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
            (Array.isArray(filters?.DEPARTMENT_ID) &&
              filters?.DEPARTMENT_ID.length === 0)
            ? departmentids
            : filters?.DEPARTMENT_ID,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,
      };


      if (mapToggle) {
        dispatch(setTableData({ pageIndex: 1, pageSize }));

        dispatch(getLocation(updatedFilters));
      }
      else {
        dispatch(getAllLocationMapData(updatedFilters2));
      }





    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        BRANCH_ID:
          filters?.BRANCH_ID == null ||
            (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? branchIds
            : filters?.BRANCH_ID,
        DEPARTMENT_ID: departmentids,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,
      };



      const updatedFilters2 = {
        ...filters,
        START_DATE: filters.START_DATE == null ? today : filters.START_DATE,
        END_DATE: filters.END_DATE == null ? today : filters.END_DATE,
        BRANCH_ID:
          filters?.BRANCH_ID == null ||
            (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? branchIds
            : filters?.BRANCH_ID,
        DEPARTMENT_ID: departmentids,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,
      };


      if (mapToggle) {
        dispatch(setTableData({ pageIndex: 1, pageSize }));

        dispatch(getLocation(updatedFilters));
      }
      else {
        dispatch(getAllLocationMapData(updatedFilters2));
      }




    } else {
      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,

        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
            (Array.isArray(filters?.DEPARTMENT_ID) &&
              filters?.DEPARTMENT_ID.length === 0)
            ? null
            : filters?.DEPARTMENT_ID,

        BRANCH_ID:
          filters?.BRANCH_ID == null ||
            (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? null
            : filters?.BRANCH_ID,
      };

      const updatedFilters2 = {
        ...filters,
        START_DATE: filters.START_DATE == null ? today : filters.START_DATE,
        END_DATE: filters.END_DATE == null ? today : filters.END_DATE,
        EMP_ID:
          filters?.EMP_ID == null ||
            (Array.isArray(filters?.EMP_ID) && filters?.EMP_ID.length === 0)
            ? null
            : filters?.EMP_ID,

        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
            (Array.isArray(filters?.DEPARTMENT_ID) &&
              filters?.DEPARTMENT_ID.length === 0)
            ? null
            : filters?.DEPARTMENT_ID,

        BRANCH_ID:
          filters?.BRANCH_ID == null ||
            (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? null
            : filters?.BRANCH_ID,

      };

      if (mapToggle) {
        dispatch(setTableData({ pageIndex: 1, pageSize }));
        dispatch(getLocation(updatedFilters));
      }
      else {
        dispatch(getAllLocationMapData(updatedFilters2));
      }

    }
  };

  return (
    <>
      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>

      <div className="filter mb-4">
        {/*------------- BRANCH MATSR IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ START  --------- */}

        {dialog &&
          (ROLE_ID == 10 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Department Name
                  </label>
                  <div className="">
                    <Select
                      className="w-full custom-select h-11" // Apply Tailwind CSS classes
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      placeholder="Select Department"
                      onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                    >
                      {department?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.DEPARTMENT}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        BRANCH_ID: null,
                        DESIGNATION_ID: null,
                        EMP_ID: null,
                        START_DATE: null,
                        END_DATE: null,
                      });
                      dispatch(setTableData({ pageIndex: 1, pageSize }));

                      dispatch(toggleNewDialog(false));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*------------- Area Manager IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------  START  --------- */}

        {dialog &&
          (ROLE_ID == 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Branch Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Branch"
                      onChange={(value) => handleChange("BRANCH_ID", value)}
                    >
                      {branch?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        BRANCH_ID: null,
                        DESIGNATION_ID: null,
                        EMP_ID: null,
                        START_DATE: null,
                        END_DATE: null,
                      });
                      dispatch(setTableData({ pageIndex: 1, pageSize }));

                      dispatch(toggleNewDialog(false));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ START  ------- */}
        {dialog &&
          (ROLE_ID != 10 && ROLE_ID != 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Branch Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Branch"
                      onChange={(value) => handleChange("BRANCH_ID", value)}
                    >
                      {branch?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Department Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Department"
                      onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                    >
                      {department?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.DEPARTMENT}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>

                 
              
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        BRANCH_ID: null,
                        DESIGNATION_ID: null,
                        EMP_ID: null,
                        START_DATE: null,
                        END_DATE: null,
                      });
                      dispatch(setTableData({ pageIndex: 1, pageSize }));

                      dispatch(toggleNewDialog(false));
                      // dispatch(getLocation());
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}
      </div>
    </>
  );
};

export default LocationFilter;
