import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetEmployee,
  apiGetSalaryslip,
  apiGetAllHoliday,
  apiGetSalaryslipTable,
  apiGetSalaryslipinner,
  apiGetBranch,
  apiGetDepartment,
} from "../../../services/SalarySlipService";

// Async thunk to fetch salary slip data
export const GetSalaryslip = createAsyncThunk(
  "salaryslip/data/GetSalaryslip",
  async (data) => {
    const response = await apiGetSalaryslip(data);
    return response.data; // Ensure this returns { data, count } structure
  }
);

// Async thunk to fetch employee data
export const getEmployee = createAsyncThunk(
  "employee/data/getEmployee",
  async (data) => {
    const response = await apiGetEmployee(data);
    return response.data;
  }
);

export const getBranch = createAsyncThunk(
  "employee/data/getBranch",
  async (data) => {
    const response = await apiGetBranch(data);
    return response.data;
  }
);

export const getDepartment = createAsyncThunk(
  "employee/data/getDepartment",
  async (data) => {
    const response = await apiGetDepartment(data);
    return response.data;
  }
);

// Async thunk to fetch holiday data
export const GetAllHoliday = createAsyncThunk(
  "employee/data/GetHoliday",
  async (data) => {
    const response = await apiGetAllHoliday(data);
    return response.data;
  }
);

export const GetSalaryslipTable = createAsyncThunk(
  "salaryslip/data/getSalaryslipTable",
  async (data) => {
    const response = await apiGetSalaryslipTable(data);
    return response.data; // Ensure this returns { data, count } structure
  }
);

export const GetSalaryslipforouttertable = createAsyncThunk(
  "salaryslip/data/GetSalaryslipforouttertable",
  async (data) => {
    const response = await apiGetSalaryslipTable(data);
    return response.data; // Ensure this returns { data, count } structure
  }
);

export const getDetailsinner = createAsyncThunk(
  "salaryslip/data/getDetailsinner",
  async (data) => {
    const response = await apiGetSalaryslipinner(data);
    return response.data; // Ensure this returns { data, count } structure
  }
);

export const getDetailsInnerExcel = createAsyncThunk(
  "salaryslip/data/getDetailsInnerExcel",
  async (data) => {
    const response = await apiGetSalaryslipinner(data);
    return response.data;
  }
);

// Initial state
const initialState = {
  loading: false,
  employeeIdList: [],
  branchList: [],
  departmetList: [],

  outerList: [],

  outertableData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  },

  detailInner: [],
  excelList: [],

  detailTable: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  },

  salaryslipList: {
    data: [], // Initialize with empty array
    count: 0, // Initialize count
  },
  holidayList: [],
  tableData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
      order: "",
      key: "",
    },
  },
  filterData: {
    name: "",
    category: ["bags", "cloths", "devices", "shoes", "watches"],
    status: [0, 1, 2],
    productStatus: 0,
  },
  error: null,
};

const dataSlice = createSlice({
  name: "salaryslipList/data",
  initialState,
  reducers: {
    updateEmployeeIdList: (state, action) => {
      state.employeeIdList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setTableDatadetail: (state, action) => {
      state.detailTable = { ...state.detailTable, ...action.payload };
    },

    setTableDataouter: (state, action) => {
      state.outertableData = { ...state.outertableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },

    updateField: (state, action) => {
      //console.log("action", state.details);
      const { index, field, value } = action.payload;
      const updatedDetails = [state.details];
      updatedDetails[index][field] = value;
      state.details = updatedDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetSalaryslipforouttertable.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSalaryslipforouttertable.fulfilled, (state, action) => {
        state.loading = false;
        state.outerList = action.payload || [];
        // //console.log("action.payload", action.payload);
        // //console.log("state.outerList", state.outerList);
        state.outertableData = {
          ...state.outertableData,
          total: action.payload?.count || 0,
        };
      })
      .addCase(GetSalaryslipforouttertable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(GetSalaryslip.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSalaryslip.fulfilled, (state, action) => {
        state.loading = false;
        state.salaryslipList = {
          data: action.payload.data,
          count: action.payload.count,
        };
        state.tableData.total = action.payload.count;
      })
      .addCase(GetSalaryslip.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(GetSalaryslipTable.fulfilled, (state, action) => {
        state.loading = false;
        state.salaryslipList = {
          data: action.payload.data,
          count: action.payload.count,
        };
        state.tableData.total = action.payload.count;
      })
      .addCase(GetSalaryslipTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetAllHoliday.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllHoliday.fulfilled, (state, action) => {
        state.loading = false;
        state.holidayList = action.payload.data;
      })
      .addCase(getEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeIdList = action.payload;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.branchList = action.payload;
      })
      .addCase(getBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departmetList = action.payload;
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDetailsinner.fulfilled, (state, action) => {
        state.loading = false;
        state.detailInner = action.payload;
        state.detailTable = {
          ...state.detailTable,
          total: action.payload?.count || 0,
        };
      })
      .addCase(getDetailsinner.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDetailsinner.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDetailsInnerExcel.fulfilled, (state, action) => {
        state.loading = false;
        state.excelList = action.payload;
      });
  },
});

export const {
  updateEmployeeIdList,
  setTableDatadetail,
  setTableDataouter,
  setTableData,
  setFilterData,
  setTravelAllowance,
  updateField,
} = dataSlice.actions;

export const selectHolidayList = (state) =>
  state.salaryslipList.data.holidayList;

export default dataSlice.reducer;
