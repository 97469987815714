import React, { useEffect, useState, useCallback } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  notification,
  Table,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postPrice,
  putPrice,
  getPrice,
  getPriceTable,
} from "../store/dataSlice";
import PriceSearch from "./PriceSearch";

import { DatePicker, Space } from "antd";
import moment from "moment";

// import { apigetGstType } from "../../../services/DistributorService";

const { TextArea } = Input;

const PriceForm = ({ handleRefresh }) => {
  const [showSaveNext, setShowSaveNext] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedPrice = useSelector((state) => state.price.state.selectedPrice);

  const validationSchema = Yup.object({
    ITEM_NAME: Yup.string().required("Required"),
    RATE: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedPrice) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedPrice]);

  const initialValues = selectedPrice || {
    ITEM_NAME: "",
    RATE: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
    dispatch(getPrice());
  };

  const handleSubmit = async () => {
    try {
      const priceListDetails = dataSource.map((item) => ({
        IS_CHANGED: item.IS_CHANGED,
        RATE: item.RATE,
        ITEM_ID: item.ITEM_ID,
      }));
      const payload = { priceListDetails };
      const action = await dispatch(postPrice(payload));

      // const action = dispatch(postPrice(dataSource));

      // ////console.log("action", action);

      if (action.payload.code < 300) {
        // ////console.log("Price submitted successfully!");
        dispatch(getPrice());
        dispatch(getPriceTable());
        // ////console.log("Price submitted successfully!");
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Price Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const data = useSelector((state) => state?.price?.data?.priceList?.data);

  // const fetchData = useCallback(() => {
  //     dispatch(getPrice());
  // });

  // useEffect(() => {
  //     fetchData();
  // }, []);

  const newDialogValue = useSelector((state) => state.price.state.newDialog);

  useEffect(() => {
    setDataSource(data);
  }, [newDialogValue]);

  const [dataSource, setDataSource] = useState([]);

  // const result1 = data.find(i => i.ITEM_ID == 1);
  // ////console.log("find in result data = ", result1)

  const handleRateChange = (e, ITEM_ID2) => {
    const newDataSource = dataSource.map((item) => {
      const result1 = data.find((i) => i.ITEM_ID == ITEM_ID2);

      if (item.ITEM_ID === ITEM_ID2) {
        const newRate = parseFloat(e.target.value);
        if (result1.RATE !== newRate) {
          return { ...item, RATE: newRate, IS_CHANGED: 1 };
        } else {
          return { ...item, RATE: newRate, IS_CHANGED: 0 };
        }
      } else {
        return { ...item, IS_CHANGED: 0 };
      }

      return item;
    });
    setDataSource(newDataSource);
  };

  // ////console.log("dataSource", dataSource);

  const columns = [
    {
      title: <span className="text-gray-500">Item Name</span>,
      dataIndex: "ITEM_NAME",
    },

    {
      title: <span className="text-gray-500">Rate</span>,
      dataIndex: "action",

      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              // onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <Input
                name="NAME"
                placeholder="Item Name"
                value={record.RATE}
                type="number"
                min="0"
                onChange={(e) => handleRateChange(e, record.ITEM_ID)}
                className="!rounded"
                style={{ padding: "8px" }}
              />
            </span>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="mt-4">
        {contextHolder}

        <Row gutter={16}>
          <Col span={24}>
            <PriceSearch />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <br></br>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered
              scroll={{
                y: 350,
              }}
              pagination={false}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="flex justify-end mt-5 ">
              <Button
                onClick={handleCancel}
                className="mr-4 mr-4 text-blue-500 border-blue-500"
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} type="primary" className="mr-4">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PriceForm;
