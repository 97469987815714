import React, { useEffect, useState, useCallback } from "react";
import { Button, Select, DatePicker, Space, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { getEmployeeDetails, setTableData } from "../store/dataSlice";

const { Option } = Select;
const { MonthPicker } = DatePicker;

const EmployeeAttendanceReportFilter = () => {
  const dispatch = useDispatch();

  const { pageIndex, pageSize, total, MONTH, YEAR } = useSelector(
    (state) => state.employeeDetails.data.tableData
  );

  const tableData = useSelector(
    (state) => state.employeeDetails.data.tableData
  );
  const dialog = useSelector(
    (state) => state?.employeeDetails?.state?.newDialog
  );

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleMonthChange = (date, dateString) => {
    const month = date.month() + 1;
    const year = date.year();
    dispatch(setTableData({ ...tableData, MONTH: month, YEAR: year }));
  };

  const handleSubmit = () => {
    dispatch(toggleNewDialog(false));
    dispatch(getEmployeeDetails(tableData));
  };

  const fetchData = useCallback(() => {
    dispatch(getEmployeeDetails(tableData));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    if (!dialog) {
      fetchData();
    }
  }, [pageIndex, pageSize]);

  const handleClear = () => {
    dispatch(setTableData({ ...tableData, MONTH: null, YEAR: null }));
    dispatch(toggleNewDialog(false));
    dispatch(getEmployeeDetails({ pageIndex: 1, pageSize }));
  };

  return (
    <div className="p-4 bg-white rounded border border-dashed mt-4">
      <div className="grid grid-cols-4 gap-4 mb-4">
        {/* <div className="col-span-4 md:col-span-1">
          <label className="block mb-1 font-semibold text-gray-500">
            Employee
          </label>
          <div className="">
            <Select
              showSearch
              filterOption={filterOption}
              mode="multiple"
              className="w-full h-11 "
              placeholder="Select Employees"
              onChange={(value) => handleChange("EMP_ID", value)}
            >
              {employee.map(({ ID, FIRST_NAME, MIDDLE_NAME, LAST_NAME }) => {
                const fullName = [FIRST_NAME, MIDDLE_NAME, LAST_NAME]
                  .filter(Boolean)
                  .join(" ");

                return fullName ? (
                  <Option key={ID} value={ID}>
                    {fullName}
                  </Option>
                ) : null;
              })}
            </Select>
          </div>
        </div> */}

        <div className="col-span-4 md:col-span-1">
          <label className="block mb-1 font-semibold text-gray-500">
            Month & Date
          </label>
          <div>
            <MonthPicker
              className="w-full  h-11"
              style={{ padding: "0.5rem" }}
              onChange={handleMonthChange}
              placeholder="Select month"
            />
          </div>
        </div>

        <div className="flex items-end justify-end mb-4 mt-6">
          <div className="flex">
            <Button
              type="default"
              className="mr-4 py-4 px-6 border border-blue-500"
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              type="primary"
              className="py-4 px-6"
              onClick={() => {
                handleSubmit();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAttendanceReportFilter;
