import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "absentReportList/state",
  initialState: {
    deleteConfirmation: false,
    selectedAbsentReport: "",
    newDialog: false,
    tableData: {
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    },
    data: {
      absentReportList: { data: [] },
      loading: false,
    },
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedAbsentReport: (state, action) => {
      state.selectedAbsentReport = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setLoading: (state, action) => {
      state.data.loading = action.payload;
    },
    setAbsentReportData: (state, action) => {
      state.data.absentReportList = action.payload;
    },
    setTotal: (state, action) => {
      state.tableData.total = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedAbsentReport,
  toggleNewDialog,
  setTableData,
  setLoading,
  setAbsentReportData,
  setTotal,
} = stateSlice.actions;

export default stateSlice.reducer;
