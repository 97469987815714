import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Table, Modal } from "antd";
import { FaRegEye } from "react-icons/fa";
import {
  GetSalaryslipforouttertable,
  setTableDataouter,
  getDetailsinner,
  setTableDatadetail,
} from "../store/dataSlice";
import SalaryslipFilter from "./SalaryslipFilter";
import { Link, useNavigate } from "react-router-dom";
import { setSelectedSalarySlipinner } from "../store/stateSlice";

const SalaryslipTable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  const loading = useSelector((state) => state.salaryslip.data.loading);

  // const [loading, setLoading] = useState(true);

  const data = useSelector((state) => state.salaryslip?.data?.outerList?.data);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.salaryslip.data.outertableData
  );
  // const fetchData = useCallback(() => {
  //   setLoading(true);
  //   dispatch(GetSalaryslipforouttertable
  //     ({ pageIndex, pageSize }))
  //     .finally(() => setLoading(false));
  // }, [dispatch, pageIndex, pageSize]);

  // useEffect(() => {
  //   fetchData();
  // }, [pageIndex, pageSize]);



  // const fetchData3 = useCallback(() => {
  //   setLoading(true);
  //   dispatch(GetSalaryslipforouttertable
  //     ())
  //     .finally(() => setLoading(false));
  // }, []);

  // useEffect(() => {
  //   fetchData3();
  // }, []);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableDataouter({ pageIndex: current, pageSize }));
  };

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];



  const onEdit = async (record) => {
    await dispatch(setSelectedSalarySlipinner(record));
    dispatch(setTableDatadetail({ pageIndex: 1, pageSize: 5 }));

    await dispatch(getDetailsinner({
      SALARY_SLIP_ID: [record.ID],
      pageIndex: 1,
      pageSize: 5
    }));
    await navigate('/salaryslip/detail');
  };


  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 200,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEye />
            </span>


          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Month</span>,
      dataIndex: "MONTH",
      render: (monthId) => monthNames[monthId - 1],
    },

    {
      title: <span className="text-gray-500">Year</span>,
      dataIndex: "YEAR",
    },

  ];




  return (
    <div>
      <SalaryslipFilter />
      <div style={{ marginBottom: "16px" }}>
        {loading ? (
          <div className="w-full flex justify-center h-60 items-center">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 1300 }}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onChange: handleTableChange,
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        )}
      </div>

    </div>
  );
};

export default SalaryslipTable;