import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'transactionList/state',
    initialState: {
        deleteConfirmation: false,
        selectedTransactionGroupId: '',
        newDialog: false,
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedTransaction: (state, action) => {
            state.selectedTransaction = action.payload
        },
        setSelectedTransactionGroupId: (state, action) => {
            state.selectedTransactionGroupId = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedTransaction, toggleNewDialog, setSelectedTransactionGroupId } =
    stateSlice.actions

export default stateSlice.reducer


