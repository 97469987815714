import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllOrganisation,
  apipostOrganisation,
  apiputOrganisation,
  apigetStateId,
  apiGetImages,
} from "../../../services/OrganisationService";

export const getOrganisation = createAsyncThunk(
  "organisation/data/getOrganisation",
  async (data) => {
    const response = await apiGetAllOrganisation(data);
    return response.data;
  }
);

export const getImages = createAsyncThunk(
  "organisation/data/getImages",
  async (data) => {
    const response = await apiGetImages(data);
    return response.data;
  }
);

export const postOrganisation = createAsyncThunk(
  "organisation/data/postOrganisation",
  async (data) => {
    const response = await apipostOrganisation(data);
    return response.data;
  }
);

export const putOrganisation = createAsyncThunk(
  "organisation/data/putOrganisation",
  async (data) => {
    const response = await apiputOrganisation(data);
    ////console.log(response);
    return response.data;
  }
);

export const getStateId = createAsyncThunk(
  "organisation/data/getStateId",
  async () => {
    const response = await apigetStateId();
    // ////console.log(response);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "organisationList/data",
  initialState: {
    loading: false,
    organisationList: [],
    stateList: [],
    imageList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.organisationList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisation.fulfilled, (state, action) => {
        state.loading = false;
        state.organisationList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getOrganisation.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrganisation.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getStateId.fulfilled, (state, action) => {
        state.stateList = action.payload;
      })
      .addCase(getImages.fulfilled, (state, action) => {
        // Adjusted to getImages
        state.imageList = action.payload;
      })
      .addCase(postOrganisation.fulfilled, (state, action) => {})
      .addCase(putOrganisation.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
