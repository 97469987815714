import React, { useEffect, useRef, useCallback, useState } from "react";
import { Select, DatePicker, Button, Space, Radio } from "antd";

import { useSelector, useDispatch } from "react-redux";
import {
  getAllcustIntrest,
  getEmployee,
  setTableData,
  getCustomer,
  // getAllcustIntrestXls,
  getService,
} from "../store/dataSlice";
import { toggleNewDialog, setFilters } from "../store/stateSlice";

const { RangePicker } = DatePicker;

const ProductinterestFilter = () => {
  const dispatch = useDispatch();
  //    --------------------- ROLE ID SART ----------------------------

  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails?.[0]?.EMPLOYEE_ID;

  const filterData1 = JSON.parse(localStorage.getItem("filterData"));
  let BRANCH_ID;
  let DEPARTMENT_ID;
  if (ROLE_ID == 10) {
    BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
    DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
  }

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it even if it dose nothing
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.Productinterest.data.tableData
  );
  const dialog = useSelector(
    (state) => state?.Productinterest?.state?.newDialog
  );

  const fetchData = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,

      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,

      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
    };
    const xls = {
      ...filters,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,

      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,

      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
    };

    dispatch(getAllcustIntrest(updatedFilters));
    // dispatch(getAllcustIntrestXls(xls));
  }, [dispatch, pageIndex, pageSize]);

  //-------------TEAM LEADER------------
  const fetchData4 = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      REPORTING_HEAD_ID: [EmpId],
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,
      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };
    const xls = {
      ...filters,
      REPORTING_HEAD_ID: [EmpId],
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,
      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };

    dispatch(getAllcustIntrest(updatedFilters));
    // dispatch(getAllcustIntrestXls(xls));
  }, [dispatch, pageIndex, pageSize]);

  //------------BRANCH MANAGER ------------

  const fetchData10 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      BRANCH_ID: branchIds,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? departmentids
          : filters?.DEPARTMENT_ID,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,
      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };

    const xls = {
      ...filters,
      BRANCH_ID: branchIds,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? departmentids
          : filters?.DEPARTMENT_ID,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,

      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };
    dispatch(getAllcustIntrest(updatedFilters));
    // dispatch(getAllcustIntrestXls(xls));
  }, [dispatch, pageIndex, pageSize]);

  //------------ASSET  MASTER ------------
  const fetchData9 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      DEPARTMENT_ID: departmentids,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? branchIds
          : filters?.BRANCH_ID,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,
      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };

    const xls = {
      ...filters,
      DEPARTMENT_ID: departmentids,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? branchIds
          : filters?.BRANCH_ID,
      EMPLOYEE_ID:
        filters?.EMPLOYEE_ID == null ||
        (Array.isArray(filters?.EMPLOYEE_ID) &&
          filters?.EMPLOYEE_ID.length === 0)
          ? null
          : filters?.EMPLOYEE_ID,
      CUSTOMER_ID:
        filters?.CUSTOMER_ID == null ||
        (Array.isArray(filters?.CUSTOMER_ID) &&
          filters?.CUSTOMER_ID.length === 0)
          ? null
          : filters?.CUSTOMER_ID,
    };
    dispatch(getAllcustIntrest(updatedFilters));
    // dispatch(getAllcustIntrestXls(xls));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    if (ROLE_ID == 10) {
      fetchData10();
    } else if (ROLE_ID == 9) {
      fetchData9();
    } else {
      fetchData();
    }
  }, [pageIndex, pageSize]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    if (dialog === false) {
      if (ROLE_ID == 10) {
        dispatch(
          setFilters({
            BRANCH_ID: null,
            DEPARTMENT_ID: null,
            CUSTOMER_ID: null,
            EMPLOYEE_ID: null,
            SERVICE_ID: null,
            IS_CONVERTED: null,
            FROM_DATE: null,
            TO_DATE: null,
          })
        );
        dispatch(
          getAllcustIntrest({
            pageIndex,
            pageSize,
            BRANCH_ID: BRANCH_ID,
            DEPARTMENT_ID: DEPARTMENT_ID,
          })
        );
      } else if (ROLE_ID == 9) {
        const filterData = JSON.parse(localStorage.getItem("filterData"));
        const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
        const departmentids =
          filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
        dispatch(
          setFilters({
            BRANCH_ID: null,
            DEPARTMENT_ID: null,
            CUSTOMER_ID: null,
            EMPLOYEE_ID: null,
            SERVICE_ID: null,
            IS_CONVERTED: null,
            FROM_DATE: null,
            TO_DATE: null,
          })
        );
        dispatch(
          getAllcustIntrest({
            pageIndex,
            pageSize,
            BRANCH_ID: branchIds,
            DEPARTMENT_ID: departmentids,
          })
        );
      } else {
        dispatch(
          setFilters({
            BRANCH_ID: null,
            DEPARTMENT_ID: null,
            CUSTOMER_ID: null,
            EMPLOYEE_ID: null,
            SERVICE_ID: null,
            IS_CONVERTED: null,
            FROM_DATE: null,
            TO_DATE: null,
          })
        );
        // dispatch(getAllcustIntrest({ pageIndex: 1, pageSize }));
        dispatch(getAllcustIntrest({ pageIndex, pageSize }));
      }
    }
  }, [dialog]);

  //    --------------------- ROLE ID END ----------------------------

  //------------- filter ---------------------

  const filters = useSelector(
    (state) => state?.Productinterest?.state?.filters
  );

  // const [filters, setFilters] = useState({
  //   BRANCH_ID: null,
  //   DEPARTMENT_ID: null,
  //   CUSTOMER_ID: null,
  //   EMPLOYEE_ID: null,
  //   SERVICE_ID: null,
  //   IS_CONVERTED: null,
  //   FROM_DATE: null,
  //   TO_DATE: null,
  // });

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleChange = (key, value) => {
    dispatch(setFilters({ ...filters, [key]: value }));
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      handleChange("FROM_DATE", dateStrings[0]);
      handleChange("TO_DATE", dateStrings[1]);
    } else {
      handleChange("FROM_DATE", null);
      handleChange("TO_DATE", null);
    }
  };

  const customer = useSelector(
    (state) => state?.Productinterest?.data?.customerList?.data
  );

  const employee = useSelector(
    (state) => state?.Productinterest?.data?.employeeList?.data
  );

  const productionInterests = useSelector(
    (state) => state.Productinterest.data.serviceList?.data
  );

  console.log("productionInterests", productionInterests);
  useEffect(() => {
    if (ROLE_ID == 10) {
      // dispatch(getDepartment({ DEPARTMENT_ID: DEPARTMENT_ID }));
      dispatch(getService());
      dispatch(
        getEmployee({ DEPARTMENT_ID: DEPARTMENT_ID, BRANCH_ID: BRANCH_ID })
      );

      dispatch(
        getCustomer({ DEPARTMENT_ID: DEPARTMENT_ID, BRANCH_ID: BRANCH_ID })
      );
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      dispatch(getService());
      // dispatch(getBranch({ BRANCH_ID: branchIds }));
      dispatch(
        getEmployee({ DEPARTMENT_ID: departmentids, BRANCH_ID: branchIds })
      );
      dispatch(
        getCustomer({ DEPARTMENT_ID: departmentids, BRANCH_ID: branchIds })
      );
    } else {
      // dispatch(getBranch());
      // dispatch(getDepartment());
      dispatch(getService());
      dispatch(getEmployee());
      dispatch(getCustomer());
    }
  }, [ROLE_ID]);

  const handleSubmit = (filters) => {
    if (ROLE_ID == 10) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        BRANCH_ID: branchIds,
        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? departmentids
            : filters?.DEPARTMENT_ID,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,

        SERVICE_ID:
          filters?.SERVICE_ID == null ||
          (Array.isArray(filters?.SERVICE_ID) &&
            filters?.SERVICE_ID.length === 0)
            ? null
            : filters?.SERVICE_ID,

        IS_CONVERTED: filters?.IS_CONVERTED,
      };

      const xls = {
        ...filters,
        BRANCH_ID: branchIds,
        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? departmentids
            : filters?.DEPARTMENT_ID,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));
      dispatch(getAllcustIntrest(updatedFilters));
      // dispatch(getAllcustIntrestXls(xls));
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        DEPARTMENT_ID: departmentids,
        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? branchIds
            : filters?.BRANCH_ID,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,
        SERVICE_ID:
          filters?.SERVICE_ID == null ||
          (Array.isArray(filters?.SERVICE_ID) &&
            filters?.SERVICE_ID.length === 0)
            ? null
            : filters?.SERVICE_ID,

        IS_CONVERTED: filters?.IS_CONVERTED,
      };
      const xls = {
        ...filters,
        DEPARTMENT_ID: departmentids,
        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? branchIds
            : filters?.BRANCH_ID,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));

      dispatch(getAllcustIntrest(updatedFilters));
      // dispatch(getAllcustIntrestXls(xls));
    } else {
      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,

        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? null
            : filters?.DEPARTMENT_ID,

        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? null
            : filters?.BRANCH_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,

        SERVICE_ID:
          filters?.SERVICE_ID == null ||
          (Array.isArray(filters?.SERVICE_ID) &&
            filters?.SERVICE_ID.length === 0)
            ? null
            : filters?.SERVICE_ID,

        IS_CONVERTED: filters?.IS_CONVERTED,
      };
      const xls = {
        ...filters,
        EMPLOYEE_ID:
          filters?.EMPLOYEE_ID == null ||
          (Array.isArray(filters?.EMPLOYEE_ID) &&
            filters?.EMPLOYEE_ID.length === 0)
            ? null
            : filters?.EMPLOYEE_ID,

        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? null
            : filters?.DEPARTMENT_ID,

        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? null
            : filters?.BRANCH_ID,
        CUSTOMER_ID:
          filters?.CUSTOMER_ID == null ||
          (Array.isArray(filters?.CUSTOMER_ID) &&
            filters?.CUSTOMER_ID.length === 0)
            ? null
            : filters?.CUSTOMER_ID,
        SERVICE_ID:
          filters?.SERVICE_ID == null ||
          (Array.isArray(filters?.SERVICE_ID) &&
            filters?.SERVICE_ID.length === 0)
            ? null
            : filters?.SERVICE_ID,

        IS_CONVERTED: filters?.IS_CONVERTED,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));
      dispatch(getAllcustIntrest(updatedFilters));
      // dispatch(getAllcustIntrestXls(xls));
    }
  };

  return (
    <>
      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>
      <div className="filter mb-4">
        {/*------------- IF ROLE_ID==4 ,TOGGLE BOX WITHE 2 BOXES ------------ START  ------- */}

        {/*------------- IF ROLE_ID==4 ,TOGGLE BOX WITHE 2 BOXES ------------ END  --------- */}

        {/*------------- BRANCH MATSR IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ START  --------- */}

        {dialog &&
          (ROLE_ID == 10 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Customer Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Customer"
                      onChange={(value) => handleChange("CUSTOMER_ID", value)}
                    >
                      {customer?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMPLOYEE_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Production Interest
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Production Interest"
                      onChange={(value) => handleChange("SERVICE_ID", value)}
                    >
                      {productionInterests?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Status
                  </label>
                  <div className="">
                    <Radio.Group
                      onChange={(e) =>
                        handleChange("IS_CONVERTED", e.target.value)
                      }
                      value={filters.IS_CONVERTED}
                    >
                      <Radio value="P">Pending</Radio>
                      <Radio value="A">Closed</Radio>
                      <Radio value="R">Rejected</Radio>
                      <Radio value="C">Completed</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      dispatch(
                        setFilters({
                          BRANCH_ID: null,
                          DEPARTMENT_ID: null,
                          CUSTOMER_ID: null,
                          EMPLOYEE_ID: null,
                          SERVICE_ID: null,
                          IS_CONVERTED: null,
                          FROM_DATE: null,
                          TO_DATE: null,
                        })
                      );
                      dispatch(toggleNewDialog(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*------------- Area Manager IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------  START  --------- */}

        {dialog &&
          (ROLE_ID == 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Customer Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Customer"
                      onChange={(value) => handleChange("CUSTOMER_ID", value)}
                    >
                      {customer?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMPLOYEE_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Production Interest
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Production Interest"
                      onChange={(value) => handleChange("SERVICE_ID", value)}
                    >
                      {productionInterests?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Status
                  </label>
                  <div className="">
                    <Radio.Group
                      onChange={(e) =>
                        handleChange("IS_CONVERTED", e.target.value)
                      }
                      value={filters?.IS_CONVERTED}
                    >
                      <Radio value="P">Pending</Radio>
                      <Radio value="A">Closed</Radio>
                      <Radio value="R">Rejected</Radio>
                      <Radio value="C">Completed</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      dispatch(
                        setFilters({
                          BRANCH_ID: null,
                          DEPARTMENT_ID: null,
                          CUSTOMER_ID: null,
                          EMPLOYEE_ID: null,
                          SERVICE_ID: null,
                          IS_CONVERTED: null,
                          FROM_DATE: null,
                          TO_DATE: null,
                        })
                      );
                      dispatch(toggleNewDialog(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ START  ------- */}
        {dialog &&
          (ROLE_ID != 10 && ROLE_ID != 4 && ROLE_ID != 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Customer Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Customer"
                      onChange={(value) => handleChange("CUSTOMER_ID", value)}
                    >
                      {customer?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMPLOYEE_ID", value)}
                    >
                      {employee?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1 mb-6">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Pick Date
                  </label>
                  <div className="">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        className="w-full  h-11"
                        onChange={handleDateChange}
                      />
                    </Space>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Production Interest
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Production Interest"
                      onChange={(value) => handleChange("SERVICE_ID", value)}
                    >
                      {productionInterests?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-2">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Status
                  </label>
                  <div className="">
                    <Radio.Group
                      onChange={(e) =>
                        handleChange("IS_CONVERTED", e.target.value)
                      }
                      value={filters?.IS_CONVERTED}
                    >
                      <Radio value="P">Pending</Radio>
                      <Radio value="A">Closed</Radio>
                      <Radio value="R">Rejected</Radio>
                      <Radio value="C">Completed</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      dispatch(
                        setFilters({
                          BRANCH_ID: null,
                          DEPARTMENT_ID: null,
                          CUSTOMER_ID: null,
                          EMPLOYEE_ID: null,
                          SERVICE_ID: null,
                          IS_CONVERTED: null,
                          FROM_DATE: null,
                          TO_DATE: null,
                        })
                      );
                      dispatch(toggleNewDialog(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ END  ------- */}
      </div>
    </>
  );
};

export default ProductinterestFilter;
