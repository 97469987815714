import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { Form, Input, Select, Row, Col, Button, Switch, notification, DatePicker, Spin } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { postTransaction, putTransaction, getAccountGroup, getAllTransaction, getAccountFrom, getAccountTo } from "../store/dataSlice";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

const TransactionForm = ({ handleRefresh }) => {
    const [api, contextHolder] = notification.useNotification();
    const [edit, setEdit] = useState(false);
    const [localTransaction, setLocalTransaction] = useState(null);
    const loading2 = useSelector((state) => state.transaction.data.loading2);

    const dispatch = useDispatch();
    let selectedTransaction = useSelector((state) => state.transaction.state.selectedTransaction);
    const groupList1 = useSelector((state) => state?.transaction?.data?.accountGroup?.data);
    const groupList2 = useSelector((state) => state?.transaction?.data?.accountGroup?.data);
    const accfrom = useSelector((state) => state?.transaction?.data?.allAccountFrom?.data);
    const accto = useSelector((state) => state?.transaction?.data?.allAccountTo?.data);
    const TransactionDetailsID = useSelector((state) => state?.transaction?.data?.transactionDetailsID?.data);

    const validationSchema = Yup.object({
        DATE: Yup.string().required("Required"),
        AMOUNT: Yup.string().required("Required"),
        PARENT_GROUP_ID1: Yup.string().required("Required"),
        PARENT_GROUP_ID2: Yup.string().required("Required"),
        FROM_ACCOUNT: Yup.string().required("Required"),
        TO_ACCOUNT: Yup.string().required("Required"),
        NARRATION: Yup.string().required("Required"),
        STATUS: Yup.boolean().required("Required"),
    });

    useEffect(() => {
        if (selectedTransaction && TransactionDetailsID) {
            const updatedTransaction = {
                ...selectedTransaction,
                PARENT_GROUP_ID1: TransactionDetailsID?.find(item => item.EFFECT === "D")?.GROUP_ID,
                PARENT_GROUP_ID2: TransactionDetailsID?.find(item => item.EFFECT === "C")?.GROUP_ID,
                FROM_ACCOUNT: TransactionDetailsID?.find(item => item.EFFECT === "D")?.ACCOUNT_ID,
                TO_ACCOUNT: TransactionDetailsID?.find(item => item.EFFECT === "C")?.ACCOUNT_ID,
            };
            setLocalTransaction(updatedTransaction);
            dispatch(getAccountFrom({ GROUP_ID: parseInt(TransactionDetailsID?.find(item => item.EFFECT === "D")?.GROUP_ID) }));
            dispatch(getAccountTo({ GROUP_ID: parseInt(TransactionDetailsID?.find(item => item.EFFECT === "C")?.GROUP_ID) }));
            setEdit(true);
        } else {
            setLocalTransaction({
                STATUS: true,
                FROM_ACCOUNT: null,
                TO_ACCOUNT: null,
                DATE: null,
                AMOUNT: null,
                NARRATION: null,
                PARENT_GROUP_ID1: null,
                PARENT_GROUP_ID2: null,
            });
            setEdit(false);
        }
    }, [selectedTransaction, TransactionDetailsID]);

    useEffect(() => {
        dispatch(getAccountGroup());
    }, [dispatch]);

    const initialValues = localTransaction || {
        STATUS: true,
        FROM_ACCOUNT: null,
        TO_ACCOUNT: null,
        DATE: null,
        AMOUNT: null,
        NARRATION: null,
        PARENT_GROUP_ID1: null,
        PARENT_GROUP_ID2: null,
    };

    const handleCancel = () => {
        dispatch(toggleNewDialog(false));
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const post1 = {
                DATE: values.DATE,
                AMOUNT: values.AMOUNT,
                NARRATION: values.NARRATION,
                detailsArray: [
                    {
                        ACCOUNT_ID: values.FROM_ACCOUNT,
                        EFFECT: "D"
                    },
                    {
                        ACCOUNT_ID: values.TO_ACCOUNT,
                        EFFECT: "C"
                    }
                ],
                STATUS: values.STATUS,

            };


            const post2 = {
                ID: values.ID,
                DATE: values.DATE,
                AMOUNT: values.AMOUNT,
                NARRATION: values.NARRATION,
                detailsArray: [
                    {
                        ACCOUNT_ID: values.FROM_ACCOUNT,
                        EFFECT: "D"
                    },
                    {
                        ACCOUNT_ID: values.TO_ACCOUNT,
                        EFFECT: "C"
                    }
                ],
                STATUS: values.STATUS,

            };

            const action = edit
                ? await dispatch(putTransaction(post2))
                : await dispatch(postTransaction(post1));

            if (action.payload.code < 300) {
                dispatch(getAllTransaction());
                dispatch(toggleNewDialog(false));
                api.success({
                    message: "Form Submitted Successfully.",
                });
            } else {
                console.error("Error occurred during form submission:", action.payload.error);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleGroupChange1 = (value) => {
        dispatch(getAccountFrom({ GROUP_ID: value }));
    };

    const handleGroupChange2 = (value) => {
        dispatch(getAccountTo({ GROUP_ID: value }));
    };

    return (
        <div className="mt-4">
            {contextHolder}
            {loading2 ? (
                <div className="w-full flex justify-center h-60 items-center">
                    <Spin size="large" />
                </div>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, touched, errors, handleChange, setFieldValue }) => (
                        <FormikForm>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.DATE && errors.DATE ? errors.DATE : ""}
                                        validateStatus={touched.DATE && errors.DATE ? "error" : undefined}
                                    >
                                        <span className="text-xs">Date</span>
                                        <Field name="DATE">
                                            {({ field, form }) => (
                                                <DatePicker
                                                    {...field}
                                                    value={field.value ? moment(field.value) : null}
                                                    onChange={(date, dateString) =>
                                                        form.setFieldValue("DATE", dateString)
                                                    }
                                                    onBlur={form.handleBlur}
                                                    className="!rounded"
                                                    style={{ width: "100%", padding: "8px" }}
                                                />
                                            )}
                                        </Field>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.AMOUNT && errors.AMOUNT ? errors.AMOUNT : ""}
                                        validateStatus={touched.AMOUNT && errors.AMOUNT ? "error" : undefined}
                                    >
                                        <span className="text-xs">Amount</span>
                                        <Input
                                            name="AMOUNT"
                                            type="number"
                                            placeholder="Amount"
                                            value={values.AMOUNT}
                                            onChange={handleChange}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.PARENT_GROUP_ID1 && errors.PARENT_GROUP_ID1 ? errors.PARENT_GROUP_ID1 : ""}
                                        validateStatus={touched.PARENT_GROUP_ID1 && errors.PARENT_GROUP_ID1 ? "error" : undefined}
                                    >
                                        <span className="text-xs">Account Group</span>
                                        <Select
                                            showSearch
                                            placeholder="Select PARENT GROUP name"
                                            onChange={(value) => {
                                                setFieldValue("PARENT_GROUP_ID1", value);
                                                handleGroupChange1(value);
                                                setFieldValue("FROM_ACCOUNT", null);
                                            }}
                                            value={values.PARENT_GROUP_ID1}
                                            className="h-[40px] rounded"
                                        >
                                            {groupList1?.map((parentgroup) => (
                                                <Option
                                                    key={parentgroup.ID}
                                                    value={parentgroup.ID}
                                                    className="text-gray-400"
                                                >
                                                    {parentgroup.NAME}


                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.FROM_ACCOUNT && errors.FROM_ACCOUNT ? errors.FROM_ACCOUNT : ""}
                                        validateStatus={touched.FROM_ACCOUNT && errors.FROM_ACCOUNT ? "error" : undefined}
                                    >
                                        <span className="text-xs">From Account</span>
                                        <Select
                                            showSearch
                                            placeholder="Select Deduction account"
                                            onChange={(value) => setFieldValue("FROM_ACCOUNT", value)}
                                            value={values.FROM_ACCOUNT}
                                            className="h-[40px] rounded"
                                            disabled={!values.PARENT_GROUP_ID1}
                                        >
                                            {accfrom?.map((category) => (
                                                <Option
                                                    key={category.ID}
                                                    value={category.ID}
                                                    className="text-gray-400"
                                                    disabled={values.TO_ACCOUNT === category.ID}
                                                >
                                                    {category.NAME}


                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.PARENT_GROUP_ID2 && errors.PARENT_GROUP_ID2 ? errors.PARENT_GROUP_ID2 : ""}
                                        validateStatus={touched.PARENT_GROUP_ID2 && errors.PARENT_GROUP_ID2 ? "error" : undefined}
                                    >
                                        <span className="text-xs">Account Group</span>
                                        <Select
                                            showSearch
                                            placeholder="Select PARENT GROUP name"
                                            onChange={(value) => {
                                                setFieldValue("PARENT_GROUP_ID2", value);
                                                handleGroupChange2(value);
                                                setFieldValue("TO_ACCOUNT", null);
                                            }}
                                            value={values.PARENT_GROUP_ID2}
                                            className="h-[40px] rounded"
                                        >
                                            {groupList2?.map((parentgroup) => (
                                                <Option
                                                    key={parentgroup.ID}
                                                    value={parentgroup.ID}
                                                    className="text-gray-400"
                                                >
                                                    {parentgroup.NAME}


                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.TO_ACCOUNT && errors.TO_ACCOUNT ? errors.TO_ACCOUNT : ""}
                                        validateStatus={touched.TO_ACCOUNT && errors.TO_ACCOUNT ? "error" : undefined}
                                    >
                                        <span className="text-xs">To Account</span>
                                        <Select
                                            showSearch
                                            placeholder="Select Crediting account"
                                            onChange={(value) => setFieldValue("TO_ACCOUNT", value)}
                                            value={values.TO_ACCOUNT}
                                            className="h-[40px] rounded"
                                            disabled={!values.PARENT_GROUP_ID2}
                                        >
                                            {accto?.map((category) => (
                                                <Option
                                                    key={category.ID}
                                                    value={category.ID}
                                                    className="text-gray-400"
                                                    disabled={values.FROM_ACCOUNT === category.ID}
                                                >
                                                    {category.NAME}


                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.NARRATION && errors.NARRATION ? errors.NARRATION : ""}
                                        validateStatus={touched.NARRATION && errors.NARRATION ? "error" : undefined}
                                    >
                                        <span className="text-xs">Narration</span>
                                        <TextArea
                                            rows={4}
                                            placeholder="Narration"
                                            maxLength={6000}
                                            name="NARRATION"
                                            value={values.NARRATION}
                                            onChange={handleChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                                        validateStatus={touched.STATUS && errors.STATUS ? "error" : undefined}
                                    >
                                        <span className="text-xs">Status</span>
                                        <div style={{ marginTop: 8 }}>
                                            <Switch
                                                checked={values.STATUS}
                                                onChange={(checked) => setFieldValue("STATUS", checked)}
                                                defaultChecked
                                                style={{ width: 50 }}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item className="flex justify-end">
                                        <Button
                                            onClick={handleCancel}
                                            className="mr-4 py-4 px-6 border border-blue-500"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {edit ? "Update" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            )}

        </div>
    );
};

export default TransactionForm;
