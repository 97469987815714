import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Form, Row, Col, Button, Radio, notification } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postAssetRequest,
  putAssetRequest,
  getAssetRequest,
} from "../store/dataSlice";

const AssetRequestForm = ({ handleRefresh }) => {
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedAssetRequest = useSelector(
    (state) => state.assetrequest.state.selectedAssetRequest
  );

  const validationSchema = Yup.object({
    APPROVAL_STATUS: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedAssetRequest) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedAssetRequest]);

  const initialValues = selectedAssetRequest || {
    APPROVAL_STATUS: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log("Form values:", values);
    try {
      const action = edit
        ? await dispatch(putAssetRequest(values))
        : await dispatch(postAssetRequest(values));
      if (action.payload.code < 300) {
        dispatch(getAssetRequest());
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Approval Status Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during Approval Status submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            submitForm,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.APPROVAL_STATUS && errors.APPROVAL_STATUS
                        ? errors.APPROVAL_STATUS
                        : ""
                    }
                    validateStatus={
                      touched.APPROVAL_STATUS && errors.APPROVAL_STATUS
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">APPROVAL STATUS</span>
                    <Radio.Group
                      name="APPROVAL_STATUS"
                      value={values.APPROVAL_STATUS}
                      onChange={(e) =>
                        setFieldValue("APPROVAL_STATUS", e.target.value)
                      }
                    >
                      <Radio
                        value="A"
                        style={{
                          color: values.APPROVAL_STATUS === "A" ? "green" : "",
                        }}
                      >
                        Approved
                      </Radio>
                      <Radio
                        value="R"
                        style={{
                          color: values.APPROVAL_STATUS === "R" ? "red" : "",
                        }}
                      >
                        Rejected
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  {selectedAssetRequest?.APPROVAL_STATUS === "P" && (
                    <Form.Item className="flex justify-end">
                      <Button
                        onClick={handleCancel}
                        className="mr-4 text-blue-500 border-blue-500"
                      >
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit">
                        {edit ? "Update" : "Submit"}
                      </Button>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AssetRequestForm;
