import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Dropdown,
  Upload,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
} from "antd";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postItem,
  putItem,
  getItem,
  getStateId,
  getCategory,
  getUnit,
} from "../store/dataSlice";
import UploadItemImage from "../Components/UploadItemImage";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
// import { apigetGstType } from "../../../services/DistributorService";
import axios from "axios";
import BaseService from "../../../services/BaseService";
const { TextArea } = Input;

const ItemForm = ({ handleRefresh }) => {
  // const [showSaveNext, setShowSaveNext] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const selectedItem = useSelector((state) => state.item.state.selectedItem);

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    CATEGORY_ID: Yup.string().required("Required"),
    PRODUCTION_PRICE: Yup.number().integer().required("Required"),
    SALE_PRICE: Yup.number().integer().required("Required"),
    DESCRIPTION: Yup.string().required("Required"),
    UNIT_ID: Yup.string().required("Required"),
    OPENING_STOCK: Yup.number().integer().required("Required"),
    EXPIRY_DAYS: Yup.number().integer().required("Required"),
    IMAGE_URL_1: Yup.string().required("Required"),
    SHORT_CODE: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    BASE_UNIT_ID: Yup.string().required("Required"),
    BASE_QTY: Yup.number().integer().required("Required"),
  });

  useEffect(() => {
    if (selectedItem) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedItem]);

  const initialValues = selectedItem || {
    NAME: "",
    CATEGORY_ID: "",
    PRODUCTION_PRICE: "",
    SALE_PRICE: "",
    DESCRIPTION: "",
    UNIT_ID: "",
    OPENING_STOCK: "",
    EXPIRY_DAYS: "",
    IMAGE_URL_1: "",
    SHORT_CODE: "",
    STATUS: true,
    BASE_UNIT_ID: "",
    BASE_QTY: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // ////console.log("Form values:", values);
    try {
      const action = edit
        ? await dispatch(putItem(values))
        : await dispatch(postItem(values));

      if (action.payload.code < 300) {
        // ////console.log("Form submitted successfully!");
        dispatch(getItem());
        // ////console.log("Form submitted successfully!");
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // drpodown code

  const stateType = useSelector((state) => state?.item?.data?.stateList?.data);

  const UnitType = useSelector((state) => state?.item?.data?.unitList?.data);
  const categoryType = useSelector(
    (state) => state?.item?.data?.categoryList?.data
  );
  // ////console.log("UnitType", UnitType);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const { Option } = Select;

  useEffect(() => {
    dispatch(getUnit());
  }, []);

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  // end

  return (
    <>
      <div className="mt-4">
        {contextHolder}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            isSubmitting,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.NAME && errors.NAME ? errors.NAME : ""}
                    validateStatus={
                      touched.NAME && errors.NAME ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Item Name </span>
                    <Input
                      name="NAME"
                      placeholder="Item Name"
                      value={values.NAME}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.UNIT_ID && errors.UNIT_ID ? errors.UNIT_ID : ""
                    }
                    validateStatus={
                      touched.UNIT_ID && errors.UNIT_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Unit </span>

                    <Select
                      showSearch
                      placeholder="Select Unit"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "UNIT_ID", value },
                        })
                      }
                      value={values.UNIT_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {UnitType?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.BASE_UNIT_ID && errors.BASE_UNIT_ID
                        ? errors.BASE_UNIT_ID
                        : ""
                    }
                    validateStatus={
                      touched.BASE_UNIT_ID && errors.BASE_UNIT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Base Unit </span>

                    <Select
                      showSearch
                      placeholder="Select Base Unit"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "BASE_UNIT_ID", value },
                        })
                      }
                      value={values.BASE_UNIT_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {UnitType?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.CATEGORY_ID && errors.GST_NO
                        ? errors.CATEGORY_ID
                        : ""
                    }
                    validateStatus={
                      touched.CATEGORY_ID && errors.CATEGORY_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs"> Category </span>

                    <Select
                      showSearch
                      placeholder="Select Category"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "CATEGORY_ID", value },
                        })
                      }
                      value={values.CATEGORY_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {categoryType?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.BASE_QTY && errors.BASE_QTY ? errors.BASE_QTY : ""
                    }
                    validateStatus={
                      touched.BASE_QTY && errors.BASE_QTY ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Base Quantity</span>
                    <Input
                      name="BASE_QTY"
                      placeholder="Base Quantity"
                      type="number"
                      min="0"
                      value={values.BASE_QTY}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.PRODUCTION_PRICE && errors.PRODUCTION_PRICE
                        ? errors.PRODUCTION_PRICE
                        : ""
                    }
                    validateStatus={
                      touched.PRODUCTION_PRICE && errors.PRODUCTION_PRICE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Production Price</span>
                    <Input
                      name="PRODUCTION_PRICE"
                      placeholder="Production Price"
                      type="number"
                      min="0"
                      value={values.PRODUCTION_PRICE}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.SALE_PRICE && errors.SALE_PRICE
                        ? errors.SALE_PRICE
                        : ""
                    }
                    validateStatus={
                      touched.SALE_PRICE && errors.SALE_PRICE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Sales Price</span>
                    <Input
                      name="SALE_PRICE"
                      placeholder="Sale Price"
                      type="number"
                      min="0"
                      value={values.SALE_PRICE}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? errors.DESCRIPTION
                        : ""
                    }
                    validateStatus={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Description </span>

                    <Input
                      name="DESCRIPTION"
                      placeholder="Description"
                      value={values.DESCRIPTION}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.OPENING_STOCK && errors.OPENING_STOCK
                        ? errors.OPENING_STOCK
                        : ""
                    }
                    validateStatus={
                      touched.OPENING_STOCK && errors.OPENING_STOCK
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Opening Stock</span>
                    <Input
                      name="OPENING_STOCK"
                      placeholder="Opening Stock"
                      type="number"
                      min="0"
                      value={values.OPENING_STOCK}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EXPIRY_DAYS && errors.EXPIRY_DAYS
                        ? errors.EXPIRY_DAYS
                        : ""
                    }
                    validateStatus={
                      touched.EXPIRY_DAYS && errors.EXPIRY_DAYS
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Expiry Days</span>
                    <Input
                      name="EXPIRY_DAYS"
                      placeholder="EXPIRY_DAYS"
                      type="number"
                      min="0"
                      value={values.EXPIRY_DAYS}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.SHORT_CODE && errors.EMAIL_ID
                        ? errors.SHORT_CODE
                        : ""
                    }
                    validateStatus={
                      touched.SHORT_CODE && errors.SHORT_CODE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Short Code</span>
                    <Input
                      name="SHORT_CODE"
                      placeholder="Short Code"
                      value={values.SHORT_CODE}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.IMAGE_URL_1 && errors.IMAGE_URL_1
                        ? errors.IMAGE_URL_1
                        : ""
                    }
                    validateStatus={
                      touched.IMAGE_URL_1IMAGE_URL_1 && errors.IMAGE_URL_1
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs ">Item Image </span>

                    <Upload
                      name="IMAGE_URL_1"
                      customRequest={({ file, onSuccess }) => {
                        UploadItemImage(
                          file,
                          "your-upload-endpoint",
                          "IMAGE_URL_1"
                        ).then((newFilename) => {
                          if (newFilename) {
                            onSuccess("ok");
                            setFieldValue("IMAGE_URL_1", newFilename);
                          }
                        });
                      }}
                      listType="picture"
                      className="!rounded"
                    >
                      <Button
                        className="w-full sm:w-40 md:w-60 lg:w-31 h-10"
                        icon={<UploadOutlined />}
                      >
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>

                    <div style={{ marginTop: 8 }}>
                      <Switch
                        defaultChecked
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end ">
                    <Button
                      onClick={handleCancel}
                      className="mr-4 mr-4 text-blue-500 border-blue-500"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ItemForm;
