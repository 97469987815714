import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllAccountgroup,
  apipostAccountgroup,
  apiputAccountgroup,
} from "../../../services/AccountGroupService";

export const GetAllAccountgroup = createAsyncThunk(
  "asset/data/getAccountGroup",
  async (data) => {
    const response = await apiGetAllAccountgroup(data);
    return response.data;
  }
);
export const postAccountgroup = createAsyncThunk(
  "asset/data/postAccountGroup",
  async (data) => {
    const response = await apipostAccountgroup(data);
    return response.data;
  }
);
export const putAccountgroup = createAsyncThunk(
  "asset/data/putAccountGroup",
  async (data) => {
    const response = await apiputAccountgroup(data);
    ////console.log(response);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "accountgroupList/data",
  initialState: {
    loading: false,

    accountgroupList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.accountgroupList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAccountgroup.fulfilled, (state, action) => {
        state.loading = false;
        state.accountgroupList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(GetAllAccountgroup.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetAllAccountgroup.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(postAccountgroup.fulfilled, (state, action) => {})
      .addCase(putAccountgroup.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, w } = dataSlice.actions;

export default dataSlice.reducer;
