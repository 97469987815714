import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllExpense,
  apipostExpense,
  apiputExpense,
  apiGetExpenseTypeId,
} from "../../../services/ExpenseService";

export const getExpenseTypeId = createAsyncThunk(
  "expense/data/getExpenseTypeId",
  async (data) => {
    const response = await apiGetExpenseTypeId(data);
    return response.data;
  }
);

export const getExpense = createAsyncThunk(
  "expense/data/getExpense",
  async (data) => {
    const response = await apiGetAllExpense(data);
    return response.data;
  }
);
export const postExpense = createAsyncThunk(
  "expense/data/postExpense",
  async (data) => {
    const response = await apipostExpense(data);
    return response.data;
  }
);
export const putExpense = createAsyncThunk(
  "expense/data/putExpense",
  async (data) => {
    const response = await apiputExpense(data);
    ////console.log(response);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "expenseList/data",
  initialState: {
    loading: false,
    expenseList: [],
    expenseTypeIdList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.expenseList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getExpenseTypeId.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseTypeIdList = action.payload;
      })

      .addCase(postExpense.fulfilled, (state, action) => {})
      .addCase(putExpense.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
