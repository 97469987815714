import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllCustomer,
  apiGetAllCustomerDetails,
  apiPostAllCustomer,
  apiPutAllCustomer,
  apiGetBranch,
  apiGetEmployee,
  apiGetDepartment,
} from "../../../services/CustomerService";

export const getCustomer = createAsyncThunk(
  "customer/data/getCustomer",
  async (data) => {
    const response = await apiGetAllCustomer(data);
    return response.data;
  }
);

export const getCustomerxls = createAsyncThunk(
  "customer/data/getCustomerxls",
  async (data) => {
    const response = await apiGetAllCustomer(data);
    return response.data;
  }
);

export const getCustomerDetails = createAsyncThunk(
  "customer/data/getCustomerDetails",
  async (data) => {
    const response = await apiGetAllCustomerDetails(data);
    return response.data;
  }
);

export const postCustomer = createAsyncThunk(
  "customer/data/postCustomer",
  async (data) => {
    const response = await apiPostAllCustomer(data);
    return response.data;
  }
);

export const putCustomer = createAsyncThunk(
  "customer/data/putCustomer",
  async (data) => {
    const response = await apiPutAllCustomer(data);
    return response.data;
  }
);

export const getEmployee = createAsyncThunk(
  "customer/data/getEmployee",
  async (data) => {
    const response = await apiGetEmployee(data);
    return response.data;
  }
);

export const getBranch = createAsyncThunk(
  "customer/data/getBranch",
  async (data) => {
    const response = await apiGetBranch(data);
    ////console.log(response);
    return response.data;
  }
);

export const getDepartment = createAsyncThunk(
  "customer/data/getDepartment",
  async (data) => {
    const response = await apiGetDepartment(data);
    ////console.log(response.data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "customerList/data",
  initialState: {
    loading: false,
    customerList: [],
    customerDetails: [],
    employeeList: [],
    branchList: [],
    departmentList: [],
    XlsList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.customerList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomer.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCustomerxls.fulfilled, (state, action) => {
        state.XlsList = action.payload;
      })

      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetails = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getCustomerDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomerDetails.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getEmployee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.branchList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getBranch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBranch.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departmentList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getDepartment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(postCustomer.fulfilled, (state, action) => {})
      .addCase(putCustomer.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
