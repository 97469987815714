import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  notification,
  Select,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { postForm, putForm, getForm } from "../store/dataSlice";

import { DatePicker, Space } from "antd";
import moment from "moment";

// import { apigetGstType } from "../../../services/DistributorService";

const { TextArea } = Input;

const SubmitForm = ({ handleRefresh }) => {
  const [showSaveNext, setShowSaveNext] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedForm = useSelector((state) => state.form.state.selectedForm);

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    PARENT_ID: Yup.string().required("Required"),
    LINK: Yup.string().required("Required"),
    ICON: Yup.string().required("Required"),

    // status: Yup.boolean().required("Required"),
  });

  useEffect(() => {
    if (selectedForm) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedForm]);

  // useEffect(() => {
  //     const fetchGSTType = async () => {
  //         try {
  //             const response = await apigetGstType();

  //             if (response.status === 200) {
  //                 const responseData = response.data;
  //                 ////console.log(responseData);
  //             } else {
  //                 console.error();
  //             }
  //         } catch (err) {
  //             ////console.log(err);
  //         }
  //     };

  //     fetchGSTType();
  // }, []);

  const initialValues = selectedForm || {
    NAME: "",
    PARENT_ID: 0,
    LINK: "",
    ICON: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    ////console.log("Form values:", values);
    try {
      const action = edit
        ? await dispatch(putForm(values))
        : await dispatch(postForm(values));

      if (action.payload.code < 300) {
        ////console.log("Form submitted successfully!");
        dispatch(getForm());
        ////console.log("Form submitted successfully!");
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const types = useSelector((state) => state?.form?.data?.formList?.data);

  ////console.log(types);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const { Option } = Select;

  useEffect(() => {
    dispatch(getForm());
  }, []);

  return (
    <>
      <div className="mt-4">
        {contextHolder}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            submitForm,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.NAME && errors.NAME ? errors.NAME : ""}
                    validateStatus={
                      touched.NAME && errors.NAME ? "error" : undefined
                    }
                  >
                    <span className="text-xs"> NAME </span>

                    <Input
                      name="NAME"
                      placeholder="Form Name.."
                      value={values.NAME}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.PARENT_ID && errors.PARENT_ID
                        ? errors.PARENT_ID
                        : ""
                    }
                    validateStatus={
                      touched.PARENT_ID && errors.PARENT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs"> Parent Name</span>

                    {/* <TextArea
                                            rows={4}
                                            placeholder="You Can Add Maximum 512 Characters.."
                                            maxLength={6000}
                                            value={values.DESCRIPTION}
                                            onChange={(e) => setFieldValue("DESCRIPTION", e.target.value)}
                                        /> */}

                    {/* <Input
                                            name="PARENT_ID"
                                            placeholder="Form Name.."
                                            value={values.PARENT_ID}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        /> */}

                    <Select
                      showSearch
                      placeholder="Select Parent Name"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "PARENT_ID", value },
                        })
                      }
                      value={values.PARENT_ID || 0}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      <Option value={0} className="text-gray-400">
                        None
                      </Option>
                      {types?.map((x) => (
                        <Option
                          key={x.ID}
                          value={x.ID}
                          className="text-gray-400"
                        >
                          {x.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.LINK && errors.LINK ? errors.LINK : ""}
                    validateStatus={
                      touched.LINK && errors.LINK ? "error" : undefined
                    }
                  >
                    <span className="text-xs">LINK</span>

                    <Input
                      name="LINK"
                      placeholder="LINK.."
                      value={values.LINK}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.ICON && errors.ICON ? errors.ICON : ""}
                    validateStatus={
                      touched.ICON && errors.ICON ? "error" : undefined
                    }
                  >
                    <span className="text-xs">ICON</span>

                    <Input
                      name="ICON"
                      placeholder="Form Name.."
                      value={values.ICON}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs ">Status</span>

                    {/* <Switch
                                            checked={values.STATUS}
                                            onChange={(checked) => setFieldValue("STATUS", checked)}
                                            defaultChecked
                                            style={{ padding: "8px" }}

                                        /> */}
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        defaultChecked
                        style={{ width: 50 }}
                      />
                    </div>

                    {/* setFieldValue is a function provided by Formik, a popular library for managing
                                         forms in React. It allows you to programmatically set the value of a field in the
                                          form state. This is particularly useful when you need to update the value of a field 
                                          based on some external input or event, rather than user input directly within a form field. */}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      className="mr-4 mr-4 text-blue-500 border-blue-500"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      // disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SubmitForm;
