import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, Switch, notification } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postAssetCategory,
  putAssetCategory,
  getAssetCategory,
} from "../store/dataSlice";

const { TextArea } = Input;

const AssetCategoryForm = ({ handleRefresh }) => {
  const [showSaveNext, setShowSaveNext] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedAssetCategory = useSelector(
    (state) => state.assetCategory.state.selectedAssetCategory
  );

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    STATUS: Yup.boolean().required("Required"),
    SEQ_NO: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedAssetCategory) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedAssetCategory]);

  const initialValues = selectedAssetCategory || {
    NAME: "",
    STATUS: true,
    SEQ_NO: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    ////console.log("Form values:", values);

    try {
      const action = edit
        ? await dispatch(putAssetCategory(values))
        : await dispatch(postAssetCategory(values));
      if (action.payload.code < 300) {
        ////console.log("Asset Category submitted successfully!");
        dispatch(getAssetCategory());
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Asset Category Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during AssetCategory submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.NAME && errors.NAME ? errors.NAME : ""}
                    validateStatus={
                      touched.NAME && errors.NAME ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Asset Name</span>
                    <Input
                      name="NAME"
                      placeholder="Enter Asset Name.."
                      value={values.NAME}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.SEQ_NO && errors.SEQ_NO ? errors.SEQ_NO : ""}
                    validateStatus={
                      touched.SEQ_NO && errors.SEQ_NO ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Seq No</span>
                    <Input
                      name="SEQ_NO"
                      placeholder="Enter Seq No.."
                      value={values.SEQ_NO}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs ">Status</span>
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        defaultChecked
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AssetCategoryForm;
