import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Table, Pagination, Switch, Modal, Spin, Select } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { putEmployee, setTableData } from "../store/dataSlice";
import {
  setSelectedEmployee,
  toggleFilterDialog,
  toggleNewDialog,
  setSwitch,
  toggleFilter,
} from "../store/stateSlice";
import OfferLetter from "./Offerletter";
import EmployeeCalender from "./EmployeeCalender";
import EmpFilter from "./EmpFilter";
import { FaFilePdf } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { FaRegImage } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const EmployeeTable = ({ handleRefresh }) => {
  const dispatch = useDispatch();
  const offerLetterRef = useRef();
  const [openCalender, setOpenCalender] = useState(false);
  const location = useLocation();
  const loading = useSelector((state) => state.employee.data.loading);

  const [selectedEmployee, setSelectedEmployeeState] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.employee.data.tableData
  );
  const [selectedProfilePhoto, setSelectedProfilePhoto] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCalenderOk = () => {
    setOpenCalender(false);
  };
  const handleCancelCalender = () => {
    setOpenCalender(false);
  };

  const isCalenderOpen = () => {
    setOpenCalender(true);
  };

  const data = useSelector((state) => state.employee.data.employeeList.data);

  const onEdit = (record) => {
    dispatch(setSelectedEmployee(record));
    dispatch(toggleNewDialog(true));
  };
  const onShow = (record) => {
    setSelectedProfilePhoto(record.PROFILE_PHOTO);
    showModal();
  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const onShowCalender = (record) => {
    dispatch(setSelectedEmployee(record));
    isCalenderOpen();
  };

  const handlePrint = useReactToPrint({
    content: () => offerLetterRef.current,
  });

  useEffect(() => {
    if (selectedEmployee) {
      handlePrint();
    }
  }, [selectedEmployee]);

  const onShowPdf = (record) => {
    setSelectedEmployeeState(record);
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putEmployee(updatedRecord));

    dispatch(setSwitch(record));
  };

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleFilterDialog(false));
    dispatch(toggleFilter(false));
  }, [location.pathname, dispatch, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      width: 155,
      fixed: "left",
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
            <span
              onClick={() => onShow(record)}
              className="text-2xl ml-2 text-red-500 cursor-pointer"
            >
              <FaRegImage />
            </span>

            <span
              onClick={() => onShowCalender(record)}
              className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
            >
              <SlCalender />
            </span>
            <span
              onClick={() => onShowPdf(record)}
              className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
            >
              <FaFilePdf />
            </span>
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FIRST_NAME",
      width: 200,
      render: (_, record) =>
        `${record.FIRST_NAME} ${record.MIDDLE_NAME ? record.MIDDLE_NAME : ""} ${
          record.LAST_NAME ? record.LAST_NAME : ""
        }`,
    },

    {
      title: <span className="text-gray-500">Designation</span>,
      dataIndex: "DESIGNATION",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Email Id</span>,
      dataIndex: "EMAIL_ID",
      width: 250,
    },
    {
      title: <span className="text-gray-500">Mobile No.</span>,
      dataIndex: "MOBILE_NO",
      width: 150,
    },

    {
      title: <span className="text-gray-500">Organization Name</span>,
      dataIndex: "ORGANISATION_NAME",
      width: 250,
    },
    {
      title: <span className="text-gray-500">Department Name</span>,
      dataIndex: "DEPARTMENT_NAME",
      width: 220,
    },
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FIRST_NAME",
      width: 200,
    },
    {
      title: <span className="text-gray-500">Branch</span>,
      dataIndex: "BRANCH_NAME",
      width: 150,
    },

    {
      title: <span className="text-gray-500">Gender</span>,
      dataIndex: "GENDER",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Date of Birth</span>,
      dataIndex: "DOB",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Address</span>,
      dataIndex: "ADDRESS",
      width: 150,
    },

    {
      title: <span className="text-gray-500">Joining Date</span>,
      dataIndex: "DOJ",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      width: 100,
      fixed: "right",
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <>
      <EmpFilter />

      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: "16px" }}>
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 1300 }}
              pagination={{
                current: pageIndex,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20],
                onChange: handleTableChange,
                onShowSizeChange: handleTableChange,
              }}
              onChange={handleTableChange}
            />
          </div>

          <Modal
            title=""
            width={600}
            height={400}
            open={isModalOpen}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}
          >
            <div className="flex justify-center items-center">
              <img
                src={`https://hrm.brothers.net.in/static/employeeProfile/${selectedProfilePhoto}`}
                alt=""
                width={400}
                height={300}
              />
            </div>
          </Modal>

          <Modal
            open={openCalender}
            onCancel={handleCancelCalender}
            onOk={handleCalenderOk}
            footer={null}
            className="flex justify-center"
            style={{ top: "2%" }}
          >
            <EmployeeCalender />
          </Modal>
          <div className="hidden">
            <OfferLetter
              ref={offerLetterRef}
              selectedEmployee={selectedEmployee}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default EmployeeTable;
