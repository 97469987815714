import React, { useEffect, useCallback, useState } from "react";
import { Button, Table, Switch } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getCart, setTableData } from "../store/dataSlice";
import { setSelectedDistributor, toggleNewDialog } from "../store/stateSlice";

const CartTable = () => {
  const dispatch = useDispatch();

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.cart.data.tableData
  );

  const fetchData = useCallback(() => {
    dispatch(getCart({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const data = useSelector((state) => state.cart.data.cartList.data);
  ////console.log(data);
  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      align: "center",
      render: (_, record) => (
        <>
          <div className="flex justify-between px-2">
            <span
              // onClick={() => onEdit(record)}
              className="text-xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEdit />
            </span>

            <span
              className="text-xl ml-2 text-red-500 cursor-pointer"
              // onClick={() => {
              //   onView(record);
              // }}
            ></span>
          </div>
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Distributor</span>,
      dataIndex: "",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Item</span>,
      dataIndex: "",
      width: 140,
      render: (_, record) => `${record.DEPARTMENT_NAME} `,
    },

    {
      title: <span className="text-gray-500">Quantity</span>,
      dataIndex: "",
      width: 160,
    },

    {
      title: <span className="text-gray-500">Rate</span>,
      dataIndex: "",
      width: 160,
    },
    {
      title: <span className="text-gray-500">Total Amount</span>,
      dataIndex: "",
      width: 140,
    },
    {
      title: <span className="text-gray-500">Final</span>,
      dataIndex: "",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, record) => {
        const handleChange = (checked) => {
          ////console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
        // pagination={{
        //   current: pageIndex,
        //   pageSize: pageSize,
        //   total: total,
        //   showSizeChanger: true,
        //   pageSizeOptions: [5, 10, 20],
        //   onChange: handleTableChange,
        //   onShowSizeChange: handleTableChange,
        // }}
        // onChange={handleTableChange}
      />
    </>
  );
};

export default CartTable;
