import React from "react";
import { Form, Input, Button, notification } from "antd";
import { apiChangePassword } from "../../services/AuthService";

const ChangePassword = ({ onCancel }) => {
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values) => {
    const userData = JSON.parse(localStorage.getItem("UserData"));
    const EMP_ID = userData?.[0]?.EMP_ID;

    const payload = {
      EMP_ID: EMP_ID,
      OLD_PASSWORD: values.currentPassword,
      NEW_PASSWORD: values.newPassword,
    };

    try {
      const response = await apiChangePassword(payload);
      //console.log(response);

      api.success({
        message: "Password changed successfully",
        duration: 1,
      });

      onCancel();
    } catch (error) {
      api.error({
        message: "Password change failed",
        description: error.message || "An error occurred",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password className="!rounded h-10" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password className="!rounded h-10" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password className="!rounded h-10" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Change Password
          </Button>
          <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePassword;
