import React from 'react';
import { Button, Select } from "antd";
import * as XLSX from 'xlsx';
import { TbFileTypeXls } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import Employeereport from '..';

const GenerateExcel = () => {

    const data = useSelector(
        (state) => state.employeereport.data.XlsList?.data
    );
    

    const exportToExcel = () => {

        const newList = data.map((item) => ({
            "Employee Name": item.EMPLOYEE_NAME,
            "Total Target Ammount": item.TOTAL_TARGET_AMOUNT,
            "Total Target Customer": item.TOTAL_TARGET_CUSTOMER,
            "Total Amount": item.TOTAL_AMOUNT,
            "Total Customer Created": item.TOTAL_CUSTOMER_CREATED,
            "Pending Target Amount": item.PENDING_TARGET_AMOUNT,
        }));
        const worksheet = XLSX.utils.json_to_sheet(newList);


        worksheet['!cols'] = [
            { wch: 30 },  // Width for 'Name' column
            { wch: 20 },  // Width for 'Branch' column
            { wch: 20 },   // Width for 'Department' column
            { wch: 20 },  // Width for 'Name' column
            { wch: 22 },  // Width for 'Branch' column
            { wch: 20 },  // Width for 'Department' column
        ];

        // Create a new workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "Employee_Target_Report.xlsx");
    };

    return (
        <div>
            {/* <button onClick={exportToExcel}>Download Excel File</button> */}

            <div className="flex mt-4 mr-5 md:mt-0">
                <div className="flex items-center">
                    <Button onClick={exportToExcel} className="py-4 px-6 font-medium border border-gray-300">
                        <TbFileTypeXls className="text-lg" />
                        <p>Export</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default GenerateExcel;
