import React, { useEffect, useState } from "react";
import Locationreducer from "./store";
import { injectReducer } from "../../store";
import moment from 'moment';
import { PiMapPinFill } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getLocation,
  getBranchId,
  getDepartmentId,
  getEmployeeId,
} from "./store/dataSlice";
import LocationMapComponent from "./Components/LocationMapComponent";
import LocationFilter from "./Components/LocationFilter"
import { FaFilter } from "react-icons/fa";
import { Button, Select } from "antd";
import LocationTable from "./Components/LocationTable";
import { toggleNewDialog, toggleMap, settexts, LocName2, LocName } from "./store/stateSlice";
injectReducer("location", Locationreducer);

const Location = () => {
  const locations = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.location.data.locationList?.data);

  const mapToggle = useSelector((state) => state.location.state.mapToggle);


  const dialog = useSelector((state) => state.location.state.newDialog);
  const handleFilterClick2 = () => {
    if (mapToggle) {
      dispatch(toggleMap(false));
      dispatch(LocName2(0));
      localStorage.setItem('timerDuration', '2000');

      // window.location.reload();
      dispatch(toggleNewDialog(false));

    } else {
      localStorage.setItem('timerDuration', '2000');

      dispatch(toggleMap(true));

    }
  };


  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };




  const i = useSelector((state) => state.location.state.locname2);
  const j = useSelector((state) => state.location.state.locname);


  // useEffect(() => {
  //   dispatch(LocName2(i + 1));
  // }, [locations.pathname]);


  // if (i >= 2) {
  //   dispatch(LocName2(0));
  //   dispatch(toggleMap(true));
  // }



  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">
            Location Master
          </div>

          <div className="flex">
            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[7px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick2}
              >
                <table>
                  <tr>
                    <td><PiMapPinFill className="text-[20px]" /> </td>
                    <td>{mapToggle ? "Open Map" : "Close Map"}



                    </td>

                  </tr>
                </table>

              </div>


            </div>
            <div className="flex items-center">

              {mapToggle ? <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div> : null}



            </div>
          </div>
        </div>
        <LocationFilter />

        {mapToggle ?
          <LocationTable />
          :
          <LocationMapComponent />
        }
      </div>
    </>
  );
};

export default Location;