import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAccount,
  apiGetAccountGroup,
  apiGetAllTransaction,
  apipostTransaction,
  apiputTransaction,
  apiGettransactionDetails,
  apiGettransactionDetailsID,
  apiPuttTransactionSwitch,
} from "../../../services/TransactionService";

export const getAllTransactionDetailsIDNull = createAsyncThunk(
  "transaction/data/getAllTransactionDetailsIDNull",
  async () => {
    return null;
  }
);


export const postSwitch = createAsyncThunk(
  "transaction/data/apiPuttTransactionSwitch",
  async (data) => {
    const response = await apiPuttTransactionSwitch(data);
    return response.data;
  }
);

export const getAllTransactionDetailsID = createAsyncThunk(
  "transaction/data/getAllTransactionDetailsID",
  async (data) => {
    const response = await apiGettransactionDetailsID(data);
    return response.data;
  }
);
export const getAllTransactionDetails = createAsyncThunk(
  "transaction/data/getAllTransactionDetails",
  async (data) => {
    const response = await apiGettransactionDetails(data);
    return response.data;
  }
);

export const getAllTransaction = createAsyncThunk(
  "transaction/data/getAllTransaction",
  async (data) => {
    const response = await apiGetAllTransaction(data);
    return response.data;
  }
);
export const getAllTransactionSwitch = createAsyncThunk(
  "transaction/data/getAllTransactionSwitch",
  async (data) => {
    const response = await apiGetAllTransaction(data);
    return response.data;
  }
);


export const getAccountFrom = createAsyncThunk(
  "transaction/data/getAccountFrom",
  async (data) => {
    const response = await apiGetAccount(data);
    return response.data;
  }
);

export const getAccountTo = createAsyncThunk(
  "transaction/data/getAccountTo",
  async (data) => {
    const response = await apiGetAccount(data);
    return response.data;
  }
);


export const getAccountGroup = createAsyncThunk(
  "transaction/data/getAccountGroup",
  async (data) => {
    const response = await apiGetAccountGroup(data);
    return response.data;
  }
);

export const postTransaction = createAsyncThunk(
  "transaction/data/postTransaction",
  async (data) => {
    const response = await apipostTransaction(data);
    return response.data;
  }
);
export const putTransaction = createAsyncThunk(
  "transaction/data/putTransaction",
  async (data) => {
    const response = await apiputTransaction(data);

    return response.data;
  }
);


export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "transactionList/data",
  initialState: {
    loading: false,
    loading2: false,
    categoryList: [],
    allAccount: [],
    allAccountFrom: [],
    allAccountTo: [],
    accountGroup: [],
    transactionList: [],
    transactionDetails: [],
    transactionDetailsID: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.transactionList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.transactionList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getAllTransaction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllTransaction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllTransactionSwitch.fulfilled, (state, action) => {
        state.transactionList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getAccountFrom.fulfilled, (state, action) => {
        state.allAccountFrom = action.payload;
      })
      .addCase(getAccountTo.fulfilled, (state, action) => {
        state.allAccountTo = action.payload;
      })
      .addCase(getAccountGroup.fulfilled, (state, action) => {
        state.accountGroup = action.payload;
      })
      .addCase(getAllTransactionDetails.fulfilled, (state, action) => {
        state.transactionDetails = action.payload;
      })
      .addCase(getAllTransactionDetailsID.fulfilled, (state, action) => {
        state.transactionDetailsID = action.payload;
        state.loading2 = false;
      })
      .addCase(getAllTransactionDetailsID.pending, (state, action) => {
        state.loading2 = true;
      })
      .addCase(getAllTransactionDetailsID.rejected, (state, action) => {
        state.loading2 = false;
      })


      .addCase(getAllTransactionDetailsIDNull.fulfilled, (state, action) => {
        state.transactionDetailsID = action.payload;
      })




      .addCase(postTransaction.fulfilled, (state, action) => { })
      .addCase(putTransaction.fulfilled, (state, action) => { })
      .addCase(postSwitch.fulfilled, (state, action) => { });


  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
