import React, { useEffect, useState, useCallback, useRef } from "react";

import { Button, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toggleFilter } from "../store/stateSlice";
import { getDepartment, setTableData } from "../store/dataSlice";
import { getBranch, getEmployeeTarget } from "../store/dataSlice";

const EmployeeTargetFilter = () => {
  const dispatch = useDispatch();

  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails?.[0]?.EMP_ID;
  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dialog = useSelector(
    (state) => state?.employeetarget?.state?.newDialogFilter
  );

  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearArray = [];
    for (let year = currentYear; year >= 2000; year--) {
      yearArray.push(year);
    }
    setYears(yearArray);
  }, []);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.employeetarget.data.tableData
  );

  //    --------------------- ROLE ID SART ----------------------------

  const filterData1 = JSON.parse(localStorage.getItem("filterData"));
  let BRANCH_ID;
  let DEPARTMENT_ID;
  if (ROLE_ID == 10) {
    BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
    DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
  }

  const fetchData = useCallback(() => {
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? null
          : filters?.DEPARTMENT_ID,

      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? null
          : filters?.BRANCH_ID,
    };

    dispatch(getEmployeeTarget(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  //------------BRANCH MANAGER ------------

  const fetchData10 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      BRANCH_ID: branchIds,
      DEPARTMENT_ID:
        filters?.DEPARTMENT_ID == null ||
        (Array.isArray(filters?.DEPARTMENT_ID) &&
          filters?.DEPARTMENT_ID.length === 0)
          ? departmentids
          : filters?.DEPARTMENT_ID,
    };
    dispatch(getEmployeeTarget(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  //------------ASSET  MASTER ------------
  const fetchData9 = useCallback(() => {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
    const departmentids =
      filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
    const updatedFilters = {
      ...filters,
      pageIndex,
      pageSize,
      DEPARTMENT_ID: departmentids,
      BRANCH_ID:
        filters?.BRANCH_ID == null ||
        (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
          ? branchIds
          : filters?.BRANCH_ID,
    };
    dispatch(getEmployeeTarget(updatedFilters));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    if (ROLE_ID == 10) {
      fetchData10();
    } else if (ROLE_ID == 9) {
      fetchData9();
    } else {
      fetchData();
    }
  }, [pageIndex, pageSize]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    if (dialog === false) {
      if (ROLE_ID == 10) {
        setFilters({
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
          MONTH: null,
          YEAR: null,
        });
        dispatch(
          getEmployeeTarget({
            pageIndex,
            pageSize,
            BRANCH_ID: BRANCH_ID,
            DEPARTMENT_ID: DEPARTMENT_ID,
          })
        );
      } else if (ROLE_ID == 9) {
        const filterData = JSON.parse(localStorage.getItem("filterData"));
        const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
        const departmentids =
          filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);
        setFilters({
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
          MONTH: null,
          YEAR: null,
        });
        dispatch(
          getEmployeeTarget({
            pageIndex,
            pageSize,
            BRANCH_ID: branchIds,
            DEPARTMENT_ID: departmentids,
          })
        );
      } else {
        setFilters({
          DEPARTMENT_ID: null,
          BRANCH_ID: null,
          MONTH: null,
          YEAR: null,
        });

        dispatch(getEmployeeTarget({ pageIndex, pageSize }));
      }
    }
  }, [dialog]);

  //    --------------------- ROLE ID END ----------------------------

  //------------- filter ---------------------

  const [filters, setFilters] = useState({
    DEPARTMENT_ID: null,
    BRANCH_ID: null,
    MONTH: null,
    YEAR: null,
  });

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const branch = useSelector(
    (state) => state?.employeetarget?.data?.branchList?.data
  );
  const department = useSelector(
    (state) => state?.employeetarget?.data?.departmentList?.data
  );

  useEffect(() => {
    if (ROLE_ID == 10) {
      dispatch(getDepartment({ DEPARTMENT_ID: DEPARTMENT_ID }));
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);

      dispatch(getBranch({ BRANCH_ID: branchIds }));
    } else {
      dispatch(getBranch());
      dispatch(getDepartment());
    }
  }, [ROLE_ID]);

  const handleSubmit = (filters) => {
    if (ROLE_ID == 10) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        BRANCH_ID: branchIds,
        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? departmentids
            : filters?.DEPARTMENT_ID,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));
      dispatch(getEmployeeTarget(updatedFilters));
    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);

      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,
        DEPARTMENT_ID: departmentids,
        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? branchIds
            : filters?.BRANCH_ID,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));

      dispatch(getEmployeeTarget(updatedFilters));
    } else {
      const updatedFilters = {
        ...filters,
        pageIndex: 1,
        pageSize,

        DEPARTMENT_ID:
          filters?.DEPARTMENT_ID == null ||
          (Array.isArray(filters?.DEPARTMENT_ID) &&
            filters?.DEPARTMENT_ID.length === 0)
            ? null
            : filters?.DEPARTMENT_ID,

        BRANCH_ID:
          filters?.BRANCH_ID == null ||
          (Array.isArray(filters?.BRANCH_ID) && filters?.BRANCH_ID.length === 0)
            ? null
            : filters?.BRANCH_ID,
      };
      dispatch(setTableData({ pageIndex: 1, pageSize }));
      dispatch(getEmployeeTarget(updatedFilters));
    }
  };

  return (
    <>
      <style>
        {`
        .custom-select .ant-select-selector {
          height: auto !important;
          min-height: 2.75rem; /* min-height equivalent to Tailwind's h-11 */
          max-height: 5rem; /* Adjust as needed */
          overflow-y: auto; /* Scrollbar if content exceeds max-height */
        }

        .custom-select .ant-select-selection-item {
          white-space: normal;
        }

        .custom-select .ant-select-dropdown {
          max-height: 15rem; /* Adjust dropdown max height as needed */
          overflow-y: auto; /* Scrollbar for dropdown items */
        }
      `}
      </style>

      <div className="filter mb-4">
        {/*------------- BRANCH MATSR IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ START  --------- */}

        {dialog &&
          (ROLE_ID == 10 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Department Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Department"
                      onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                    >
                      {department?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.DEPARTMENT}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Month
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Month"
                      onChange={(value) => handleChange("MONTH", value)}
                    >
                      <option value="" disabled>
                        Month
                      </option>
                      {monthNames.map((x, index) => (
                        <option key={index + 1} value={index + 1}>
                          {x}{" "}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Year
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Year"
                      onChange={(value) => handleChange("YEAR", value)}
                    >
                      <option value="" disabled>
                        Year
                      </option>
                      {years.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end mt-4 ">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500 "
                    onClick={() => {
                      setFilters({
                        DEPARTMENT_ID: null,
                        BRANCH_ID: null,
                        MONTH: null,
                        YEAR: null,
                      });
                      dispatch(toggleFilter(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==10 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*------------- Area Manager IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------  START  --------- */}

        {dialog &&
          (ROLE_ID == 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Branch Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Branch"
                      onChange={(value) => handleChange("BRANCH_ID", value)}
                    >
                      {branch?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Month
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Month"
                      onChange={(value) => handleChange("MONTH", value)}
                    >
                      <option value="" disabled>
                        Month
                      </option>
                      {monthNames.map((x, index) => (
                        <option key={index + 1} value={index + 1}>
                          {x}{" "}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Year
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Year"
                      onChange={(value) => handleChange("YEAR", value)}
                    >
                      <option value="" disabled>
                        Year
                      </option>
                      {years.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end mt-4 ">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500 "
                    onClick={() => {
                      setFilters({
                        DEPARTMENT_ID: null,
                        BRANCH_ID: null,
                        MONTH: null,
                        YEAR: null,
                      });

                      dispatch(toggleFilter(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*------------- IF ROLE_ID==9 ,TOGGLE BOX WITHE 3 BOXES ------------ END  --------- */}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ START  ------- */}
        {dialog &&
          (ROLE_ID != 10 && ROLE_ID != 9 ? (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4  ">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Branch Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Branch"
                      onChange={(value) => handleChange("BRANCH_ID", value)}
                    >
                      {branch?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Department Name
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Department"
                      onChange={(value) => handleChange("DEPARTMENT_ID", value)}
                    >
                      {department?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.DEPARTMENT}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Month
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Month"
                      onChange={(value) => handleChange("MONTH", value)}
                    >
                      <option value="" disabled>
                        Month
                      </option>
                      {monthNames.map((x, index) => (
                        <option key={index + 1} value={index + 1}>
                          {x}{" "}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Year
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      // mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Year"
                      onChange={(value) => handleChange("YEAR", value)}
                    >
                      <option value="" disabled>
                        Year
                      </option>
                      {years.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end mt-4 ">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500 "
                    onClick={() => {
                      setFilters({
                        DEPARTMENT_ID: null,
                        BRANCH_ID: null,
                        MONTH: null,
                        YEAR: null,
                      });
                      dispatch(toggleFilter(false));
                      dispatch(setTableData({ pageIndex: 1, pageSize }));
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null)}

        {/*-------------NORMAL TOGGLE BOX WITHE 4 BOXES ------------ END  ------- */}
      </div>
    </>
  );
};

export default EmployeeTargetFilter;
