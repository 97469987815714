import ApiService from "./ApiService";

export async function apiGetAllDesignation(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/designation/get",
    method: "post",
    data,
  });
}
export async function apipostDesignation(data) {
  return ApiService.fetchData({
    url: "/api/designation/create",
    method: "post",
    data,
  });
}

export async function apiputDesignation(data) {
  return ApiService.fetchData({
    url: "/api/designation/update",
    method: "put",
    data,
  });
}
