import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, Switch, notification } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postDesignation,
  putDesignation,
  getDesignation,
} from "../store/dataSlice";

const { TextArea } = Input;

const DesignationForm = () => {
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedDesignation = useSelector(
    (state) => state.designation.state.selectedDesignation
  );

  const validationSchema = Yup.object({
    DESIGNATION: Yup.string().required("Required"),
    STATUS: Yup.boolean().required("Required"),
    DESCRIPTION: Yup.string().required("Required"),

    // status: Yup.boolean().required("Required"),
  });

  useEffect(() => {
    if (selectedDesignation) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedDesignation]);

  const initialValues = selectedDesignation || {
    DESIGNATION: "",
    STATUS: true,
    DESCRIPTION: "",
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const action = edit
        ? await dispatch(putDesignation(values))
        : await dispatch(postDesignation(values));
      if (action.payload.code < 300) {
        dispatch(getDesignation());
        dispatch(toggleNewDialog(false));
        setLoading(false);

        api.success({
          message: "Form Submitted Successfully.",
          duration: 1,
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            isSubmitting,
            submitForm,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DESIGNATION && errors.DESIGNATION
                        ? errors.DESIGNATION
                        : ""
                    }
                    validateStatus={
                      touched.DESIGNATION && errors.DESIGNATION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs"> Designation Name</span>
                    {/* {JSON.stringify(values.DESIGNATION)} */}
                    <Input
                      name="DESIGNATION"
                      placeholder="Designation Name.."
                      value={values.DESIGNATION}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? errors.DESCRIPTION
                        : ""
                    }
                    validateStatus={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Description</span>

                    <TextArea
                      rows={4}
                      placeholder="You Can Add Maximum 512 Characters.."
                      maxLength={6000}
                      value={values.DESCRIPTION}
                      onChange={(e) =>
                        setFieldValue("DESCRIPTION", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs ">Status</span>

                    <div style={{ marginTop: 8 }}>
                      <Switch
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        defaultChecked
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      className="mr-4 !py-4 !px-6 border !rounded border-blue-500 text-blue-500"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="!py-4 !px-6 !rounded"
                      disabled={isSubmitting}
                      loading={loading}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DesignationForm;
