import ApiService from "./ApiService";

export async function apiGetAllDistributor(data) {
  // ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/distributor/get",
    method: "post",
    data,
  });
}

export async function apipostDistributor(data) {
  return ApiService.fetchData({
    url: "/api/distributor/create",
    method: "post",
    data,
  });
}

export async function apiputDistributor(data) {
  return ApiService.fetchData({
    url: "/api/distributor/update",
    method: "put",
    data,
  });
}

export async function apigetGstType(data) {
  return ApiService.fetchData({
    url: "/api/gstType/get",
    method: "post",
    data,
  });
}

export async function apiGetState(data) {
  return ApiService.fetchData({
    url: "/api/state/getStateData",
    method: "post",
    data,
  });
}
