import ApiService from "./ApiService";

export async function apiGetAllCustomer(data) {
  return ApiService.fetchData({
    url: "/api/customer/get",
    method: "post",
    data,
  });
}

export async function apiGetAllCustomerDetails(data) {
  return ApiService.fetchData({
    url: "/api/serviceInterest/get",
    method: "post",
    data,
  });
}

export async function apiPostAllCustomer(data) {
  return ApiService.fetchData({
    url: "/api/customer/post",
    method: "post",
    data,
  });
}

export async function apiPutAllCustomer(data) {
  return ApiService.fetchData({
    url: "/api/customer/put",
    method: "put",
    data,
  });
}

export async function apiGetBranch(data) {
  return ApiService.fetchData({
    url: "/api/branch/getBranchData",
    method: "post",
    data,
  });
}

export async function apiGetEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}

export async function apiGetDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    data,
  });
}

export async function apiGetAllService(data) {
  return ApiService.fetchData({
    url: "/api/service/get",
    method: "post",
    data,
  });
}
