import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllEom,
  apipostEom,
  apiputEom,
} from "../../../services/EOMService";

export const getEom = createAsyncThunk("eom/data/getEom", async (data) => {
  const response = await apiGetAllEom(data);
  return response.data;
});
export const postEom = createAsyncThunk("eom/data/postEom", async (data) => {
  const response = await apipostEom(data);
  return response.data;
});
export const putEom = createAsyncThunk("eom/data/putEom", async (data) => {
  const response = await apiputEom(data);
  //console.log(response);
  return response.data;
});

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "eomList/data",
  initialState: {
    loading: false,
    eomList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.eomList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEom.fulfilled, (state, action) => {
        state.loading = false;
        state.eomList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getEom.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEom.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(postEom.fulfilled, (state, action) => {})
      .addCase(putEom.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
