import ApiService from "./ApiService";

export async function apiGetAllAccountgroup(data) {
  ////console.log("params", data);
  return ApiService.fetchData({
    url: "/api/accountGroup/get",
    method: "post",
    data,
  });
}
export async function apipostAccountgroup(data) {
  return ApiService.fetchData({
    url: "/api/accountGroup/create",
    method: "post",
    data,
  });
}

export async function apiputAccountgroup(data) {
  return ApiService.fetchData({
    url: "/api/accountGroup/update",
    method: "put",
    data,
  });
}
