import ApiService from "./ApiService";

export async function apiGetLeave(data) {
  return ApiService.fetchData({
    url: "/api/leave/get",
    method: "post",
    data,
  });
}

export async function apiGetDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/get",
    method: "post",
    data,
  });
}

export async function apiGetBranch(data) {
  return ApiService.fetchData({
    url: "/api/branch/get",
    method: "post",
    data,
  });
}

export async function apiGetEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/get",
    method: "post",
    data,
  });
}


export async function apiGetApproveLeave(data) {
  return ApiService.fetchData({
    url: "/api/leave/approveLeave",
    method: "post",
    data,
  });
}



export async function apiGetRejectLeave(data) {
  return ApiService.fetchData({
    url: "/api/leave/rejectLeave",
    method: "post",
    data,
  });
}

export async function apiGetLeaveDataForAreaManager(data) {
  return ApiService.fetchData({
    url: "/api/leave/getLeaveDataForAreaManager",
    method: "post",
    data,
  });
}






