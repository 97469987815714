import React, { useEffect, useState } from "react";
import { Menu, Flex, notification, Modal } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenu } from "./store/dataSlice";
import { injectReducer } from "../../store";
import Menureducer from "./store";
import logo from "../../assets/images/logo/sbm2.jpg";

import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as IoCalendarNumber from "react-icons/io5";
import * as IoCalendarNumberSharp from "react-icons/io5";
import * as GrIcons from "react-icons/gr";
import * as FaIcons from "react-icons/fa";
import * as Fa6Icons from "react-icons/fa6";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";
import * as AiFillProduct from "react-icons/ai";

// import * as Icons from "react-icons/io";

injectReducer("menu", Menureducer);

const Icons = {
  ...IoIcons,
  ...BsIcons,
  ...GrIcons,
  ...FaIcons,
  ...RiIcons,
  ...Fa6Icons,
  ...BiIcons,
  ...MdIcons,
  ...FcIcons,
  ...IoCalendarNumber,
  ...IoCalendarNumberSharp,
  ...AiFillProduct,
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [api, contextHolder] = notification.useNotification();
  const data = useSelector((state) => state.menu.data.menuList.data);

  const filterData = data?.filter((item) => item.show_in_menu === 1);
  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const savedRoleId4 = localStorage.getItem("ROLE_ID");
  const filterDatas = JSON.parse(localStorage.getItem("filterData") || "[]");

  const generateMenuItems = (data) => {
    const sortedData = data
      ? [...data].sort((a, b) => a.SEQ_NO - b.SEQ_NO)
      : [];

    if (savedRoleId4 == 9 || savedRoleId4 == 10) {
      if (filterDatas.length === 0) {
        return null;
      }
    }

    return sortedData?.map((item) => {
      let IconComponent = null;
      if (item.icon) {
        IconComponent = Icons[item.icon];
      }
      if (item.children && item.children.length > 0) {
        return {
          key: item.title,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${selectedKey === item.link ? "selected" : ""
                }`}
            />
          ) : null,
          children: generateMenuItems(item.children),
        };
      } else {
        return {
          key: item.link,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${selectedKey === item.link ? "selected" : ""
                }`}
            />
          ) : null,
        };
      }
    });
  };

  const showAccessDeniedModal2 = () => {
    Modal.error({
      title: "Access Denied",
      content:
        "You do not have access to this role. because branch and department is not assigned to you",
      onOk: () => {
        // localStorage.removeItem("UserData");
        // localStorage.removeItem("ROLE_ID");
        navigate("/login");
      },
    });
  };

  useEffect(() => {
    if (savedRoleId4 == 9 || savedRoleId4 == 10) {
      if (filterDatas.length === 0) {
        showAccessDeniedModal2();
      } else {
        console.log("filterData list is not empty");
      }
    }
  }, [savedRoleId4, generateMenuItems(data)]);

  const checkAuthorizedRoute = async (currentUrlPath) => {
    const roleId = localStorage.getItem("ROLE_ID");
    const userData = localStorage.getItem("UserData");
    const userData2 = JSON.parse(userData);
    const EMP_ID = userData2?.[0]?.EMP_ID;

    console.log("role id from sidebar", roleId);
    const menuResponse = await dispatch(
      getMenu({ ROLE_ID: roleId, EMP_ID })
    ).unwrap();
    const menuData = menuResponse.data;

    //console.log("menuData", menuData);
    const findMatchingRoute = (items) => {
      if (Array.isArray(items)) {
        for (const item of items) {
          if (item.link === currentUrlPath) {
            return item;
          } else if (item.link === "#" && item.children) {
            const childMatch = findMatchingRoute(item.children);
            if (childMatch) {
              return childMatch;
            }
          }
        }
      }
      return null;
    };

    const matchingRoute = findMatchingRoute(menuData);
    // //console.log("matchingRoute", matchingRoute);

    if (!matchingRoute) {

      if (savedRoleId4 == 9 || savedRoleId4 == 10) {
        if (filterDatas.length === 0) {
          return navigate("/login");
        }
        else {
          localStorage.removeItem("token");
          localStorage.removeItem("UserData"); // imp dont know what does
          // window.location.href = "/login";
          return navigate("/login");
        }


      }

      localStorage.removeItem("token");
      localStorage.removeItem("UserData"); // imp dont know what does
      // window.location.href = "/login";
      return navigate("/login");



    }
  };

  useEffect(() => {
    const currentUrlPath = window.location.pathname;

    checkAuthorizedRoute(currentUrlPath);
  }, [window.location.pathname]);

  return (
    <>
      {contextHolder}
      <Flex align="center" justify="center">
        <div className="logo ">
          <img src={logo} width={150} alt="" className="mt-2  " />
        </div>
      </Flex>

      <Menu
        mode="inline"
        className="menu-bar"
        selectedKeys={[selectedKey]}
        // style={{ width: 300 }}
        onClick={(items) => {
          setSelectedKey(items.key);
          navigate(items.key);
        }}
        items={generateMenuItems(filterData)}
      />
    </>
  );
};
