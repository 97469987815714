import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch, Spin } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrganisation,
  putOrganisation,
  getStateId,
  getImages,
  setTableData,
} from "../store/dataSlice";
import {
  setSelectedOrganisation,
  setSelectedOrganisationImage,
  toggleNewDialog,
  toggleImageBox,
} from "../store/stateSlice";
import { FaImage, FaRegImage } from "react-icons/fa6";
import BaseService from "../../../services/BaseService";

import axios from "axios";

const OrganisationTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.organisation.data.loading);

  const data = useSelector(
    (state) => state?.organisation?.data?.organisationList?.data
  );

  // ////console.log(data);

  const onEdit = (record) => {
    dispatch(setSelectedOrganisation(record));
    dispatch(toggleNewDialog(true));
  };

  const onEditImage = (record) => {
    dispatch(setSelectedOrganisationImage(record));
    dispatch(toggleImageBox(true));
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putOrganisation(updatedRecord));
    dispatch(getOrganisation());
  };

  useEffect(() => {
    dispatch(getStateId());
  }, []);

  useEffect(() => {
    dispatch(getImages());
  }, []);

  // ////console.log("sdssssssssss", BaseService.apikey);
  // const imgs = useSelector((state) => state?.organisation?.data?.imageList?.data);
  // ////console.log("images = ", imgs);

  // const [imageUrls, setImageUrls] = useState({});

  // const fetchImage = async (imageName) => {
  //     ////console.log("image name = ", imageName)
  //     const config = {
  //         headers: {
  //             apikey: "hjh4653dsiivy457468asdfe",
  //             token: localStorage.getItem("user"),
  //         },
  //         responseType: 'blob',
  //     };

  //     try {
  //         const response = await axios.get(
  //             `${BaseService.defaults.baseURL}/static/orgImg/${imageName}`,
  //             config
  //         );

  //         const url = URL.createObjectURL(response.data);
  //         ////console.log("image response = ", response)

  //         setImageUrls((prevUrls) => ({ ...prevUrls, [imageName]: url }));
  //     } catch (error) {
  //         console.error("Error fetching image:", error);
  //     }
  // };

  // useEffect(() => {
  //     if (data) {
  //         data.forEach((record) => {

  //             if (record.ORG_IMG && !imageUrls[record.ORG_IMG]) {
  //                 fetchImage(record.ORG_IMG);
  //             }
  //         });
  //     }
  // }, [data]);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.organisation.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getOrganisation({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 130, // Adjusted to ensure the title fits
      render: (_, record) => (
        <div className="flex items-center">
          <span
            onClick={() => onEdit(record)}
            className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
          >
            <MdEdit />
          </span>

          <span
            onClick={() => onEditImage(record.ORG_IMG)}
            className="text-2xl ml-8 text-red-500 cursor-pointer"
          >
            <FaRegImage />
          </span>
        </div>
      ),
    },

    // {
    //     title: <span className="text-gray-500">Image</span>,
    //     dataIndex: "ORG_IMG",
    //     render: (ORG_IMG) => (
    //         imageUrls[ORG_IMG] ? (
    //             <img
    //                 src={imageUrls[ORG_IMG]}
    //                 alt="Organisation"
    //                 style={{ width: 50, height: 50, objectFit: "cover" }}
    //             />
    //         ) : (
    //             'Loading...'
    //         )
    //     ),
    //     width: 100,
    //     ellipsis: true,
    // },

    {
      title: <span className="text-gray-500">Organisation Name</span>,
      dataIndex: "ORGANISATION",
      width: 180, // Adjusted to ensure the title fits
      ellipsis: true,
    },

    {
      title: <span className="text-gray-500">GST No</span>,
      dataIndex: "GST_NO",
      width: 150, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">Registration No</span>,
      dataIndex: "REGISTRATION_NO",
      width: 150, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">Address</span>,
      dataIndex: "ADDRESS",
      width: 250, // Adjusted to ensure the title fits
    },
    {
      title: <span className="text-gray-500">City</span>,
      dataIndex: "CITY",
      width: 150, // Adjusted to ensure the title fits
    },
    {
      title: <span className="text-gray-500">Tehsil</span>,
      dataIndex: "TEHSIL",
      width: 110, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">District</span>,
      dataIndex: "DISTRICT",
      width: 100, // Adjusted to ensure the title fits
      ellipsis: true,
    },

    // {
    //     title: <span className="text-gray-500">Status</span>,
    //     dataIndex: "STATUS",
    //     width: 100, // Adjusted to ensure the title fits
    //     render: (text) => (
    //         <span>{text === 1 ? "Active" : "Inactive"}</span>
    //     ),
    //     ellipsis: true,
    // },

    // {
    //     title: <span className="text-gray-500">State Id</span>,
    //     dataIndex: "STATE_ID",
    //     width: 100, // Adjusted to ensure the title fits
    //     ellipsis: true,
    // },

    {
      title: <span className="text-gray-500">State</span>,
      dataIndex: "STATE_NAME",
      width: 150,
      // render: (i) => {

      //     const state = stateType?.find((state) => state.ID === i);
      //     return (
      //         <span>{state ? state.NAME : 'Unknown'}</span>
      //     );
      // },
      ellipsis: true,
    },

    {
      title: <span className="text-gray-500">Country</span>,
      dataIndex: "COUNTRY",
      width: 150, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">Pincode</span>,
      dataIndex: "PINCODE",
      width: 150, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">Mobile No</span>,
      dataIndex: "MOBILE_NO",
      width: 150, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    {
      title: <span className="text-gray-500">Email </span>,
      dataIndex: "EMAIL_ID",
      width: 250, // Adjusted to ensure the title fits
      ellipsis: true,
    },
    // {
    //     title: <span className="text-gray-500">Status</span>,
    //     dataIndex: "STATUS",
    //     width: 100, // Adjusted to ensure the title fits
    //     render: (text) => (
    //         <span>{text === 1 ? "Active" : "Inactive"}</span>
    //     ),
    //     ellipsis: true,
    // },

    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{
            x: 1300,
          }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onChange: handleTableChange,
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      )}
    </>
  );
};

export default OrganisationTable;
