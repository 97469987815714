import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllEmployeeTarget,
  apipostEmployeeTarget,
  apipostEmployeeTarInner,
  apiputEmployeeTarget,
  apiGetAllBranch,
  apiGetAllDepartment,
} from "../../../services/EmployeeTargetMappingService";

export const getBranch = createAsyncThunk(
  "employeetarget/data/getBranch",
  async (data) => {
    const response = await apiGetAllBranch(data);
    return response.data;
  }
);

export const getDepartment = createAsyncThunk(
  "employeetarget/data/getDepartment",
  async (data) => {
    const response = await apiGetAllDepartment(data);
    return response.data;
  }
);

export const getEmployeeTargetInner = createAsyncThunk(
  "employeetarget/data/getEmployeeTargetInner",
  async (data) => {
    const response = await apipostEmployeeTarInner(data);
    return response.data;
  }
);
export const getEmployeeTarget = createAsyncThunk(
  "employeetarget/data/getEmployeeTarget",
  async (data) => {
    const response = await apiGetAllEmployeeTarget(data);
    return response.data;
  }
);
export const postEmployeeTarget = createAsyncThunk(
  "employeetarget/data/postEmployeeTarget",
  async (data) => {
    const response = await apipostEmployeeTarget(data);
    return response.data;
  }
);

export const putEmployeeTarget = createAsyncThunk(
  "employeetarget/data/putEmployeeTarget",
  async (data) => {
    const response = await apiputEmployeeTarget(data);
    ////console.log(response);
    return response.data;
  }
);

export const rrinitialTableDataInneree = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};
export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "employeetargetList/data",
  initialState: {
    loading: false,
    loadinginner: false,

    employeetargetList: [],
    branchList: [],
    departmentList: [],
    employeetargetInnerList: [],
    tableDataInner: rrinitialTableDataInneree,
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.employeetargetList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },

    setTableDataInner: (state, action) => {
      state.tableDataInner = { ...state.tableDataInner, ...action.payload };
    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeTarget.fulfilled, (state, action) => {
        state.loading = false;
        state.employeetargetList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getEmployeeTarget.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmployeeTarget.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getEmployeeTargetInner.fulfilled, (state, action) => {
        state.loadinginner = false;
        state.employeetargetInnerList = action.payload;
        state.tableDataInner = state.tableDataInner || {};
        state.tableDataInner.total = action.payload.count;
      })
      .addCase(getEmployeeTargetInner.pending, (state) => {
        state.loadinginner = true;
      })
      .addCase(getEmployeeTargetInner.rejected, (state) => {
        state.loadinginner = false;
      })

      .addCase(getBranch.fulfilled, (state, action) => {
        state.branchList = action.payload;
      })

      .addCase(getDepartment.fulfilled, (state, action) => {
        state.departmentList = action.payload;
      })
      .addCase(postEmployeeTarget.fulfilled, (state, action) => {})
      .addCase(putEmployeeTarget.fulfilled, (state, action) => {});
  },
});

export const {
  updateProductList,
  setTableDataInner,
  setTableData,
  setFilterData,
} = dataSlice.actions;

export default dataSlice.reducer;
