import React, { useEffect } from "react";

import { Button, Modal, notification } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import PriceForm from "./Components/PriceForm";
import PriceTable from "./Components/PriceTable";
import { injectReducer } from "../../store";
import Pricereducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedPrice } from "./store/stateSlice";
import { getPrice, getPriceTable } from "./store/dataSlice";
import PriceMainSearch from "./Components/PriceMainSearch";

injectReducer("price", Pricereducer);

const Price = () => {
  const [api, contextHolder] = notification.useNotification();

  const dialog = useSelector((state) => state.price.state.newDialog);

  const AddEdit = useSelector((state) => state.price.state.selectedPrice);
  const aaa = String(dialog);

  const dispatch = useDispatch();

  // const onDialog = () => {

  //   dispatch(getPrice());

  //   dispatch(setSelectedPrice(null));
  //   dispatch(toggleNewDialog(true));

  // };

  const onDialog = async () => {
    const action = await dispatch(getPrice());
    const { payload } = action;

    if (payload && payload.code === 302) {
      ////console.log("error");
      api.info({
        message: "Already generated",
        description:
          "The price list for this month has already been submitted.",
      });
    } else {
      dispatch(setSelectedPrice(null));
      dispatch(toggleNewDialog(true));
    }
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleRefresh = () => {
    dispatch(getPrice());
  };

  useEffect(() => {
    handleRefresh();
  }, [dispatch]);

  return (
    <>
      {contextHolder}

      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            Monthly Price List
          </div>
          <div className="flex">
            <div className="flex items-center">
              <PriceMainSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Price</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Price" : "Add New Price"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            width={1000}
            onCancel={handleCloseModal}
          >
            <PriceForm />
          </Modal>
        </div>

        <PriceTable />
      </div>
    </>
  );
};

export default Price;
