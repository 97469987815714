import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "employeeList/state",
  initialState: {
    deleteConfirmation: false,
    selectedEmployee: "",
    newDialog: false,
    filterDialog: false,
    selectedImage1: '',
    imageDialog: false,
    filterToggle: true,
    switchHappen: '',
    filters: {
      ORG_ID: null,
      DEPARTMENT_ID: null,
      BRANCH_ID: null,
      DESIGNATION_ID: null,
      STATUS: null,
      SEARCH_FILTER: null,
    },

  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setSwitch: (state, action) => {
      state.switchHappen = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    toggleFilterDialog: (state, action) => {
      state.filterDialog = action.payload;
    },
    setSelectedImage1: (state, action) => {
      state.selectedImage1 = action.payload
    },
    toggleImageBox: (state, action) => {
      state.imageDialog = action.payload
    },
    toggleFilter: (state, action) => {
      state.filterToggle = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },

  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedEmployee,
  toggleNewDialog,
  toggleFilterDialog,
  setSelectedImage1,
  toggleImageBox,
  toggleFilter,
  setSwitch,
  setFilters,
} = stateSlice.actions;

export default stateSlice.reducer;
