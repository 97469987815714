import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DatePicker, Select, Spin, Table } from "antd";
import { GetEmployeetarget, setTableData } from "../store/dataSlice";
import { useDispatch } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { useLocation } from "react-router-dom";
import EmployeereportFilter from "./EmployeereportFilter";
const { RangePicker } = DatePicker;

const EmployeetargetTable = () => {
  const data = useSelector(
    (state) => state.employeereport?.data.employeereportList?.data
  );
  const loading = useSelector((state) => state.employeereport.data.loading);

  const dispatch = useDispatch();
  const location = useLocation();

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.employeereport.data?.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const columns = [
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "EMPLOYEE_NAME",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Target Amount</span>,
      dataIndex: "TOTAL_TARGET_AMOUNT",
      render: (text) => (text !== null ? text : 0),
    },
    {
      title: <span className="text-gray-500">Achievement Amount</span>,
      dataIndex: "TOTAL_AMOUNT",
    },
    {
      title: <span className="text-gray-500">Pending Target</span>,
      dataIndex: "PENDING_TARGET_AMOUNT",
      render: (text) => (text !== null ? text : 0),
    },

    {
      title: <span className="text-gray-500">Target Customer</span>,
      dataIndex: "TOTAL_TARGET_CUSTOMER",
      render: (text) => (text !== null ? text : 0),
    },
    {
      title: <span className="text-gray-500">Closed Customers </span>,
      dataIndex: "TOTAL_CUSTOMER_CREATED",
    },
  ];

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog(false));
  }, [location.pathname]);

  return (
    <>
      <div>
        <EmployeereportFilter />

        <div style={{ marginBottom: "16px" }}>
          {loading ? (
            <div className="w-full flex justify-center h-60 items-center">
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 1300 }}
              pagination={{
                current: pageIndex,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20],
                onChange: handleTableChange,
                onShowSizeChange: handleTableChange,
              }}
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeetargetTable;
