import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Button, Table } from "antd";
import { FaFilter } from "react-icons/fa";
import { toggleNewDialog2 } from "../store/stateSlice";
import * as XLSX from "xlsx";

import { Link, useNavigate } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";

import { setTableDatadetail } from "../store/dataSlice";
import SalaryslipFilter from "./SalaryslipFilter";
import SalarySlipDetailFilter from "../Components/SalarySlipDetailFilter";
const SalaryslipTable = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loadings, setLoading] = useState(false);

  const loading = useSelector((state) => state.salaryslip.data.loading);

  // Fetching salary slip data
  const data = useSelector((state) => state.salaryslip.data.detailInner?.data);
  const selectedPrice = useSelector(
    (state) => state.salaryslip.state.selectedSalarySlipinner
  );
  const dialog = useSelector((state) => state.salaryslip.state.newDialog2);

  const ExcelData = useSelector(
    (state) => state.salaryslip.data.excelList?.data
  );

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.salaryslip.data.detailTable
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableDatadetail({ pageIndex: current, pageSize }));
  };

  const dum = {
    TOTAL_PRESENT_DAYS: 7,
    TOTAL_ABSENT_DAYS: 24,
    TRAVEL_ALLOWANCE: 603.47,
    PAYABLE_SALARY: 4516.13,
    ACTUAL_PAYABLE_SALARY: 5119.6,
  };

  const exportToExcel = () => {
    setLoading(true);
    setTimeout(() => {
      if (ExcelData && ExcelData.length > 0) {
        let month, year;
        const transformedData = ExcelData.map(
          ({
            ID,
            EMP_ID,
            FIRST_NAME,
            MIDDLE_NAME,
            LAST_NAME,
            TOTAL_PRESENT_DAYS,
            TOTAL_ABSENT_DAYS,
            TRAVEL_ALLOWANCE,
            PAYABLE_SALARY,
            ACTUAL_PAYABLE_SALARY,
            REMARK,
            BRANCH_NAME,
            MONTH,
            YEAR,
            DEPARTMENT_NAME,
            ...rest
          }) => {
            month = MONTH;
            year = YEAR;
            const data = {
              "Employee Name":
                `${FIRST_NAME} ${MIDDLE_NAME} ${LAST_NAME}`.trim(),
              "Branch Name": BRANCH_NAME,
              "Department Name": DEPARTMENT_NAME,
              "Basic Salary": PAYABLE_SALARY,
              "Actual Payable Salary": ACTUAL_PAYABLE_SALARY,
              "Total Present Days": TOTAL_PRESENT_DAYS,
              "Total Absent Days": TOTAL_ABSENT_DAYS,
              "Travel Allowance": TRAVEL_ALLOWANCE,
              Remark: REMARK,
            };
            return data;
          }
        );

        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const wb = XLSX.utils.book_new();

        const ws = XLSX.utils.json_to_sheet(transformedData);

        ws["!cols"] = [
          { wch: 30 },
          { wch: 20 },
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 25 },
          { wch: 10 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 20 },
        ];

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const fileName = `${monthNames[month - 1]} ${year}.xlsx`;
        XLSX.writeFile(wb, fileName);
        setLoading(false);
      } else {
        setLoading(false);
        //console.log("No data available to export");
      }
    }, 2000);
  };

  const columns = [
    {
      title: <span className="text-gray-500">Name</span>,
      dataIndex: "Name",
      fixed: "left",
      width: 200,

      render: (_, record) =>
        `${record.FIRST_NAME} 
                
                ${record.LAST_NAME}`,
    },
    {
      title: <span className="text-gray-500">Branch </span>,
      dataIndex: "BRANCH_NAME",
      width: 130,
    },

    {
      title: <span className="text-gray-500">Department </span>,
      dataIndex: "DEPARTMENT_NAME",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Total Present Days</span>,
      dataIndex: "TOTAL_PRESENT_DAYS",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Actual Payable Salary</span>,
      dataIndex: "ACTUAL_PAYABLE_SALARY",
      width: 180,
    },
    {
      title: <span className="text-gray-500">Total Absent Days</span>,
      dataIndex: "TOTAL_ABSENT_DAYS",
    },
    {
      title: <span className="text-gray-500">Travel Allowance</span>,
      dataIndex: "TRAVEL_ALLOWANCE",
    },
    {
      title: <span className="text-gray-500">Payable Salary</span>,
      dataIndex: "PAYABLE_SALARY",
    },
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog2(false));
    } else {
      dispatch(toggleNewDialog2(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl   !text-[#414141]">
            <span className="font-bold ">Salary List Of -</span>

            <span className="ml-3">
              {months[selectedPrice.MONTH]} {JSON.stringify(selectedPrice.YEAR)}
            </span>
          </div>
          <div className="flex">
            <div className="flex items-center">
              {/* <PriceDetailSearch /> */}
              <div
                className={`bg-[#096CAE] p-[9px] rounded text-xl mr-4 text-white cursor-pointer ${
                  loadings ? "cursor-wait" : "cursor-pointer"
                }`}
                onClick={exportToExcel}
              >
                <SiMicrosoftexcel />
              </div>
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>

              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={() => navigate("/salaryslip")}
              >
                <RiArrowGoBackFill />

                <p>Go Back</p>
              </Button>
            </div>
          </div>
        </div>

        <SalarySlipDetailFilter />

        <div>
          <SalaryslipFilter />
          <div style={{ marginBottom: "16px" }}>
            {loading ? (
              <div className="w-full flex justify-center h-60 items-center">
                <Spin size="large" />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                bordered
                scroll={{ x: 1300 }}
                pagination={{
                  current: pageIndex,
                  pageSize: pageSize,
                  total: total,
                  showSizeChanger: true,
                  pageSizeOptions: [5, 10, 20],
                  onChange: handleTableChange,
                  onShowSizeChange: handleTableChange,
                }}
                onChange={handleTableChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalaryslipTable;
