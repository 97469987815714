import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, setTableData } from "../store/dataSlice";

import { setSelectedOrder, toggleNewDialog } from "../store/stateSlice";
const OrderTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.order.data.tableData
  );

  const fetchData = useCallback(() => {
    dispatch(getOrder({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const data = useSelector((state) => state.order.data.orderList.data);

  ////console.log(data);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const onEdit = (record) => {
    dispatch(setSelectedOrder(record));
    dispatch(toggleNewDialog(true));
  };

  const onOrderNumberClick = (record) => {
    navigate("/order/details", { state: { record } });
  };

  const columns = [
    {
      title: <span className="text-gray-500">Order No.</span>,
      dataIndex: "ORDER_NO",
      width: 120,

      fixed: "left",
      align: "center",
      render: (text, record) => (
        <span
          className="text-gray-500 font-semibold"
          onClick={() => onOrderNumberClick(record)}
          style={{ cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className="text-gray-500">Retailer Name</span>,
      width: 170,
      dataIndex: "DISTRIBUTOR_NAME",

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-500">Order Date</span>,
      dataIndex: "ORDER_DATE",
      width: 170,

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-500">Order Status</span>,
      dataIndex: "ORDER_STATUS",
      width: 140,

      render: (text) => {
        let style;
        let displayText;

        if (text === "P") {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Pending";
        } else if (text === "A") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Approve";
        } else if (text === "R") {
          style = {
            color: "#ff6b6b",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Rejected";
        }

        return <span style={style}>{displayText}</span>;
      },
    },
    {
      title: <span className="text-gray-500">Current Stage</span>,
      dataIndex: "CURRENT_STAGE",
      width: 170,
      render: (text) => {
        let style;
        let displayText;

        if (text === "P") {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Pending";
        } else if (text === "AC") {
          style = {
            color: "#4caf50",
            backgroundColor: "#e8f5e9",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
            fontSize: "10px",
          };
          displayText = "Approved by Accountant";
        } else if (text === "AH") {
          style = {
            color: "#2196f3",
            backgroundColor: "#e3f2fd",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
            fontSize: "10px",
          };
          displayText = "Approved by HR";
        } else if (text === "D") {
          style = {
            color: "#ff9800",
            backgroundColor: "#fff3e0",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
            fontSize: "10px",
          };
          displayText = "Delivered";
        }

        return <span style={style}>{displayText}</span>;
      },
    },
    {
      title: <span className="text-gray-500">Bill Amount</span>,
      dataIndex: "TOTAL_AMOUNT",
      width: 120,

      render: (text) => (
        <span className="text-gray-500 font-semibold">₹{text}</span>
      ),
    },
    {
      title: <span className="text-gray-500">Destination Address</span>,
      dataIndex: "DESTINATION_ADDRESS",
      width: 170,
      render: (text) => (
        <span className="text-gray-500 font-semibold">
          {text ? text : "* Address is not available"}
        </span>
      ),
    },
    {
      title: <span className="text-gray-500">Driver Number</span>,
      dataIndex: "DRIVER_NO",
      width: 170,

      render: (text) => (
        <span className="text-gray-500 font-semibold">
          {text ? text : "* Number is not Available"}
        </span>
      ),
    },
    {
      title: <span className="text-gray-500">GR RR Number</span>,
      dataIndex: "GR_RR_NO",
      width: 170,

      render: (text) => (
        <span className="text-gray-500 font-semibold">
          {text ? text : "* Number is not Available"}
        </span>
      ),
    },
    {
      title: <span className="text-gray-500">Vehicle Number</span>,
      dataIndex: "VEHICLE_NO",
      width: 170,

      render: (text) => (
        <span className="text-gray-500 font-semibold">
          {text ? text : "* Number is not Available"}
        </span>
      ),
    },
  ];

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: 1300 }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default OrderTable;
