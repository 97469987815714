import React, { useState } from "react";
import Salaryslipreducer from "./store";
import { injectReducer } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Button, Drawer } from "antd";
import { LuPlus } from "react-icons/lu";
import { FaFilter } from "react-icons/fa";
import SalaryslipTable from "./Components/SalaryslipTable";
import {
  toggleNewDialog,
  setSelectedSalarySlip,
  toggleDrower,
} from "./store/stateSlice";
import GenerateSalarySlipDrawer from "./Components/GenerateSalarySlipDrawer";

injectReducer("salaryslip", Salaryslipreducer);

const Salaryslip = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.salaryslip.state.newDialog);
  const drawerOpen = useSelector(
    (state) => state.salaryslip.state.drowerDialog
  );

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  const onDialog = () => {
    dispatch(setSelectedSalarySlip(null));
    dispatch(toggleDrower(true));
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">Salary Slip</div>
          <div className="flex">
            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Generate Salary Slip</p>
              </Button>
            </div>
          </div>
        </div>

        <SalaryslipTable />
      </div>
      <Drawer
        title="Generate Salary Slip"
        width={1400}
        // onClose={onClose}
        onClose={() => dispatch(toggleDrower(false))}
        open={drawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <GenerateSalarySlipDrawer />
      </Drawer>
    </>
  );
};

export default Salaryslip;
