import React from "react";
import { Button, Modal } from "antd";
const AttachmentModal = ({ isVisible, record, onClose }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  ////console.log(baseURL);
  const attachmentUrl = record
    ? `${baseURL}/static/taskAttachments/${record.ATTACHMENT}`
    : null;

  //console.log(attachmentUrl);
  const handleDownload = () => {
    if (attachmentUrl) {
      const blob = new Blob([attachmentUrl], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = record.ATTACHMENT;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const isPdf = attachmentUrl && attachmentUrl.endsWith(".pdf");

  return (
    <>
      <Modal
        title="Attachment"
        open={isVisible}
        footer={null}
        onCancel={onClose}
        width={700}
        style={{ top: "5%" }}
      >
        {record?.ATTACHMENT ? (
          <>
            <div
              style={{
                padding: "20px",
                height: "calc(80vh - 70px)",
                overflowY: "auto",
              }}
            >
              {isPdf ? (
                <iframe
                  src={attachmentUrl}
                  title="PDF Attachment"
                  style={{ width: "100%", height: "100%", border: "none" }}
                />
              ) : (
                <img
                  src={attachmentUrl}
                  alt="Attachment"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="w-full pt-5 px-5 flex justify-end">
              <Button
                type="primary"
                onClick={handleDownload}
                style={{
                  padding: "20px",
                  background: "#096CAE",
                }}
              >
                Download Attachment
              </Button>
            </div>
          </>
        ) : (
          <div>No attachment available</div>
        )}
      </Modal>
    </>
  );
};

export default AttachmentModal;
