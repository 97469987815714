import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "customerList/state",
  initialState: {
    deleteConfirmation: false,
    selectedDistributor: "",
    newDialog: false,
    counter: 0,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedDistributor = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    increaseCounter: (state, action) => {
      state.counter = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedCustomer,
  toggleNewDialog,
  increaseCounter,
} = stateSlice.actions;

export default stateSlice.reducer;
