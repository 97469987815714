import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Input,
  Form,
  Row,
  Col,
  Button,
  Switch,
  Select,
  Radio,
  notification,
} from "antd";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postNotification,
  putNotification,
  getNotification,
} from "../store/dataSlice";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const NotificationForm = ({ handleRefresh }) => {
  const [api, contextHolder] = notification.useNotification();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedNotification = useSelector(
    (state) => state.Notification.state.selectedNotification
  );
  const NotificationList = useSelector(
    (state) => state.Notification.data.NotificationList.data
  );
  const employeeIdList = useSelector(
    (state) => state.employeereport.data.employeeIdList.data
  );

  const validationSchema = Yup.object({
    STATUS: Yup.boolean().required("Required"),
    TITLE: Yup.string().required("Required"),
    // SENDER_EMP_ID: Yup.string().required("Required"),
    //RECEIVER_EMP_ID: Yup.string().required("Required"),
    READ_STATUS: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (selectedNotification) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedNotification]);

  const initialValues = selectedNotification || {
    STATUS: true,
    TITLE: "",
    SENDER_EMP_ID: "",
    RECEIVER_EMP_ID: "",
    READ_STATUS: "U",
  };
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails?.[0]?.EMP_ID;
  //console.log("Empid", EmpId);

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data);
  // this line is important dont remove it
  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log("Form values:", values);
    try {
      const monthNumber = new Date(values.MONTH).getMonth() + 1;
      const actionValues = {
        ...values,
        MONTH: monthNumber,
        SENDER_EMP_ID: EmpId,
      };
      const action = edit
        ? await dispatch(putNotification(actionValues))
        : await dispatch(postNotification(actionValues));
      if (action.payload.code < 300) {
        //console.log("Notification submitted successfully!");
        dispatch(getNotification());
        dispatch(toggleNewDialog(false));
        api.success({
          message: "Notification Submitted Successfully.",
          duration: 1,
        });
        // handleRefresh();
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
        api.error({
          message: "Failed to submit Notification.",
          description: action.payload.error,
        });
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      api.error({
        message: "An unexpected error occurred.",
        description: error.toString(),
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            submitForm,
            handleBlur,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.TITLE && errors.TITLE ? errors.TITLE : ""}
                    validateStatus={
                      touched.TITLE && errors.TITLE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Title</span>

                    <Input
                      name="TITLE"
                      placeholder="TITLE"
                      value={values.TITLE}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.RECEIVER_EMP_ID && errors.RECEIVER_EMP_ID
                        ? errors.RECEIVER_EMP_ID
                        : ""
                    }
                    validateStatus={
                      touched.RECEIVER_EMP_ID && errors.RECEIVER_EMP_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Receiver Emp </span>
                    <Select
                      showSearch
                      placeholder="Select Employee name"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "RECEIVER_EMP_ID", value },
                        })
                      }
                      value={values.RECEIVER_EMP_ID}
                      className="h-[40px] rounded"
                    >
                      {employeeIdList?.map((type) => (
                        <Option
                          key={type.ID}
                          value={type.ID}
                          className="text-gray-400"
                        >
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                  //  help={touched.RECEIVER_EMP_ID && errors.RECEIVER_EMP_ID ? errors.RECEIVER_EMP_ID : ""}
                   // validateStatus={touched.RECEIVER_EMP_ID && errors.RECEIVER_EMP_ID ? "error" : undefined}
                  >
                    <span className="text-xs">Receiver Emp</span>
                    {/* <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Employee name"
                      onChange={(values) => setFieldValue("RECEIVER_EMP_ID", values.join(","))}
                      value={values.RECEIVER_EMP_ID ? values.RECEIVER_EMP_ID.split(",") : []}
                      className="h-[40px] rounded"
                    >
                      {employeeIdList?.map((emp) => (
                        <Option key={emp.ID}value={emp.ID}className="text-gray-400">
                          {`${emp.FIRST_NAME} ${emp.MIDDLE_NAME} ${emp.LAST_NAME}`}
                        </Option>
                      ))}
                    </Select> */}
              {/* <Select
                      showSearch
                      mode="multiple"
                      filterOption={filterOption}
                      className="w-full custom-select h-11"
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("RECEIVER_EMP_ID", value)}
                    >
                      {employeeIdList?.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.FIRST_NAME} {type.MIDDLE_NAME} {type.LAST_NAME}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
              {/* </Row> */}

              <Row gutter={16}>
                <Col span={23}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.MESSAGE && errors.MESSAGE ? errors.MESSAGE : ""
                    }
                    validateStatus={
                      touched.MESSAGE && errors.MESSAGE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Message</span>

                    <TextArea
                      rows={4}
                      placeholder="You Can Add Maximum 512 Characters.."
                      maxLength={6000}
                      value={values.MESSAGE}
                      onChange={(e) => setFieldValue("MESSAGE", e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.READ_STATUS && errors.READ_STATUS
                        ? errors.READ_STATUS
                        : ""
                    }
                    validateStatus={
                      touched.READ_STATUS && errors.READ_STATUS
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">READ STATUS</span>
                    <Radio.Group
                      name="READ_STATUS"
                      value={values.READ_STATUS}
                      onChange={(e) =>
                        setFieldValue("READ_STATUS", e.target.value)
                      }
                    >
                      <Radio
                        value="R"
                        style={{
                          color: values.READ_STATUS === "Read" ? "green" : "",
                        }}
                      >
                        Read
                      </Radio>
                      <Radio
                        value="U"
                        style={{
                          color: values.READ_STATUS === "Unread" ? "yellow" : "",
                        }}
                      >
                        Unread
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

 */}

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        defaultChecked
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default NotificationForm;
