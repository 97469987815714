import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch, Spin } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getAllcustIntrest } from "../store/dataSlice";
import { setSelectedDesignation, toggleNewDialog } from "../store/stateSlice";
import ProductionintrestFilter from "./ProductionintrestFilter";
import { setTableData } from "../store/dataSlice";
const DesignationTable = () => {
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state?.Productinterest?.data?.ProductIntrestList?.data
  );
  const loading = useSelector((state) => state.Productinterest.data.loading);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.Productinterest.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  // const fetchData = useCallback(() => {
  //   dispatch(getAllcustIntrest({ pageIndex, pageSize }));
  // }, [dispatch, pageIndex, pageSize]);

  // useEffect(() => {
  //   fetchData();
  // }, [pageIndex, pageSize]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "CREATED_EMP_NAME",
      width: 220,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "CUSTOMER_NAME",
      width: 220,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "SERVICE_NAME",
      width: 220,

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "INTERESTED_DATETIME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: "Action Date",
      dataIndex: "CONVERTED_DATETIME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "IS_CONVERTED",
      render: (text) => {
        let style;
        let displayText;

        if (text === "P") {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Pending";
        } else if (text === "A") {
          style = {
            color: "#2196f3",
            backgroundColor: "#e3f2fd",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Closed";
        } else if (text === "R") {
          style = {
            color: "#ff6b6b",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Rejected";
        } else if (text === "C") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Completed";
        }

        return <span style={style}>{displayText}</span>;
      },
    },
  ];


  return (
    <>
      <ProductionintrestFilter />
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{
            x: 1300,
          }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onChange: handleTableChange,
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      )}
    </>
  );
};

export default DesignationTable;
