import React, { useEffect, useState } from "react";
import { Button, Select, Spin } from "antd";
import * as XLSX from "xlsx";
import { TbFileTypeXls } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { AiFillFileExcel } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { getAllcustIntrestXls } from "../store/dataSlice";

const GenerateExcel = () => {
  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = useState(false);

  const filters = useSelector(
    (state) => state?.Productinterest?.state?.filters
  );

  const loading = useSelector(
    (state) => state?.Productinterest?.data?.loadingXls
  );
  const data = useSelector((state) => state.Productinterest.data.XlsList?.data);

  useEffect(() => {
    if (isExporting && !loading) {
      generateExcel();
      setIsExporting(false);
    }
  }, [loading, isExporting]);

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      dispatch(getAllcustIntrestXls(filters));
    } catch (error) {
      console.error("Error initiating export:", error);
      setIsExporting(false); // Reset in case of error
    }
  };

  const generateExcel = () => {
    try {
      const newList = data.map((item) => ({
        "Customer Name": item.CUSTOMER_NAME,
        Amount: item.AMOUNT,
        description: item.DESCRIPTION,
        "Crated Employee Name": item.CREATED_EMP_NAME,
        "service Name": item.SERVICE_NAME,
        "Conversion Status":
          item.IS_CONVERTED == "P"
            ? "Pending"
            : item.IS_CONVERTED == "A"
            ? "Closed"
            : item.IS_CONVERTED == "R"
            ? "Rejected"
            : item.IS_CONVERTED == "C"
            ? "Completed"
            : "",
        "Conversion Date Time": item.CONVERTED_DATETIME,
        "Interested Date Time": item.INTERESTED_DATETIME,
      }));

      const worksheet = XLSX.utils.json_to_sheet(newList);

      worksheet["!cols"] = [
        { wch: 25 }, // 1
        { wch: 10 }, // 2
        { wch: 20 }, // 3
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 23 }, // 7
        { wch: 23 }, //8
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, "Production_interest_Report.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  return (
    <div>
      <div className="flex">
        <div className="flex items-center">
          {isExporting ? (
            <>
              <Spin /> <span className="ml-2 text-sm">Generating Excel...</span>
            </>
          ) : (
            <div
              className="bg-[#096CAE] p-[9px] rounded text-xl mr-2 text-white cursor-pointer"
              onClick={exportToExcel}
            >
              <SiMicrosoftexcel />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateExcel;
