import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllDepartment,
  apiPostDepartment,
  apiPutDepartment,
  apiGetState,
} from "../../../services/DepartmentService";

export const getDepartment = createAsyncThunk(
  "department/data/get",
  async (data) => {
    const response = await apiGetAllDepartment(data);
    return response.data;
  }
);

export const postDepartment = createAsyncThunk(
  "department/data/post",
  async (data) => {
    const response = await apiPostDepartment(data);
    return response.data;
  }
);

export const putDepartment = createAsyncThunk(
  "department/data/put",
  async (data) => {
    const response = await apiPutDepartment(data);
    return response.data;
  }
);

export const getState = createAsyncThunk(
  "department/data/state",
  async (data) => {
    const response = await apiGetState(data);
    ////console.log(response);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "departmentList/data",
  initialState: {
    loading: false,
    departmentList: [],
    stateList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.departmentList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departmentList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getDepartment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(postDepartment.fulfilled, (state, action) => {})
      .addCase(putDepartment.fulfilled, (state, action) => {})
      .addCase(getState.fulfilled, (state, action) => {
        state.stateList = action.payload;
      });
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
