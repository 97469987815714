import react from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table, Pagination, Switch, Spin } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { setSelectedHoliday, toggleNewDate } from "../store/stateSlice";
import { useCallback, useEffect } from "react";
import { getHoliday, putHoliday, setTableData } from "../store/dataSlice";



const HolidayTable = () => {

    const dispatch = useDispatch();

    const onEdit = (record) => {
      dispatch(setSelectedHoliday(record))
      dispatch(toggleNewDate(true))
    }
    const loading = useSelector((state) => state.holiday.data.holidayLoading);

    const data = useSelector(
      (state) => state?.holiday?.data?.holidayList?.data
    );


    const { pageIndex, pageSize, total } = useSelector(
      (state) => state.holiday.data.tableData
    );
    const fetchData = useCallback(() => {
      dispatch(getHoliday({pageIndex, pageSize}));
    }, [dispatch, pageIndex, pageSize]);
  
    useEffect(() => {
      fetchData();
      // dispatch(getHoliday());
    }, [pageIndex, pageSize]);

    const onSwitch = async (record) => {
      const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
      await dispatch(putHoliday(updatedRecord));
      dispatch(getHoliday());
    };

    const handleTableChange = (pagination) => {
      const { current, pageSize } = pagination;
  
      dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
    };

    const columns = [
        {
          title: <span className="text-gray-500">Action</span>,
          dataIndex: "action",
          fixed: "left",
          width: 50, // Adjusted to ensure the title fits
          render: (_, record) => (
            <div className="flex items-center">
              <span
                onClick={() => onEdit(record)}
                className="text-2xl ml-2 text-[#096CAE] cursor-pointer"
              >
                <MdEdit />
              </span>
            </div>
          ),
        },
        {
          title: <span className="text-gray-500">Date</span>,
          dataIndex: "DATE",
          width: 60, // Adjusted to ensure the title fits
          ellipsis: true,
        },
    
        {
          title: <span className="text-gray-500">Name</span>,
          dataIndex: "NAME",
          width: 50, // Adjusted to ensure the title fits
          ellipsis: true,
        },
        {
          title: <span className="text-gray-500">Description</span>,
          dataIndex: "DESCRIPTION",
          width: 70, // Adjusted to ensure the title fits
          ellipsis: true,
        },
        {
          title: <span className="text-gray-500">Status</span>,
          dataIndex: "STATUS",
          fixed: "right",
          width: 80,
          render: (_, record) => (
            <Switch
              checked={record.STATUS === 1}
              onChange={() => onSwitch(record)}
            />
          ),
        },
      ];


    return (<>

        { loading ? (
            <div className="w-full flex justify-center h-60 items-center">
                <Spin size="large" />
            </div>
        ) : (
            <Table
                columns={columns}
                dataSource={data}
                bordered
                scroll={{
                    x: 1300,
                }}
                pagination={{
                    current: pageIndex,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    pageSizeOptions: [5, 10, 20],
                    onChange: handleTableChange,
                    onShowSizeChange: handleTableChange,
                }}
                onChange={handleTableChange}
            />
        )}
    </>)
}

export default HolidayTable