import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getExpense, putExpense } from "../store/dataSlice";
import { setSelectedExpense, toggleNewDialog } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";
const ExpenseTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.expense?.data?.expenseList?.data);

  ////console.log(data);

  const onEdit = async (record) => {
    dispatch(setSelectedExpense(record));
    dispatch(toggleNewDialog(true));
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putExpense(updatedRecord));
    dispatch(getExpense());
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.expense.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getExpense({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>

            {/* <span className="text-2xl ml-2 text-red-500 cursor-pointer">
              <MdDelete />
            </span> */}
          </div>
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Date</span>,
      dataIndex: "DATE",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Amount</span>,
      dataIndex: "AMOUNT",
    },

    {
      title: <span className="text-gray-500">Expence Type</span>,
      dataIndex: "EXPENSE_TYPE_ID",
    },

    {
      title: <span className="text-gray-500">Location</span>,
      dataIndex: "LOCATION",
    },

    {
      title: <span className="text-gray-500">Description</span>,
      dataIndex: "DESCRIPTION",
    },

    {
      title: <span className="text-gray-500">Party Name</span>,
      dataIndex: "PARTY_NAME",
    },
    {
      title: <span className="text-gray-500">Bill</span>,
      dataIndex: "BILL_URL",
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <>
      {/* <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
      // onChange={handleTableChange}
      // pagination={false} // disable built-in pagination
      /> */}

      {/* <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={data.length} // replace with your actual data count
        onChange={setCurrentPage}
        style={{ marginTop: "16px" }}
      />
 */}

      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20],
          onChange: handleTableChange,
          onShowSizeChange: handleTableChange,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ExpenseTable;
