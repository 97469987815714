import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch, Spin, Select } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getEmployeeTarget,
  putEmployeeTarget,
  getEmployeeTargetInner,
} from "../store/dataSlice";
import {
  setSelectedEmployeeTarget,
  toggleFilter,
  setSelectedEmployeeTargetInner,
} from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import EmployeeTargetFilter from "./EmployeeTargetFilter";

const EmployeeTargetTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.employeetarget.data.loading);

  const data = useSelector(
    (state) => state?.employeetarget?.data?.employeetargetList?.data
  );

  const onEdit = async (record) => {
    await dispatch(setSelectedEmployeeTargetInner(record));
    await dispatch(getEmployeeTargetInner({ TARGET_ID: [record.ID] }));
    await navigate("/employeetargetinner");
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.employeetarget.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  // const fetchData = useCallback(() => {
  //   dispatch(getEmployeeTarget({ filters, pageIndex, pageSize }));
  // }, [dispatch, pageIndex, pageSize]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEye />
            </span>
          </div>
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Department</span>,
      dataIndex: "DEPARTMENT",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Branch Name</span>,
      dataIndex: "BRANCH_NAME",
    },

    {
      title: <span className="text-gray-500">Month</span>,
      dataIndex: "MONTH",
      render: (monthId) => monthNames[monthId - 1],
    },

    {
      title: <span className="text-gray-500">YEAR</span>,
      dataIndex: "YEAR",
    },

    {
      title: <span className="text-gray-500">Total Amount</span>,
      dataIndex: "TOTAL_AMOUNT",
    },

    {
      title: <span className="text-gray-500">Total Customers</span>,
      dataIndex: "TOTAL_CUSTOMERS",
    },
  ];

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleFilter(false));
  }, [location.pathname]);

  return (
    <>
      <div className="filter mb-4">
        <EmployeeTargetFilter />
      </div>

      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginBottom: "16px" }}>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{
              x: 1300,
            }}
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onChange: handleTableChange,
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </>
  );
};

export default EmployeeTargetTable;
